{
  "404-title": "Crush - Not found",
  "404-not-found-1": "Not found",
  "404-not-found-2": "Sorry, the page you are looking for is not available.",
  "navbar-mission": "Mission",
  "navbar-download": "Download",
  "footer-follow": "Follow us",
  "footer-follow-1": "Instagram",
  "footer-follow-2": "TikTok",
  "footer-follow-3": "Twitter",
  "footer-follow-4": "LinkdIn",
  "footer-docs": "Docs",
  "footer-docs-1": "Mission",
  "footer-docs-2": "Legal notice",
  "footer-docs-3": "Privacy",
  "footer-docs-4": "Cookies",
  "footer-docs-5": "Guidelines",
  "footer-contact": "Contact us",
  "footer-contact-1": "Support",
  "footer-contact-2": "Jobs",
  "footer-contact-3": "Investors",
  "home-title": "Crush - Where media meets platonic",
  "home-hero-slogan-1": "Where media",
  "home-hero-slogan-2": "meets platonic ™",
  "home-concept-1": "Find your people",
  "home-concept-2": "Connect with people like you at the different stages of your life.",
  "home-concept-3": "Cool vibes",
  "home-concept-4": "Embrace cool vibes and real moments. We steer clear of toxicity and pretense.",
  "home-attributes-1": "Aesthetic",
  "home-attributes-2": "We promote the publication of aesthetic and high-quality content; the web is already flooded with cat photos.",
  "home-attributes-3": "Stalker",
  "home-attributes-4": "Your friends are the stars. Take a few bursts of photos when they are off guard and post some GIFs on their accounts.",
  "home-attributes-5": "Threads",
  "home-attributes-6": "Engage in vibrant discussions spanning today's most pressing topics to the trends that will shape tomorrow.",
  "home-attributes-7": "Twins",
  "home-attributes-8": "Connect with individuals who share facial features akin to yours, amplifying your social network in an entirely novel and funny way.",
  "home-attributes-9": "Age segmented",
  "home-attributes-10": "By grouping users by age, similar to high school classes, connections are limited to individuals of the same generation for a safer and efficient interaction.",
  "home-attributes-11": "Crush",
  "home-attributes-12": "Not a dating app — just friends and relaxed relationships. Recapture the nostalgia of high school connections in a digital environment.",
  "home-partners": "Powered By",
  "mission-title": "Crush - Mission",
  "mission-date": "Last updated: December 8, 2023",
  "mission-header": "Mission and values",
  "mission-introduction": "In the heart of Crush beats something more than just a simple social network; it's a universe woven with ethical values, a philosophy, and a purpose. This document is the journey through the foundations of our vision, from the birth of the idea to the meticulous outline of the creation we are forging.",
  "mission-origin": "The origin",
  "mission-origin-1": "The origin of Crush dates back to a seed sown long before its concrete development in 2020 and its launch in 2023. Our vision to merge social networks with Crush's distinctive touch was conceived in 2016, inspired by 'The Social Network', the notable cinematic work recognized by Quentin Tarantino as a gem of the past decade. This film, which tells the story of the young founders of Facebook, particularly its most recognized figure, Mark Zuckerberg — the father of social networks, provided us with an enlightening perspective.",
  "mission-origin-2": "In Crush, we fervently believe that the core of today's social networks resides in platonic love. If you've had the chance to watch this movie, you may have picked up on Zuckerberg and Eduardo Saverin's criticisms towards dating apps. In a key scene, around the 30:00-minute mark, during the Winklevoss brothers' lawsuit for alleged idea theft, Saverin mentions:",
  "mission-origin-3": "\"It really hadn't much to do with the Winklevoss' dating site\".",
  "mission-origin-4": "It's curious because a few minutes later, around the 36:05-minute mark, we see Mark implementing elements like relationship status and interests in his project:",
  "mission-origin-5": "\"Relationship status, interests... This is what drives life in college. Are you having sex or aren't you? That's why people take classes, sit where they sit, and do what they do. It's the center, you know? That's what The Facebook is going to be.\".",
  "mission-origin-6": "Finally, in the final scene, we witness a lovestruck Zuckerberg sending a friend request to his crush.",
  "mission-origin-7": "This movie poses an intriguing question:",
  "mission-origin-8": "Are social media platforms actually disguised dating apps?",
  "mission-purpose": "Our purpose",
  "mission-purpose-1": "There is no definitive answer to the question raised in the previous section. Perhaps yes, perhaps no... in the end, it depends on the ethical and cultural values, intentions, and education of the end-user. What we do know for sure is that this opens the door to real and very serious problems that, unlike in the real world, can indeed be solved.",
  "mission-purpose-2": "Whether by the standards pre-established by society or the anonymity that leads to inappropriate behavior online, love in the digital realm, whether it's platonic and genuine on social media or more romantic on dating apps, has unfortunately been sexualized in recent years. Why is something so beautiful and fundamental in our lives being distorted in this parallel universe?",
  "mission-purpose-3": "Let's be clear and completely transparent. In Spain, our home country, according to verifiable data and statistics,",
  "mission-purpose-4": "7 out of 10 minors under the age of 15 have a mobile device.",
  "mission-purpose-5": "This means that around 70% of minors have unrestricted access to a virtual world where interaction with adults has not been regulated or restricted, exposing them to risks such as harassment, bullying, or pedophilia, among others.",
  "mission-purpose-6": "Currently, regardless of the social network you sign up for, it's common to find people selling inappropriate content or sharing any type of unsuitable content. The situation is getting worse as time goes by.",
  "mission-purpose-7": "We aim to eliminate the sexualization and toxicity of the network by addressing its origin directly.",
  "mission-purpose-8": "Crush is proposed as a redefinition of social networks from a different perspective, focusing on platonic relationships.",
  "mission-purpose-9": "We don't offer a dating system or the creation of romantic/serious relationships, as that contradicts our",
  "mission-purpose-10": "community guidelines.",
  "mission-purpose-11": "Instead, we offer what was envisioned in the movie 'The Social Network', but with a solution to the problem and a cool approach.",
  "mission-purpose-12": "Our mission goes beyond the typical \"share real moments\" or \"we help you connect\". In addition to accompanying the user through the various stages of their life, we are dedicated to solving the aforementioned issues. On our platform, as of the drafting of this document, it is already impossible for a minor to interact with an adult (and vice versa), not only to close the doors to these ethical issues but also to encourage the creation of bonds between people of the same generation, with a similar mindset. We plan to introduce more effective solutions in the next decade of development; that's our promise.",
  "guidelines-title": "Crush - Guidelines",
  "guidelines-date": "Last updated: April 4, 2024",
  "guidelines-header": "Community guidelines",
  "guidelines-introduction-1": "In Crush, it's about establishing platonic connections, sharing authentic life moments with friends, socializing, and creating new friendships that align with your mindset. We strongly believe in freedom of expression, but we also value the ability to distinguish between right and wrong. This document outlines the standards of our social network, essential to fulfilling",
  "guidelines-introduction-2": "our core purpose.",
  "guidelines-introduction-3": "We expect appropriate behavior, careful content, authenticity, humility, and above all, responsibility. Any violation of our principles could result in the permanent suspension of your account, with no possibility of re-registering in our system.",
  "guidelines-introduction-4": "Just as in real life, there are consequences for actions. Please respect our rules and do not use our system in ways contrary to the law. We want to emphasize our seriousness and rigor; a playful tone is not welcome in this context.",
  "guidelines-dating": "Anti-dating",
  "guidelines-dating-1": "Crush is a merge of social networks suitable for all individuals",
  "guidelines-dating-2": "aged 15 and above, ",
  "guidelines-dating-3": "whether or not in a romantic relationship. Don't confuse friends and platonic relationships (optional) with romantic relationships or real commitments.",
  "guidelines-dating-4": "Next, we outline a series of behaviors you must adopt and respect to stay on our platform:",
  "guidelines-dating-5": "Do not use our service to seek or request romantic encounters.",
  "guidelines-dating-6": "If love comes into your life, let it not be through a screen.",
  "guidelines-dating-7": "Don't post dirty stuff.",
  "guidelines-dating-8": "Don't harass or bother any user.",
  "guidelines-dating-9": "Don't lie about your age or personal information, let alone impersonate someone else.",
  "guidelines-dating-10": "It's a serious crime that could result in legal actions against you.",
  "guidelines-content": "Aesthetic",
  "guidelines-content-1": "For us, any form of software is art, and art must be respected. In a social network, the platform's quality is reflected in the content users generate. In our case, we encourage creativity and inspiration. We ask you to publish while maintaining a minimum standard of quality.",
  "guidelines-content-2": "Don't post visually poor content.",
  "guidelines-content-3": "Make an effort.",
  "guidelines-content-4": "Spam is an immediate removal.",
  "guidelines-content-5": "If you want to promote your Instagram, use the tools we provide for that.",
  "guidelines-content-6": "Don't share content that infringes on others' intellectual property.",
  "guidelines-content-7": "Please, avoid posting pictures of kittens;",
  "guidelines-content-8": "the internet is already flooded with them.",
  "guidelines-content-9": "Don't embarrass yourself by posting unnecessary nudity.",
  "guidelines-content-10": "We're tired of shirtless photos.",
  "guidelines-vibes": "Good vibes only",
  "guidelines-vibes-1": "At Crush, we don't tolerate inappropriate behavior. Just as you maintain your image in real life, we ask you to be responsible and uphold that integrity online. Show courtesy and respect to both the users of our community and the platform's creators.",
  "guidelines-vibes-2": "Child exploitation, sextortion, or any other type of abuse, in any form, is strictly prohibited.",
  "guidelines-vibes-3": "Our commitment against suicide and self-harm is absolute.",
  "guidelines-vibes-4": "Any content that promotes, encourages, or glorifies these actions will result in a permanent suspension.",
  "guidelines-vibes-5": "We take legality seriously.",
  "guidelines-vibes-6": "Avoid sharing content that promotes illegal activities, as well as any content related to weapons or drugs.",
  "guidelines-vibes-7": "Offensive or violent content has no place on our platform and is not allowed.",
  "guidelines-vibes-8": "The Stalker mode is meant for fun among friends.",
  "guidelines-vibes-9": "However, even if the content ultimately requires approval from the user(s) in the GIF, avoid sharing photos of your friends without their prior consent.",
  "legal-title": "Crush - Legal notice",
  "legal-date": "Last updated: April 4, 2024",
  "legal-header": "Legal notice",
  "legal-introduction-1": "Every application is a universe, and all universes are subject to rules. In this document, you will find the legal notice and the general terms of use of our platform, which are complemented by those defined in our",
  "legal-introduction-2": "community guidelines.",
  "legal-owner": "1. Owner identification",
  "legal-owner-1": "The website www.crushsocialnetwork.com (hereinafter, the",
  "legal-owner-2": "'Website'",
  "legal-owner-3": ") and the mobile application for Smartphones with the name 'Crush' (hereinafter, the",
  "legal-owner-4": "'App'",
  "legal-owner-5": ") are owned by CRUSH SOCIAL NETWORK, LTD., provided with NIF: B-72599830 and registered in the Mercantile Registry of Valencia with the following registry data: T. 3606, F.75, I.1, H. MU-108656, and whose contact details are:",
  "legal-owner-6": "Registered Office:",
  "legal-owner-7": "Calle Pintor Velázquez, 3, 1, 30530 Cieza, Murcia (Spain)",
  "legal-owner-8": "Contact email:",
  "legal-area": "2. Area of application",
  "legal-area-1": "These General Conditions of Use of the Website (hereinafter, the",
  "legal-area-2": "'Terms of Use'",
  "legal-area-3": ") include the terms and conditions that regulate access, navigation and use of the Website and the App.",
  "legal-area-4": "In this sense, the access, use and browsing of the Website and the App confers the condition of user(s) (hereinafter, the",
  "legal-area-5": "'User'",
  "legal-area-6": "or",
  "legal-area-7": "'Users'",
  "legal-area-8": "), and implies the acceptance of each and every one of these Conditions of Use. The User is fully aware that the mere browsing of the Website or the App implies acceptance of these Conditions of Use and, therefore, if the User does not agree with them, they should not use the Website or this App.",
  "legal-area-9": "The Website and the App are mainly aimed at Users residing in Spain. Crush does not ensure that the Website or the App comply with the laws of other countries, either totally or partially. If the User resides or is domiciled elsewhere and decides to access and/or browse the Website or access or use the App, they will do so at their own risk and must ensure that such access, browsing or use complies with their local legislation, Crush not assuming any responsibility that may arise from such access or use.",
  "legal-area-10": "Crush may, at any time and without notice, modify these Terms of Use, which will be published as such modifications occur.",
  "legal-conditions": "3. Conditions of access and use",
  "legal-conditions-1": "Access to the Website or the App by the User, as a general rule, is free. In the event that there are functionalities or services reserved for certain Users or that it is necessary to pay a price, these will be duly identified in the Website or the App.",
  "legal-conditions-2": "Access to the Website or the App is carried out under the sole responsibility of the User, and does not imply any type of commercial relationship between Crush and the User. The User is responsible for ensuring that the information and content included in the Website or in the App meet their specific requirements.",
  "legal-conditions-3": "The User agrees to:",
  "legal-conditions-4": "Access and use both the Website and the App in good faith and in accordance with these Conditions of Use, the Law, morality and public order, with the additional prerequisite of attaining a minimum age of 15 years.",
  "legal-conditions-5": "At Crush, we certify that we comply with applicable legislation on child safety and take child sexual abuse material seriously. We take appropriate measures, such as removing such material upon actual knowledge of its existence, in accordance with our standards and relevant laws.",
  "legal-conditions-6": "Provide truthful, current and lawful information in the forms offered both on the Website and in the App. In any case, the User will immediately notify Crush of any fact that allows the improper use of the information registered in said forms, such as, but not limited to, theft, loss, or unauthorized access to identifiers and/or passwords, in order to proceed with their immediate cancellation.",
  "legal-conditions-7": "Not carry out any action on the Website or the App that may cause damage or alterations in the contents, programs or systems of the Website or the App, including the introduction of computer viruses, the installation of robots, or any harmful software or file, defective, or ultimately that may cause damage to our computer systems.",
  "legal-conditions-8": "The User will respond in any case for damages and losses that may be caused to both Crush and third parties and Crush may adopt the technical, legal and any other measures that it deems appropriate to prevent, mitigate or stop the consequences of the previous prohibited conducts and to demand as many responsibilities as are deemed appropriate.",
  "legal-property": "4. Intellectual and industrial property",
  "legal-property-1": "All the contents of both the Website and the App, including, without limitation, the texts, images, photographs, videos, graphics, distinctive signs of any kind, icons, interfaces, as well as the software, source code, designs, architecture, presentation, arrangement and classification of the contents and any other element present on the Website or the App that may be the subject of intellectual property rights are the exclusive property of Crush or third parties that have licensed, authorized or consented to their use on the Website or in the App.",
  "legal-property-2": "In this sense, when the User accesses, navigates and uses the Website or accesses or uses the App, they are not granted any exploitation rights that exist or may exist over all or part of these, Crush reserving all these rights. The User can only view the elements of the Website and the App and use them to the extent that is strictly necessary for their correct use. In particular, it is prohibited to use or resell for commercial purposes any material or content present on the Website or the App without the prior authorization of Crush.",
  "legal-property-3": "Crush reserves the right to modify, at any time, and without prior notice, the presentation and configuration of the Website or the App and the contents and services that may be incorporated into them. The User acknowledges and accepts that at any time Crush may interrupt, deactivate and/or cancel any of these elements that are integrated into the Website and the App or the access to them.",
  "legal-property-4": "If the User considers that any of the contents of the Website or the App constitutes a violation of the rights of protection of intellectual property, they must immediately notify Crush through the contact information in section 1 (Owner Identification) of these Terms of Use.",
  "legal-disclaimer": "5. Exclusion of guarantees and responsibility",
  "legal-disclaimer-1": "The information published on the Website or in the App may not be exhaustive or not fully updated, so Crush does not assume any responsibility derived from the lack of integrity, updating or precision of the data and information contained in the different pages that are part of the Website or the App.",
  "legal-disclaimer-2": "Crush does not guarantee that access to the App or the Website will be uninterrupted or error free. Likewise, Crush does not guarantee that the content or software present on the Website or the App does not cause damage to the User's computer system (software and hardware). Crush will not be responsible for losses, damages or harm of any kind arising from the access, navigation and use of the Website or the App, including, but not limited to, those caused to computer systems or those caused by the introduction of viruses.",
  "legal-disclaimer-3": "Crush, likewise, is exonerated from any liability derived from inappropriate use of the Website or the App by the User.",
  "legal-links": "6. Links",
  "legal-links-1": "It is reported that both the Website and the App can be found, among others, links, banners, buttons, directories and search engines that allow Users to access other websites or apps belonging to and/or managed by third parties and that, therefore, are beyond the control of Crush, who cannot assume responsibility for the content that appears on said pages or apps.",
  "legal-links-2": "In the event that you consider that said content is inappropriate or contrary to the purposes of Crush, we ask that you notify us through the means of contact indicated above, so that we can adopt the appropriate measures.",
  "legal-jurisdiction": "7. Applicable law and jurisdiction",
  "legal-jurisdiction-1": "These Conditions of Use and their execution are subject to Spanish law.",
  "legal-jurisdiction-2": "If any dispute arises over the interpretation or application of these Terms of Use, the Parties will negotiate in good faith to try to resolve such discrepancy or claim. However, in the event that the discrepancy or claim is not resolved, the Parties will submit to the corresponding courts or tribunals according to law.",
  "privacy-title": "Crush - Privacy",
  "privacy-date": "Last updated: December 8, 2023",
  "privacy-header": "Privacy policy",
  "privacy-introduction-1": "At Crush, we are committed to ensuring that your personal data is protected and is not used for purposes other than those indicated in this Privacy Policy. For this reason, in this section we inform users and interested parties of everything related to the processing of their personal data, thus complying with the data protection regulations applicable in our country: General Data Protection Regulation (EU) 2016/679, of April 27 regarding the protection of natural persons with regard to the processing of personal data and the free circulation of these data (hereinafter,",
  "privacy-introduction-2": "'GDPR'",
  "privacy-introduction-3": ") and Organic Law 3/2018, of December 5, Protection of Personal Data and guarantee of digital rights (hereinafter,",
  "privacy-introduction-4": "'LOPDGDD'",
  "privacy-introduction-5": "This Privacy Policy is applicable to the data processing that CRUSH SOCIAL NETWORK, LTD., carried out both through the website: www.crushsocialnetwork.com (hereinafter, the 'Website'), and through the mobile application for Smartphones with the name 'Crush' (hereinafter, the 'App') and/or those that are indicated. We recommend that you read it carefully before using the Website or the App and providing your data through them. Do not hesitate to ask us any questions in this regard via email:",
  "privacy-index": "Index of contents",
  "privacy-index-1": "In this Policy you will find all the information related to the processing of your personal data and the rights you can exercise to maintain control over them. In this sense, you will find information about:",
  "privacy-index-2": "Who is responsible for the processing of your data.",
  "privacy-index-3": "What requirements you must meet to provide us with your personal data.",
  "privacy-index-4": "What data processing we carry out through the website and what are its main characteristics, explaining:",
  "privacy-index-5": "What data we collect and what are the ways of collecting it.",
  "privacy-index-6": "For what purposes we collect the data we request.",
  "privacy-index-7": "What is the legitimacy for its treatment.",
  "privacy-index-8": "How long do we keep them?",
  "privacy-index-9": "To which recipients your data is communicated.",
  "privacy-index-10": "Existence of international transfers of your data.",
  "privacy-index-11": "What are your rights and how can you exercise them?",
  "privacy-index-12": "How we protect your personal information.",
  "privacy-index-13": "Changes to this policy.",
  "privacy-controller": "1. Who is responsible for the processing of your personal data?",
  "privacy-controller-1": "Your personal data will be processed by the company CRUSH SOCIAL NETWORK, LTD. (",
  "privacy-controller-2": "'CRUSH'",
  "privacy-controller-3": "), with NIF B-72599830 and whose contact details are as follows:",
  "privacy-controller-4": "Address:",
  "privacy-controller-5": "Calle Pintor Velázquez, 3, 1, 30530 Cieza, Murcia (Spain)",
  "privacy-controller-6": "Contact email:",
  "privacy-dpo": "1.1. Our Data Protection Officer",
  "privacy-dpo-1": "From CRUSH, we make available to you the contact details of our Data Protection Officer, to whom you can address any questions you may have in relation to this Privacy Policy or the processing of your personal data.",
  "privacy-dpo-2": "Email:",
  "privacy-requirements": "2. What requirements must you fulfill to provide us with your personal data?",
  "privacy-requirements-1": "2.1. Minimum age. To provide us with your personal data and use our Website and our App, you must be over 15 years of age.",
  "privacy-requirements-2": "2.2. Veracity. When you provide us with your data to use our services, you guarantee that the data and information provided is real, truthful, updated and also belongs to you and not to third parties.",
  "privacy-requirements-3": "In addition, you must notify us of any change that occurs in the data provided, responding in any case to the veracity and accuracy of the your supplied data at all times.",
  "privacy-requirements-4": "2.3. Control of Age and Veracity. From CRUSH we reserve the right to verify your age and identifying information, if necessary, even requiring an official document or equivalent procedure and, in case of detection of fraud that is verified or suspected that you are under the indicated age, to delete, temporarily deactivate and/or cancel your account.",
  "privacy-processing": "3. What data processing do we carry out through the Website and the App and what are its main characteristics?",
  "privacy-processing-1": "Below, we explain how we treat your personal information and provide you, in detail, with all the relevant information regarding your privacy:",
  "privacy-contact": "3.1. When you contact us through our channels (contact form, chat, email)",
  "privacy-contact-1": "What are the ways of collecting the data?",
  "privacy-contact-2": "Contact form.",
  "privacy-contact-3": "Chat.",
  "privacy-contact-4": "Sending emails to",
  "privacy-contact-5": "or other CRUSH email addresses.",
  "privacy-contact-6": "What data do we collect?",
  "privacy-contact-7": "Identification and contact information.",
  "privacy-contact-8": "We collect your identification data (name and surname) and email address, as well as any other information that you voluntarily include in the communications that you send us.",
  "privacy-contact-9": "We may request additional information from you if necessary to comply with your request or requirement.",
  "privacy-contact-10": "What are the purposes of processing your personal data?",
  "privacy-contact-11": "Answer your requests.",
  "privacy-contact-12": "The main purpose of processing this data will be to answer your requests, answer your questions and/or provide you with the required information, as well as, where appropriate, follow up on your requests.",
  "privacy-contact-13": "Improve customer service.",
  "privacy-contact-14": "All the information derived from doubts, queries, and advice offered to interested parties, as well as the way in which requests are resolved, allows us to know how we provide our own customer service, allowing us to improve its quality.",
  "privacy-contact-15": "Likewise, all the information collected, after the conservation period indicated below, is anonymized and used for the purpose of being able to analyze the most frequently asked questions through the chat and to be able to automate the most frequent ones, prepare FAQs or keep it for statistical purposes to develop business strategies.",
  "privacy-contact-16": "What is the legal basis that allows us to process your data? Is the provision of this data mandatory?",
  "privacy-contact-17": "Consent.",
  "privacy-contact-18": "The data provided for the above purposes will be processed based on your consent, granted when you voluntarily contact us through the means made available to you to request information or make a request.",
  "privacy-contact-19": "All the information collected by the customer service department will be processed for statistical purposes that will help us to improve the quality of the customer service provided. This purpose is considered to be compatible with the initial one.",
  "privacy-contact-20": "The information that you must provide will be indicated with an asterisk or similar. Without this information it would not be possible to attend to your queries or requests.",
  "privacy-contact-21": "How long do we keep your information?",
  "privacy-contact-22": "We will process all your personal information during the time your requests are being processed and, if necessary, to follow up on them. Once this period has ended, CRUSH will keep, blocked, said information during the periods provided in the legislation to attend to eventual responsibilities and to demonstrate compliance with our obligations. From this moment on, CRUSH will only treat the information in an anonymous way, so it will not be possible to link the statistical information with the specific users to whom it refers.",
  "privacy-contact-23": "Who do we give your personal information to?",
  "privacy-contact-24": "We do not make any additional transfer to carry out this treatment than those indicated, in general, in point",
  "privacy-contact-25": "4. To whom do we give your personal information?",
  "privacy-contact-26": "In this sense, some channels through which you can contact us are managed by service providers, who act as Treatment Managers. You will find more information about how these service providers act in point 4, mentioned above.",
  "privacy-app-use": "3.2. When you register and use our App 'Crush'",
  "privacy-app-use-1": "What are the ways of collecting the data?",
  "privacy-app-use-2": "Registration form in our App and use of its different spaces.",
  "privacy-app-use-3": "What data do we collect?",
  "privacy-app-use-4": "Basic data for registration:",
  "privacy-app-use-5": "In order to register in our App and use the services, you will need to register in our App and create a",
  "privacy-app-use-6": "'profile'.",
  "privacy-app-use-7": "During the registration process we require you, as a user, the following data:",
  "privacy-app-use-8": "Username.",
  "privacy-app-use-9": "Full name.",
  "privacy-app-use-10": "Email.",
  "privacy-app-use-11": "Date of birth.",
  "privacy-app-use-12": "Additional data:",
  "privacy-app-use-13": "In addition to the above, our App allows you to voluntarily share information related to:",
  "privacy-app-use-14": "Gender.",
  "privacy-app-use-15": "Sexual orientation.",
  "privacy-app-use-16": "Interests.",
  "privacy-app-use-17": "Country, city or region.",
  "privacy-app-use-18": "Description about you.",
  "privacy-app-use-19": "Profession and studies.",
  "privacy-app-use-20": "Musical tastes.",
  "privacy-app-use-21": "Best friend.",
  "privacy-app-use-22": "If you authorize it, your precise geolocation, which, if you have allowed it, is carried out in the background.",
  "privacy-app-use-23": "Usage data: information may be collected about your interactions with other users (chats with other users, users with whom you connect and interact, time, frequency of interaction or other characteristics of your interactions).",
  "privacy-app-use-24": "Some of the data that you can provide voluntarily is considered sensitive, since it is related to your sexual orientation. You should take into account that, if you decide to include such information, CRUSH will process it together with the rest of the information to fulfill the purposes indicated below. Likewise, this information may be shared with the rest of the users (it may be made public), so that unauthorized use of the App by other users could cause the leaking of this data. CRUSH is not responsible for the inappropriate use of data by other users.",
  "privacy-app-use-25": "We also inform you that CRUSH uses the above data for the purpose of creating profiles in order to recommend content which might be of your interest and with the personal characteristics that you include about yourself, as explained below.",
  "privacy-app-use-26": "In addition, if you authorize it, we will have access to:",
  "privacy-app-use-27": "Camera (images): granting camera permission allows the user to upload any image directly to the App.",
  "privacy-app-use-28": "Photo gallery (images): granting access to the photo gallery allows the user to upload any image from their photo gallery.",
  "privacy-app-use-29": "Microphone (audio): granting access to the microphone allows the user to use the device's voice recorder directly from the App.",
  "privacy-app-use-30": "Content posted by you:",
  "privacy-app-use-31": "By using our App, you have the possibility of interacting with other users through our community, being able to upload publications with audiovisual content (photos, audios, etc., if you have granted the respective permissions) and add comments with your opinions, ask questions and resolve concerns of other users.",
  "privacy-app-use-32": "We also offer an exciting 'Stalker' mode, where your friends can capture GIF images of you with your consent. However, the responsibility for capturing and requesting to publish these GIFs on your profile lies with the user who initiates the request. The GIF won't be published on your profile until you accept the request. Furthermore, all collaborators can permanently remove their contributions by either rejecting the request or deleting the publication.",
  "privacy-app-use-33": "CRUSH uses Amazon Web Services face recognition technology to process CRUSH's users' biometric data as its service provider ('Processor'). Amazon may process and store face templates for the purposes of providing face verification and/or identification services on CRUSH's behalf, and only as instructed by CRUSH. Amazon will store this data as long as CRUSH requests, which shall be no longer than a limited grace period after the date when (i) CRUSH ceases to have a relationship with Amazon or (ii) when CRUSH requests deletion.",
  "privacy-app-use-34": "In the event that you publish posts in the App (which is merely optional, we do not force you to do so) Amazon's facial recognition technology will be used, which allows to detect faces and find users similar to you (twins) within our system. This is the only use we make of this recognition technology.",
  "privacy-app-use-35": "Our processor, Amazon, is committed to its own compliance with the GDPR, as well as to providing a variety of products, features, documentation, and resources to help its customers meet their compliance obligations under the GDPR.",
  "privacy-app-use-36": "App usage data:",
  "privacy-app-use-37": "We also automatically collect App usage data, specifically technical data and information related to your use of the App, including the type of mobile device, the unique ID of your mobile device, the IP address of your mobile device, the mobile operating system, if any, the type of Internet browsers you use and information about the way you use the App, such as the frequency and time of use, ads you have clicked on, etc.",
  "privacy-app-use-38": "Data about you provided by third parties:",
  "privacy-app-use-39": "Other users of the App may provide us with information about you when they use our services; for example, when they interact with you or if they send us a report involving you.",
  "privacy-app-use-40": "What are the purposes of processing your personal data?",
  "privacy-app-use-41": "Sign up and manage your account",
  "privacy-app-use-42": "so that we can verify your email address and age to sign up for the App, interact with you and give you access to our services.",
  "privacy-app-use-43": "Manage and execute the contractual relationship that unites us:",
  "privacy-app-use-44": "we carry out different steps necessary to execute the contract signed with you, so that, for example, we can provide you with the service, contact you to help you resolve doubts, to verify the information you have provided us. Likewise, we may send you communications of an administrative nature, about updates, changes in our conditions of service, security alerts, as well as respond to any request or requirement that you send us.",
  "privacy-app-use-45": "Publish your content.",
  "privacy-app-use-46": "When you share content or information through the App, it will appear published for the public that you have selected that can see what you share. To add certain content, such as photos and videos, you will need to give us access to your camera or photo gallery.",
  "privacy-app-use-47": "Any user of our App can view your username and public profile on the App, which includes content that you have shared with the general public. Also, keep in mind that your content published in the App may be shared by other users inside or outside the App, take a screenshot of it or download it.",
  "privacy-app-use-48": "Post content about you.",
  "privacy-app-use-49": "Other users of the application can create and share content with your image or data with an audience of your choice. If you do not feel comfortable with the content that others have shared about you, please report it by using the App forms or contact us by email.",
  "privacy-app-use-50": "Offer you content (news, articles, experiences, among others)",
  "privacy-app-use-51": "created directly by CRUSH or in collaboration with external people who may be of interest to you.",
  "privacy-app-use-52": "Sending push notifications and alerts within the App.",
  "privacy-app-use-53": "If you have authorized it, you will receive our push notifications in your web browser or on your mobile device. Push notifications may include reminders, promotional communications, alerts, and updates regarding the App and services.",
  "privacy-app-use-54": "If you activate these notifications, you will receive personalized notices, based on a profile that we develop about you and the use you make of the App. For the personalization of notices, the information obtained through the data entered by you in the App and the data collected regarding the use of the App (visited content) to create a profile in relation to your interests and offer you mainly that content and information that suits you.",
  "privacy-app-use-55": "You can opt out of push notifications at any time by adjusting the settings on your mobile device, although opting out will not affect other communications you may receive from CRUSH, such as email communications.",
  "privacy-app-use-56": "Prevention, detection and prosecution of illegal activities or activities contrary to the conditions of service or that endanger the security of information or the App:",
  "privacy-app-use-57": "CRUSH may process the data to control and prevent any form of abuse of our services, such as fraudulent activities, denial-of-service attacks, sending spam, unauthorized access to our users' accounts, as well as any other practice that is contrary to the General Conditions and Use of the App or endangers the security of the information or the integrity of the App itself, being able to delete the content or the account that is contrary to the law, our General Conditions or the Community Guidelines.",
  "privacy-app-use-58": "Within this purpose, the processing of complaints that you present against other users or those that are presented against you is also included, with the option of contacting CRUSH to analyze the specific case and guaranteeing that there will be human intervention to correct or rectify the issue that arises to us.",
  "privacy-app-use-59": "We may also use tools to detect inappropriate content (for example, adult or violent content) in the visual content you post.",
  "privacy-app-use-60": "To offer you a better service, your data will also be processed for the purpose of:",
  "privacy-app-use-61": "Help you connect with other users, allowing you to interact with them through chat screens and other mechanisms.",
  "privacy-app-use-62": "Recommend you to other users who are similar to you.",
  "privacy-app-use-63": "To make recommendations, we use the information you provide us, including information about your location if you give your permission, as well as information we obtain from your use of this space (for example, characteristics of the people with whom you actually interact) to develop an automated profile about you and preferably show you those users who match your profile and what you are looking for.",
  "privacy-app-use-64": "In this sense, you will preferably be shown active users in the App, who are close to you, who match the indicated preferences and your profile created from your interactions, also ensuring that your characteristics and profile match the preferences of the recommended users.",
  "privacy-app-use-65": "Statistical purposes and others carried out with anonymous data.",
  "privacy-app-use-66": "We may anonymize all personal information collected from users who use the App for statistical and other purposes, such as algorithm training, to measure the effectiveness of advertising campaigns available on the App, the navigability and usability of the App to to be able to improve the design, presentation and functionalities available or others.",
  "privacy-app-use-67": "To show you advertising:",
  "privacy-app-use-68": "Our App may include advertising that, if you give your consent, will be personalized according to your tastes and preferences.",
  "privacy-app-use-69": "What is the legal basis that allows us to process your data? Is the provision of this data mandatory?",
  "privacy-app-use-70": "Execute and maintain the contractual relationship between the Parties.",
  "privacy-app-use-71": "The reason why we process your information for the purposes mentioned above is to fulfill the contract we have with you.",
  "privacy-app-use-72": "Consent.",
  "privacy-app-use-73": "Some functionalities are based on your express consent: (i) publication of your own content, (ii) access to the camera or photo gallery to publish certain content in the App, (iii) access to the geolocation of the device for user recommendation by proximity (iv) the reception of personalized push notifications (v) display of personalized advertising content. Also, if you decide to provide us with additional voluntary information to complete your profile, such information will be used based on your consent.",
  "privacy-app-use-74": "Remember that you can revoke your consent at any time through the settings of your device, deleting your content or through the options available in the App.",
  "privacy-app-use-75": "Legitimate interest.",
  "privacy-app-use-76": "The data will be processed based on our legitimate interest in relation to the prevention of illegal activities and others related to the security of the App, to guarantee the security of the network and of the information to prevent events that may compromise the availability, authenticity, integrity and confidentiality of personal data or the platform itself, as well as compliance with community standards.",
  "privacy-app-use-77": "We may also show you non-personalized advertising through the App based on our legitimate interest.",
  "privacy-app-use-78": "If you wish to obtain more information about the weighting that we have carried out on the legitimate interests of CRUSH in relation to the above purposes, you can request it by writing to us at",
  "privacy-app-use-79": "Processing of specially protected data.",
  "privacy-app-use-80": "The data related to your interests or aspects related to sexual orientation will be processed based on your explicit consent.",
  "privacy-app-use-81": "How long do we keep your information?",
  "privacy-app-use-82": "We will keep all your personal information for as long as our contractual relationship is in force, that is, until you delete your account. Once the contractual relationship has ended or your account has been cancelled, CRUSH will keep, blocked, all the information during the periods provided by law to investigate illegal or harmful conduct, attend to eventual responsibilities and to demonstrate compliance with our obligations.",
  "privacy-app-use-83": "In relation to the content published by you through our App, you should take into account that any publication or comment that you have published in our community will remain in the App until you delete it or until you delete your account. However, any other content where you appear and that has been published by other users (for example, photos or videos) may remain visible until said user deletes it or closes their account.",
  "privacy-app-use-84": "However, we reserve the right to use your information in aggregate form after closing your account for statistical purposes and to improve our services. The use of such information will not allow you to be personally identified.",
  "privacy-app-use-85": "Who do we give your personal information to?",
  "privacy-app-use-86": "There are no specific transfers of your personal data to fulfill the purpose indicated in this section. However, we can use the services of service providers, who will have limited access to the data and will be bound by a duty of confidentiality (for more information on how our service providers act, see point",
  "privacy-app-use-87": "4. To whom do we give your personal information? > Service Providers",
  "privacy-app-use-88": "However, keep in mind that when you post content on our App, you may be sharing personal information with other users (including your public profile).",
  "privacy-app-use-89": "Also, if someone files a report that implicates you, we may notify the complainant of the actions we have taken as a result of their report, if any.",
  "privacy-cookies": "3.3. Browsing the Website (cookies)",
  "privacy-cookies-1": "On our Website, as well as in our App, we use cookies or other tracking and tracing tools to collect information about the use that users make of the Website and the App.",
  "privacy-cookies-2": "For more information about the treatment we carry out through said tracking tools, visit our",
  "privacy-cookies-3": "Cookies Policy",
  "privacy-social-profiles": "3.4. CRUSH profiles on social networks",
  "privacy-social-profiles-1": "CRUSH has a profile on the main social networks, such as Twitter, Instagram, LinkedIn or TikTok.",
  "privacy-social-profiles-2": "When you become a follower of one of our pages on social networks, the data processing will be governed by the conditions of use, privacy policies and access regulations that belong to the corresponding social network and previously accepted by the user.",
  "privacy-social-profiles-3": "CRUSH, in this sense, will process your data for the purpose of correctly managing its presence on the social network, informing you of activities, products or services, as well as for any other purpose that the regulations of social networks allow.",
  "privacy-social-profiles-4": "Keep in mind that we have no influence on the information that the social network collects or how it processes it, so we recommend that you keep yourself informed of the purpose and scope of the information collection that is carried out through said social networks.",
  "privacy-transfer": "4. To whom do we give your personal information?",
  "privacy-transfer-1": "In general, from CRUSH we will not communicate your data to third parties. However, in addition to the transfers that we specifically indicate in the section in which we explain the characteristics of the different operations (point 3), we inform you of the communications that, in general, we can make, as well as their legitimizing basis, which affect all previous treatments.",
  "privacy-transfer-2": "Essential service providers",
  "privacy-transfer-3": "to execute the service we offer you, (for example, computer hosting companies or platforms for sending commercial communications). Notwithstanding the foregoing, these entities have signed the corresponding confidentiality agreements and will only process your data according to our instructions, not being able to use them for their own purposes or apart from the service they provide us.",
  "privacy-transfer-4": "Public organisms.",
  "privacy-transfer-5": "We may disclose to the competent public authorities the data and any other information that is in our possession or that is accessible through our systems when there is a legal obligation to do so, as well as when required, for example, when the purpose is to prevent or prosecute abuse of services or fraudulent activities through our Website or Application. In these cases, the personal data that you provide us will be kept and made available to the administrative or judicial authorities.",
  "privacy-transfer-6": "In the case of a corporate transaction:",
  "privacy-transfer-7": "In the event of a merger, acquisition, sale of all or part of its assets, or any other type of corporate transaction involving a third party, we may share, disclose, or transfer user data to the successor entity (including during the pre-operative phase).",
  "privacy-transfer-8": "To third parties after aggregation or anonymization:",
  "privacy-transfer-9": "we may disclose or use aggregated or anonymized data (meaning data that is not linkable to an identified or identifiable natural person) for any purpose.",
  "privacy-transfer-10": "To third parties with the user's consent or other legitimate basis:",
  "privacy-transfer-11": "In the event that we want to share data with third parties outside the scope of this Privacy Policy, your consent will be requested, in any case, or you will be informed about it and its legitimate basis.",
  "privacy-transfer-12": "Likewise, we inform you that this Privacy Policy only refers to the collection, treatment and use of information (relating to personal data) by us through the interaction you carry out with our Website or Application. Access to third-party web pages that you can access through links from the Website or the App have their own privacy policies over which we have no control. Therefore, before providing them with any personal information, we recommend that you find out about their Privacy Policies.",
  "privacy-eea": "5. Is your personal data transferred to third countries outside the European Economic Area?",
  "privacy-eea-1": "Some of our service providers are located in countries outside the European Economic Area (",
  "privacy-eea-2": "'EEA'",
  "privacy-eea-3": "The location of these companies outside the EEA implies the existence of an international transfer of your personal data, which could imply a lower degree of protection than that included in European regulations. However, from CRUSH we have applied measures so that said transfers do not give rise to a lower degree of protection of your personal data.",
  "privacy-eea-4": "In this sense, the service providers that are outside the EEA have signed the corresponding standard contractual clauses approved by the European Commission (",
  "privacy-eea-5": "'CCT'",
  "privacy-eea-6": "), an agreement signed between both entities by which the non-EU company guarantees that it applies the European standards of data protection.",
  "privacy-eea-7": "Therefore, the use of these providers does not give rise to a lower degree of protection of your personal data than the use of providers located in the European Union. You can consult the content of the CCT in the following link:",
  "privacy-eea-8": "Next, we indicate which are the international transfers that we make from CRUSH:",
  "privacy-eea-9": "Name",
  "privacy-eea-10": "Affected activities",
  "privacy-eea-11": "Country",
  "privacy-eea-12": "Transfer mechanism",
  "privacy-eea-13": "All",
  "privacy-eea-14": "Face detection and search for similar-looking faces",
  "privacy-eea-15": "Push notifications",
  "privacy-eea-16": "Sending emails",
  "privacy-eea-usa": "USA",
  "privacy-eea-sccs": "Standard Contractual Clauses (SCCs)",
  "privacy-rights": "6. What are the rights that you can exercise as an interested?",
  "privacy-rights-1": "You can exercise the rights that the law guarantees you in relation to the processing of your personal data by contacting our Data Protection Delegate via email",
  "privacy-rights-2": "Any request for rights that we receive will be resolved as soon as possible and, in any case, within the maximum period established by law, from the date of receipt. In some cases, it will be necessary to request a copy of your identity document or other identification document to verify your identity.",
  "privacy-rights-3": "The rights that correspond to you as an interested party are the following:",
  "privacy-rights-4": "Right to withdraw the consent granted",
  "privacy-rights-5": "You can revoke your consent in relation to all processing based on it at any time. However, the withdrawal of consent will not affect the legality of the treatment based on the consent prior to its withdrawal.",
  "privacy-rights-6": "Right of access",
  "privacy-rights-7": "You have the right to know what data is being processed, if applicable and, if so, to obtain a copy of it, as well as to obtain information regarding:",
  "privacy-rights-8": "the origin and recipients of the data;",
  "privacy-rights-9": "the purposes for which they are processed;",
  "privacy-rights-10": "whether there is an automated decision-making process, including profiling;",
  "privacy-rights-11": "the data retention period; and",
  "privacy-rights-12": "the rights provided by the regulations.",
  "privacy-rights-13": "Right to rectification",
  "privacy-rights-14": "You have the right to obtain the rectification of your personal data or to complete it when it is incomplete.",
  "privacy-rights-15": "Right to erasure",
  "privacy-rights-16": "You have the right to request the deletion of your personal data if they are no longer necessary for the purpose for which they were collected or, where appropriate, if we are no longer authorized to process them.",
  "privacy-rights-17": "Right to data portability",
  "privacy-rights-18": "You have the right to request the portability of your data in the case of treatments that are based on your consent or on the execution of a contract, as long as the processing has been carried out by automated means. In the event of exercising this right, you will receive your personal data in a structured format, commonly used and readable by any electronic device. However, you can also request, when possible, that your data be transmitted directly to another company.",
  "privacy-rights-19": "Right to restrict processing",
  "privacy-rights-20": "You have the right to limit the processing of your data in the following cases:",
  "privacy-rights-21": "When you have requested the rectification of your personal data, during the period in which we verify their accuracy.",
  "privacy-rights-22": "When you consider that we are not authorized to process your data. In that case, you can ask us to limit its use instead of requesting its deletion.",
  "privacy-rights-23": "When you consider that it is no longer necessary for us to continue processing your data and you want us to keep them for the purposes of exercising or defending claims.",
  "privacy-rights-24": "In cases where there is processing based on our legitimate interest and you have exercised your right to oppose it, you can ask us to limit the use of your data during the verification of the prevalence of said interests with respect to yours.",
  "privacy-rights-25": "Right to object",
  "privacy-rights-26": "You have the right to object at any time to the processing of your personal data based on our legitimate interest, including profiling.",
  "privacy-rights-27": "Right to lodge a complaint with a Supervisory Authority",
  "privacy-rights-28": "Remember that, at any time, and in the event that you consider that we have violated your right to the protection of your data, you can address your defense to the corresponding Control Authority, in the case of Spain, the Spanish Agency for Data Protection (",
  "privacy-confidence": "7. How do we guarantee the confidentiality of your information?",
  "privacy-confidence-1": "The security of your personal data is a priority for us. For this reason, CRUSH has implemented all the necessary security measures to guarantee effective use and treatment of the personal data provided by the user, safeguarding the intimacy, privacy, confidentiality and integrity of these, and makes use of the technical means necessary to avoid alteration, loss, unauthorized access or treatment of your data, according to the state of technology at all times.",
  "privacy-confidence-2": "Consequently, we comply with recommended security standards to protect them. However, it is impossible to fully guarantee your security due to the very nature of the Internet and because there may be malicious actions by third parties beyond our control.",
  "privacy-confidence-3": "We promise to act quickly and diligently in case the security of the data is in danger or compromised, and to inform you about it in case it is relevant.",
  "privacy-modifications": "8. Modifications to this policy",
  "privacy-modifications-1": "From CRUSH we can modify the content of this Privacy Policy at any time, especially when there are legislative, jurisprudential or interpretation modifications of the Spanish Agency for Data Protection that affect the data processing carried out by CRUSH through the Website or the App. Any new version of this Privacy Policy will be effective on the published effective date.",
  "privacy-modifications-2": "If the revised version includes a substantial change that affects the processing of your data, we will notify you at least 30 days in advance by posting a notice on our Website or our App or by notifying you via email. In any case, we recommend that you periodically review this Privacy Policy to be informed of how your personal data is treated and protected, as well as your rights.",
  "cookies-title": "Crush - Cookies",
  "cookies-date": "Last updated: December 8, 2023",
  "cookies-header": "Cookies policy",
  "cookies-introduction-1": "Both on the website www.crushsocialnetwork.com (hereinafter, the",
  "cookies-introduction-2": "'Website'",
  "cookies-introduction-3": ") as well as through the mobile application for Smartphones with the name 'Crush' (hereinafter, the",
  "cookies-introduction-4": "'App'",
  "cookies-introduction-5": ") we use cookies or other tracking and tracing tools to collect information about the use that users make of the Website and the App.",
  "cookies-introduction-6": "In this document, you will discover which cookies we give to the Cookie Monster, as well as any other type of related information regarding this wonderful bite.",
  "cookies-definition": "1. What is a cookie?",
  "cookies-definition-1": "A cookie is a file that is installed in the browser of your device (computer, mobile, etc.) in order to store and retrieve data. Cookies can have a multitude of purposes, depending on what they have been configured for: for example, saving preferences, collecting usage statistics, enabling technical features, among others.",
  "cookies-responsible": "2. Who is the responsible?",
  "cookies-responsible-1": "The responsible for the treatment of the information collected through cookies is CRUSH SOCIAL NETWORK, LTD. (CRUSH), with NIF B-72599830 and address at Calle Pintor Velázquez, 3, 1, 30530 Cieza, Murcia (Spain).",
  "cookies-responsible-2": "If you have any questions related to the use of cookies, please write an email to",
  "cookies-responsible-3": "and we will reply to you as soon as possible.",
  "cookies-data": "3. What data is collected through cookies?",
  "cookies-data-1": "When you access the Website or install and use the App, even if you have not registered, the following information is collected through cookies:",
  "cookies-data-2": "Device information: During the use of the Website or the App, we collect information about the mobile device from which you access the platform. The information we collect is the device model, operating system and version, unique device identifier, and network.",
  "cookies-data-3": "Information about your location: We also obtain the IP address, time zone and what the mobile service provider is, if any, which allows us to obtain a general location.",
  "cookies-data-4": "User navigation information: We collect information about the use you make of the Website and the App. Specifically, the frequency of use, the sections you visit, use of specific functions, etc.",
  "cookies-types": "4. What types of cookies are there?",
  "cookies-types-1": "According to their purpose,",
  "cookies-types-2": "we can classify cookies among the following:",
  "cookies-types-3": "Technical cookies:",
  "cookies-types-4": "These cookies are strictly necessary to navigate the Website or the App and allow basic functions such as browsing from one section to another. They are in charge of technical aspects such as identifying profiles or sessions and managing tool preferences in the use, interaction and location of access to content. Also belonging to this category, due to their technical nature, are those cookies that allow the management, in the most efficient way possible, of the advertising spaces included in the Website and the App, without collecting information from users for different purposes, such as personalizing ads or other types of content.",
  "cookies-types-5": "Statistical cookies:",
  "cookies-types-6": "These cookies measure and collect statistical information regarding the use made by the user of the Website or the App, such as the time spent on the platform, the number of visits or the contents visited. Said information allows us to understand how users interact with the Website and the App, evaluate performance, which sections are the most or least visited, including the quantification of the impact of the advertisements introduced on the Website and the App (if applicable). Having this information can solve errors, and improve the design of the system and the content offered through it.",
  "cookies-types-7": "Preference or personalization cookies:",
  "cookies-types-8": "Preference cookies allow the Website or the App to remember specific user information so that they can access the service with certain characteristics that can differentiate their experience from that of other users. For example, your preferred language or the region from which you access, the number of results to display when the user performs a search, the appearance or content of the service depending on the type of device from which the user accesses it, etc.",
  "cookies-types-9": "Behavioral advertising cookies:",
  "cookies-types-10": "These cookies allow the management of the advertising spaces that, where appropriate, have been included in the Website or the App. However, the management of the advertising spaces is done based on the user's browsing profile. These cookies store user browsing information obtained through continuous observation of their browsing habits, developing a specific user profile, so the ads that appear in these spaces will be personalized for the user according to said habits.",
  "cookies-types-11": "Depending on the entity that manages them:",
  "cookies-types-12": "Own cookies:",
  "cookies-types-13": "are those that are sent to the user's device from a computer or domain managed by the person responsible for the Website or App, in this case, CRUSH, and from which the service requested by the user is provided.",
  "cookies-types-14": "Third-party cookies:",
  "cookies-types-15": "are those that are sent to the user's device from a computer or domain that is not managed by the person responsible for the Website or the App, but by another entity that processes the data obtained through cookies.",
  "cookies-types-16": "Depending on the time they remain active on the device:",
  "cookies-types-17": "Session cookies:",
  "cookies-types-18": "They are designed to collect and store data during the time the user is browsing the Website or the App, and are deactivated at the end of the browsing.",
  "cookies-types-19": "Persistent cookies:",
  "cookies-types-20": "These store data that can be accessed and processed during a period defined by the person responsible for the cookie. Their lifetimes can range from minutes to years.",
  "cookies-used": "5. What cookies do we use specifically on our platform?",
  "cookies-used-1": "On our platform, we use the following cookies:",
  "cookies-used-2": "Name",
  "cookies-used-3": "Purpose",
  "cookies-used-4": "Own/Third-Party Cookies",
  "cookies-used-5": "Expiration",
  "cookies-used-6": "14 months",
  "cookies-used-7": "Indefinite",
  "cookies-used-stats": "Statistics",
  "cookies-used-technical": "Technical",
  "cookies-used-third-party-google": "Third-Party (Google LLC.)",
  "cookies-legitimation": "6. What is the basis of legitimation that allows us to process your data through cookies? If so, are they mandatory?",
  "cookies-legitimation-1": "The information we collect through cookies and other tracking devices is based on your consent, which we request when you access the Website or when you install the App through an informative pop-up or in the manner established by Google (when it comes to Android) or Apple (when it comes to iOS).",
  "cookies-legitimation-2": "In this sense, at any time you can withdraw your consent as explained in the section related to the revocation of consent.",
  "cookies-legitimation-3": "Technical cookies cannot be disabled, as otherwise the proper functioning of the Website or App would be compromised. In general, they are only set in response to actions taken when requesting services from us, such as setting your preferences in relation to the cookies themselves, log in or complete forms. In the event that the deactivation occurs, this could cause the use of some of the services of the Website or the App to be limited due to not being able to access certain functionalities and therefore your experience would be less satisfactory.",
  "cookies-transfer": "7. To whom do we give your personal information? Are there international data transfers?",
  "cookies-transfer-1": "We use some third-party cookies, which are those cookies collected and managed by a third party, so said entities will receive your personal data collected through cookies for the purposes indicated for each cookie. These types of cookies are indicated in the table in section 5, as 'Third-Party Cookies'. This table identifies the third party that manages each cookie.",
  "cookies-transfer-2": "You can find out about the transfers to third countries that, where appropriate, are made by the third parties identified in this Cookies Policy in their corresponding policies.",
  "cookies-consent": "8. What is the way to accept, deny or revoke the consent for the use of cookies on this website?",
  "cookies-consent-1": "When you access our Website or our App for the first time, you will be shown a window in which you can indicate whether you accept cookies or whether you wish to configure or reject them, except those that are strictly necessary. Likewise, you can withdraw the previously granted consent at any time.",
  "cookies-consent-2": "If you wish to withdraw your consent related to the Cookies Policy at any time, you have two options: (i) withdraw your consent through the buttons enabled for this purpose in this Cookies Policy; or (ii) delete cookies stored on the device through the settings and configurations of your Internet browser.",
  "cookies-consent-3": "Keep in mind that, if you accept third-party cookies, you must eliminate them from the browser options or from the system offered by the third party itself.",
  "cookies-consent-4": "You can expand the information on how to disable cookies, depending on your browser, in the following links:",
  "cookies-consent-5": "; for iOS:",
  "cookies-rights": "9. What are the rights that you can exercise as an interested?",
  "cookies-rights-1": "The rights that you have as an interested party in relation to the protection of your personal data are indicated in the section 'What are the rights that you can exercise as an interested?' within the",
  "cookies-rights-2": "Privacy Policy",
  "cookies-modifications": "10. Modifications to this policy",
  "cookies-modifications-1": "We may update the Cookie Policy of our platform from time to time. For this reason, we recommend that you review this policy each time you access our Website or App in order to be adequately informed about how and for what we use cookies."
}
