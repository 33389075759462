{
  "404-title": "Crush - No encontrado",
  "404-not-found-1": "No encontrado",
  "404-not-found-2": "Lo sentimos, la página que buscas no está disponible.",
  "navbar-mission": "Misión",
  "navbar-download": "Descargar",
  "footer-follow": "Síguenos",
  "footer-follow-1": "Instagram",
  "footer-follow-2": "TikTok",
  "footer-follow-3": "Twitter",
  "footer-follow-4": "LinkdIn",
  "footer-docs": "Documentación",
  "footer-docs-1": "Misión",
  "footer-docs-2": "Aviso legal",
  "footer-docs-3": "Privacidad",
  "footer-docs-4": "Cookies",
  "footer-docs-5": "Directrices",
  "footer-contact": "Contáctanos",
  "footer-contact-1": "Soporte",
  "footer-contact-2": "Empleos",
  "footer-contact-3": "Inversores",
  "home-title": "Crush - Donde los medios se encuentran con lo platónico",
  "home-hero-slogan-1": "Where media",
  "home-hero-slogan-2": "meets platonic ™",
  "home-concept-1": "Encuentra a tu gente",
  "home-concept-2": "Concecta con personas como tú en las diferentes etapas de tu vida.",
  "home-concept-3": "Vibras guays",
  "home-concept-4": "Comparte buenas vibras y momentos reales. Nada de toxicidad o pretensiones.",
  "home-attributes-1": "Aesthetic",
  "home-attributes-2": "Promovemos la publicación de contenido aesthetic y de alta calidad; Internet ya está inundado de fotos de gatos.",
  "home-attributes-3": "Stalker",
  "home-attributes-4": "Tus amigos son las estrellas. Tómales unas ráfagas de fotos cuando estén desprevenidos y publica GIFs en sus cuentas.",
  "home-attributes-5": "Hilos",
  "home-attributes-6": "Participa en discusiones vibrantes que abarcan desde los temas más actuales hasta las tendencias que darán forma al mañana.",
  "home-attributes-7": "Gemelos",
  "home-attributes-8": "Conecta con personas que tienen rasgos faciales similares a los tuyos, ampliando tu red social de una manera completamente novedosa y divertida.",
  "home-attributes-9": "Segmentación por edad",
  "home-attributes-10": "Al agrupar a los usuarios por edad, como en las clases de instituto, las conexiones se limitan a individuos de la misma generación para una interacción más segura y eficiente.",
  "home-attributes-11": "Crush",
  "home-attributes-12": "No es una aplicación de citas — solo amigos y relaciones relajadas. Recupera la nostalgia de las conexiones de la escuela secundaria en un entorno digital.",
  "home-partners": "Powered By",
  "mission-title": "Crush - Misión",
  "mission-date": "Última modificación: 8 diciembre, 2023",
  "mission-header": "Misión y valores",
  "mission-introduction": "En el corazón de Crush late algo más que una simple red social; es un universo tejido con valores éticos, una filosofía y un propósito. Este documento es el viaje a través de los cimientos de nuestra visión, desde el nacimiento de la idea hasta el minucioso esbozo de la creación que estamos forjando.",
  "mission-origin": "El origen",
  "mission-origin-1": "El origen de Crush se remonta a una semilla sembrada mucho antes de su desarrollo concreto en 2020 y su lanzamiento en 2023. Nuestra visión de fusionar redes sociales con el toque distintivo de Crush se gestó en 2016, inspirada por 'La red social', la notable obra cinematográfica reconocida por Quentin Tarantino como una joya de la década pasada. Esta película, que relata la historia de los jóvenes fundadores de Facebook, especialmente de su figura más reconocida, Mark Zuckerberg — el padre de las redes sociales, nos brindó una perspectiva reveladora.",
  "mission-origin-2": "En Crush, creemos fervientemente que el núcleo de las redes sociales actuales reside en el amor platónico. Si has tenido la oportunidad de ver esta película, es posible que hayas captado las críticas de Zuckerberg y Eduardo Saverin hacia las aplicaciones de citas. En una escena clave, aproximadamente en el minuto 30:00, durante el litigio de los hermanos Winklevoss por supuesto robo de idea, Saverin menciona:",
  "mission-origin-3": "\"It really hadn't much to do with the Winklevoss' dating site\".",
  "mission-origin-4": "Es curioso, pues unos minutos después, alrededor del minuto 36:05, vemos a Mark implementando elementos como el estado sentimental e intereses en su proyecto:",
  "mission-origin-5": "\"Relationship status, interests... This is what drives life in college. Are you having sex or aren't you? That's why people take classes, sit where they sit, and do what they do. It's the center, you know? That's what The Facebook is going to be.\".",
  "mission-origin-6": "Por último, en la escena final, presenciamos a un Zuckerberg enamorado enviando una solicitud de amistad a su crush.",
  "mission-origin-7": "Esta película nos plantea una intrigante pregunta:",
  "mission-origin-8": "¿Son las redes sociales en realidad aplicaciones de citas disfrazadas?",
  "mission-purpose": "Nuestro propósito",
  "mission-purpose-1": "No existe una respuesta definitiva a la pregunta planteada en la sección anterior. Tal vez sí, tal vez no... al final, depende de los valores éticos y culturales, las intenciones y la educación del usuario final. Lo que sí sabemos con certeza es que esto deja la puerta abierta a problemas reales y muy serios que, a diferencia de en el mundo real, sí que pueden ser solucionados.",
  "mission-purpose-2": "Ya sea por las normas preestablecidas por la sociedad o el anonimato que conduce a comportamientos inapropiados en línea, el amor en el ámbito digital, ya sea platónico y genuino en las redes sociales o más romántico en las aplicaciones de citas, ha sido, desafortunadamente, sexualizado en los últimos años. ¿Por qué algo tan hermoso y fundamental en nuestra vida está siendo distorsionado en este universo paralelo?",
  "mission-purpose-3": "Vamos a ser claros y completamente transparentes. En España, nuestro país de origen, según datos y estadísticas verificables,",
  "mission-purpose-4": "7 de cada 10 menores de 15 años tienen un dispositivo móvil.",
  "mission-purpose-5": "Esto significa que alrededor del 70% de los menores tiene acceso sin restricciones a un mundo virtual donde la interacción con adultos no ha sido regulada ni restringida, exponiéndolos a riesgos como el acoso, el bullying o la pedofilia, entre otros.",
  "mission-purpose-6": "En la actualidad, independientemente de la red social en la que te registres, es común encontrar a personas vendiendo contenido inapropiado o compartiendo cualquier tipo de contenido inadecuado. A más corre el tiempo, más asquerosa es la situación.",
  "mission-purpose-7": "Queremos eliminar la sexualización y la toxicidad de la red, abordando su origen de manera directa.",
  "mission-purpose-8": "Crush se plantea como una redefinición de las redes sociales desde una perspectiva distinta, centrada en las relaciones platónicas.",
  "mission-purpose-9": "No ofrecemos un sistema de dating o la creación de relaciones románticas/serias, ya que eso contradice nuestras",
  "mission-purpose-10": "normas comunitarias.",
  "mission-purpose-11": "En cambio, ofrecemos lo mismo que se ideaba en la película de 'La red social', pero con una solución al problema y un enfoque más guay.",
  "mission-purpose-12": "Nuestra misión trasciende el típico \"comparte momentos reales\" o \"te ayudamos a conectar\". Además de acompañar al usuario en las diversas etapas de su vida, nos dedicaremos a resolver los problemas anteriormente mencionados. En nuestra plataforma, al momento de redactar este documento, ya es imposible que un menor interactúe con un mayor de edad (y viceversa), no solo para cerrar las puertas a esas cuestiones éticas, sino también para fomentar la creación de vínculos entre personas de la misma generación, con una mentalidad afín. Planeamos presentar más soluciones eficaces en la próxima década de desarrollo; es nuestra promesa.",
  "guidelines-title": "Crush - Directrices",
  "guidelines-date": "Última modificación: 4 abril, 2024",
  "guidelines-header": "Normas comunitarias",
  "guidelines-introduction-1": "En Crush, se trata de establecer conexiones platónicas, compartir momentos auténticos de la vida con amigos, socializar y crear nuevas amistades afines a tu mentalidad. Creemos firmemente en la libertad de expresión, pero también valoramos la capacidad de distinguir entre el bien y el mal. Este documento detalla los estándares de nuestra red social, esenciales para cumplir con",
  "guidelines-introduction-2": "nuestro principal propósito.",
  "guidelines-introduction-3": "Esperamos un comportamiento adecuado, cuidado en el contenido, autenticidad, humildad y, sobre todo, responsabilidad. Cualquier violación de nuestros principios fundamentales podría resultar en la suspensión permanente de tu cuenta, sin posibilidad de volver a registrarte en nuestro sistema.",
  "guidelines-introduction-4": "Al igual que en la vida real, hay consecuencias para las acciones. Por favor, respeta nuestras reglas y no utilices nuestro sistema de manera contraria a la ley. Queremos enfatizar nuestra seriedad y rigurosidad; el tono de juego no es bienvenido en este contexto.",
  "guidelines-dating": "Anti-dating",
  "guidelines-dating-1": "Crush es una combinación de redes sociales apta para todas las personas",
  "guidelines-dating-2": "mayores de 15 años de edad,",
  "guidelines-dating-3": "estén o no estén en una relación sentimental. No confundas los amigos y las relaciones platónicas (opcionales) con las relaciones románticas o compromisos reales.",
  "guidelines-dating-4": "A continuación, te detallamos una serie de comportamientos que debes adoptar y respetar para permanecer en nuestra plataforma:",
  "guidelines-dating-5": "No utilices nuestro servicio para buscar ni solicitar encuentros románticos.",
  "guidelines-dating-6": "Si el amor ha llegar a tu vida, que no sea a través de una pantalla.",
  "guidelines-dating-7": "No publiques guarradas.",
  "guidelines-dating-8": "No acoses ni molestes a ningún usuario.",
  "guidelines-dating-9": "No mientas en tu edad o datos personales, y mucho menos suplantes la identidad de otra persona.",
  "guidelines-dating-10": "Es un delito muy grave que podría conllevar acciones legales en tu contra.",
  "guidelines-content": "Aesthetic",
  "guidelines-content-1": "Para nosotros, cualquier forma de software es arte, y el arte debe ser respetado. En una red social, la calidad de la plataforma se refleja en el contenido que los usuarios generan. En nuestro caso, fomentamos la creatividad y la inspiración. Te pedimos que publiques manteniendo un estándar mínimo de calidad.",
  "guidelines-content-2": "No publiques contenido de mala calidad visual.",
  "guidelines-content-3": "Haz un esfuerzo.",
  "guidelines-content-4": "El spam es eliminación directa.",
  "guidelines-content-5": "Si quieres promocionar tu Instagram, utiliza las herramientas que te proporcionamos para ello.",
  "guidelines-content-6": "No compartas contenido que infrinja la propiedad intelectual de otros.",
  "guidelines-content-7": "Por favor, evita publicar fotos de gatitos;",
  "guidelines-content-8": "en Internet ya existen muchas.",
  "guidelines-content-9": "No hagas el ridículo publicando desnudez innecesaria.",
  "guidelines-content-10": "Estamos cansados de las fotografías sin camiseta.",
  "guidelines-vibes": "Sólo buenas vibras",
  "guidelines-vibes-1": "En Crush, no toleramos comportamientos inapropiados. Al igual que cuidas tu imagen en la vida real, te pedimos que seas responsable y mantengas esa integridad en Internet. Demuestra educación y respeto tanto hacia los usuarios de nuestra comunidad como hacia los creadores de la plataforma.",
  "guidelines-vibes-2": "La explotación infantil, la extorsión sexual o cualquier otro tipo de abuso, en cualquier forma, están estrictamente prohibidos.",
  "guidelines-vibes-3": "Nuestro compromiso contra el suicidio y la autolesión es absoluto.",
  "guidelines-vibes-4": "Cualquier contenido que promueva, anime o enaltezca estas acciones resultará en una suspensión permanente.",
  "guidelines-vibes-5": "Nos tomamos en serio la legalidad.",
  "guidelines-vibes-6": "Evita compartir contenido que promueva actividades ilegales, así como cualquier contenido relacionado con armas o drogas.",
  "guidelines-vibes-7": "El contenido ofensivo o violento no tiene lugar en nuestra plataforma y no está permitido.",
  "guidelines-vibes-8": "El modo Stalker tiene como finalidad la diversión entre amigos.",
  "guidelines-vibes-9": "Aunque, en última instancia, el contenido deba ser aprobado por el usuario o los usuarios que aparecen en el GIF, evita a toda costa compartir fotografías de tus colegas sin consentimiento previo.",
  "legal-title": "Crush - Aviso legal",
  "legal-date": "Última modificación: 4 abril, 2024",
  "legal-header": "Aviso legal",
  "legal-introduction-1": "Cada aplicación es un universo y todos los universos están sujetos a reglas. En este documento encontrarás el aviso legal y los términos generales de uso de nuestra plataforma, complementados por los definidos en nuestras",
  "legal-introduction-2": "reglas comunitarias.",
  "legal-owner": "1. Identificación del titular",
  "legal-owner-1": "El sitio web www.crushsocialnetwork.com (en adelante, el",
  "legal-owner-2": "'Sitio Web'",
  "legal-owner-3": ") y la aplicación móvil para Smartphone con el nombre 'Crush' (en adelante, la",
  "legal-owner-4": "'App'",
  "legal-owner-5": ") son titularidad de CRUSH SOCIAL NETWORK, S.L., provista de NIF: B-72599830 e inscrita en el Registro Mercantil de Valencia con los siguientes datos regristrales: T. 3606, F.75, I.1, H. MU-108656, y cuyos datos de contacto son:",
  "legal-owner-6": "Domicilio Social:",
  "legal-owner-7": "Calle Pintor Velázquez, 3, 1, 30530 Cieza, Murcia (España)",
  "legal-owner-8": "Email de contacto:",
  "legal-area": "2. Ámbito de aplicación",
  "legal-area-1": "Las presentes Condiciones Generales de Uso del Sitio Web (en adelante, las",
  "legal-area-2": "'Condiciones de Uso'",
  "legal-area-3": ") recogen los términos y condiciones que regulan el acceso, navegación y uso del Sitio Web y la App.",
  "legal-area-4": "En este sentido, el acceso, uso y navegación por el Sitio Web y la App confiere la condición de usuario(s) (en adelante, el",
  "legal-area-5": "'Usuario'",
  "legal-area-6": "o los",
  "legal-area-7": "'Usuarios'",
  "legal-area-8": "), e implica la aceptación de todas y cada una de las presentes Condiciones de Uso. El Usuario es plenamente consciente de que la mera navegación por el Sitio web o la App implica la aceptación de las presentes Condiciones de Uso y, por ello, si el Usuario no está de acuerdo con las mismas, no deberá utilizar el Sitio Web o la presente App.",
  "legal-area-9": "El Sitio Web y la App están dirigidos principalmente a Usuarios residentes en España. Crush no asegura que el Sitio Web o la App cumplan cumplan con legislaciones de otros países, ya sea total o parcialmente. Si el Usuario reside o tiene su domicilio en otro lugar y decide acceder y/o navegar en el Sitio Web o acceder o usar la App, lo hará bajo su propia responsabilidad y deberá asegurarse de que tal acceso, navegación o uso cumple con la legislación local que le es aplicable, no asumiendo Crush responsabilidad alguna que se pueda derivar de dicho acceso o uso.",
  "legal-area-10": "Crush podrá, en cualquier momento, y sin previo aviso, modificar las presentes Condiciones de Uso, las cuales serán publicadas a medida que se produzcan dichas modificaciones.",
  "legal-conditions": "3. Condiciones de acceso y uso",
  "legal-conditions-1": "El acceso al Sitio Web o a la App por el Usuario, por regla general, es gratuito. En el supuesto de que existan funcionalidades o servicios reservados a determinados Usuarios o que sea necesario pagar un precio, estos quedarán debidamente identificados en el Sitio Web o en la App.",
  "legal-conditions-2": "El acceso al Sitio Web o la App se realiza bajo exclusiva responsabilidad del Usuario, y no supone entablar ningún tipo de relación de carácter comercial entre Crush y el Usuario. El Usuario es responsable de asegurarse que las informaciones y contenidos incluidos en el Sitio Web o en la App cumplen con sus requerimientos específicos.",
  "legal-conditions-3": "El Usuario se compromete a:",
  "legal-conditions-4": "Acceder y utilizar el Sitio Web como la App conforme a la buena fe y de acuerdo con las presentes Condiciones de Uso, la Ley, la moral y el orden público, con el requisito adicional de alcanzar una edad mínima de 15 años.",
  "legal-conditions-5": "Desde Crush, certificamos que cumplimos con la legislación aplicable sobre seguridad infantil y abordamos de manera seria el material de abuso sexual infantil. Tomamos medidas adecuadas, como la eliminación del mencionado material una vez que tengamos conocimiento real de su existencia, de acuerdo con nuestros estándares y las leyes pertinentes.",
  "legal-conditions-6": "Aportar información veraz, actual y lícita en los formularios ofrecidos tanto en el Sitio Web como en la App. En todo caso, el Usuario notificará de forma inmediata a Crush acerca de cualquier hecho que permita el uso indebido de la información registrada en dichos formularios, tales como, pero no sólo, el robo, extravío, o el acceso no autorizado a identificadores y/o contraseñas, con el fin de proceder a su inmediata cancelación.",
  "legal-conditions-7": "No realizar ninguna acción sobre el Sitio Web o la App que pueda ocasionar daños o alteraciones en los contenidos, programas o sistemas del Sitio Web o la App, incluyendo la introducción de virus informáticos, la instalación de robots, o cualquier software o archivo dañino, defectuoso, o en definitiva que pueda causar daños a nuestros sistemas informáticos.",
  "legal-conditions-8": "El Usuario responderá en todo caso de los daños y perjuicios que pueda causar tanto a Crush como a terceros y Crush podrá adoptar las medidas técnicas, legales y de cualquier índole que estime pertinentes para prevenir, mitigar o detener las consecuencias de las conductas prohibidas anteriores y para exigir cuantas responsabilidades se estimen pertinentes.",
  "legal-property": "4. Propiedad intelectual e industrial",
  "legal-property-1": "Todos los contenidos, tanto del Sitio Web como de la App, incluyendo, sin carácter limitativo, los textos, imágenes, fotografías, vídeos, gráficos, signos distintivos de cualquier clase, iconos, interfaces, así como el software, código fuente, diseños, arquitectura, presentación, disposición y clasificación de los contenidos y cualquier otro elemento presente en el Sitio Web o la App que pueda ser objeto de derechos de propiedad intelectual son propiedad de Crush o de terceros que han licenciado, autorizado o consentido su utilización en el Sitio Web o en la App.",
  "legal-property-2": "En este sentido, cuando el Usuario accede, navega y utiliza el Sitio Web o accede o usa la App, no se le confiere ningún derecho de explotación que exista o pueda existir sobre la totalidad o parte de estos, reservándose Crush todos estos derechos. El Usuario únicamente puede visualizar los elementos del Sitio Web y la App y utilizarlos en la medida de lo estrictamente necesario para el correcto uso de estos. En especial, esta prohibido utilizar o revender con fines comerciales cualquier material o contenido presente en el Sitio Web o la App sin contar con la previa autorización de Crush.",
  "legal-property-3": "Crush se reserva la facultad de modificar, en cualquier momento, y sin aviso previo, la presentación y configuración del Sitio Web o de la App y de los contenidos y servicios que en ellos pudieran estar incorporados. El Usuario reconoce y acepta que en cualquier momento Crush pueda interrumpir, desactivar y/o cancelar cualquiera de estos elementos que se integran en el Sitio Web y la App o el acceso a los mismos.",
  "legal-property-4": "Si el Usuario considera que cualquiera de los contenidos del Sitio Web o de la App supone una violación de los derechos de protección de la propiedad intelectual, deberá comunicarlo inmediatamente a Crush a través de los datos de contacto del apartado 1 (Identificación del Titular) de estas Condiciones de Uso.",
  "legal-disclaimer": "5. Exclusión de garantías y responsabilidad",
  "legal-disclaimer-1": "La información publicada en el Sitio Web o en la App puede no ser exhaustiva o no estar totalmente actualizada, por lo que Crush no asume ninguna responsabilidad derivada de la falta de integridad, actualización o precisión de los datos e informaciones contenidas en las distintas páginas que forman parte del Sitio Web o de la App.",
  "legal-disclaimer-2": "Crush no garantiza el acceso a la App o al Sitio Web sea ininterrumpido o que esté libre de errores. Asimismo, Crush tampoco garantiza que el contenido o software presente en el Sitio Web o la App no causen un daño al sistema informático (software y hardware) del Usuario. Crush no será responsable por las pérdidas, navegación y el uso del Sitio Web o la App, incluyéndose, pero no limitándose, a los ocasionados a los sistemas informáticos o los provocados por la introducción de virus.",
  "legal-disclaimer-3": "Crush, igualmente, queda exonerada de cualquier responsabilidad derivada de un uso inadecuado del Sitio Web o la App por parte del Usuario.",
  "legal-links": "6. Enlaces",
  "legal-links-1": "Se informa que tanto en el Sitio Web como en la App puedan encontrarse, entre otros, links, banners, botones, directorios y motores de búsqueda que permiten a los Usuarios acceder a otros sitios webs o apps pertenecientes y/o gestionados por terceros y que, por tanto, escapan al control de Crush, quien no pueda asumir responsabilidades por el contenido que aparezca en dichas páginas o apps.",
  "legal-links-2": "En caso de que considere que dicho contenido resulta inapropiado o contrario a los fines de Crush, le rogamos que lo ponga en nuestro conocimiento a través de los medios de contacto acteriormente expuestos, a fin de que adoptemos las medidas oportunas.",
  "legal-jurisdiction": "7. Legislación aplicable y jurisdicción",
  "legal-jurisdiction-1": "Las presentes Condiciones de Uso y su ejecución quedan sometidas a la legislación española.",
  "legal-jurisdiction-2": "Si surgiese cualquier controversia sobre la interpretación o aplicación de las presentes Condiciones de Uso, las Partes negociarán de buena fe para intentar resolver tal discrepancia o reclamación. No obstante, para el supuesto de que la discrepancia o la reclamación no se resolviera las Partes se someterán a los juzgados o tribunales que correspondan conforme a derecho.",
  "privacy-title": "Crush - Privacidad",
  "privacy-date": "Última modificación: 8 diciembre, 2023",
  "privacy-header": "Política de privacidad",
  "privacy-introduction-1": "En Crush, nos comprometemos a asegurar que tus datos personales estén protegidos y no se utilicen para finalidades distintas de las indicadas en la presente Política de Privacidad. Por esta razón, en este apartado informamos a los usuarios e interesados de todo lo concerniente al tratamiento de sus datos personales, dando así cumplimiento a la normativa de protección de datos aplicable en nuestro país: Reglamento General de Protección de Datos (UE) 2016/679, de 27 de abril relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (en adelante,",
  "privacy-introduction-2": "'RGPD'",
  "privacy-introduction-3": ") y Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales (en adelante,",
  "privacy-introduction-4": "'LOPDGDD'",
  "privacy-introduction-5": "La presente Política de Privacidad es aplicable a los tratamientos de datos que CRUSH SOCIAL NETWORK, S.L., realiza tanto a través sitio web: www.crushsocialnetwork.com (en adelante, el 'Sitio Web'), como a través de la aplicación móvil para Smartphone con el nombre 'Crush' (en adelante, la 'App') y/o aquellos que se indiquen. Te recomendamos que la leas con detenimiento antes de utilizar el Sitio Web o la App y suministrar tus datos a través de estos. No dudes en consultarnos cualquier duda al respecto a través del correo electrónico:",
  "privacy-index": "Índice de contenidos",
  "privacy-index-1": "En esta Política encontrarás toda la información relativa al tratamiento de tus datos personales y a los derechos que puedes ejercer para mantener el control sobre los mismos. En este sentido, encontrarás información sobre:",
  "privacy-index-2": "Quién es el responsable del tratamiento de sus datos.",
  "privacy-index-3": "Qué requisitos debes cumplir para suministrarnos tus datos personales.",
  "privacy-index-4": "Qué tratamientos de datos realizamos a través de la Aplicación y cuáles son sus principales características, explicándote:",
  "privacy-index-5": "Qué datos recogemos y cuáles son las vías de recogida de estos.",
  "privacy-index-6": "Para qué finalidades recabamos los datos que te solicitamos.",
  "privacy-index-7": "Cuál es la legitimación para su tratamiento.",
  "privacy-index-8": "Durante cuánto tiempo los conservamos.",
  "privacy-index-9": "A qué destinatarios se comunican tus datos.",
  "privacy-index-10": "Existencia de transferencias internacionales de tus datos.",
  "privacy-index-11": "Cuáles son tus derechos y cómo puedes ejercerlos.",
  "privacy-index-12": "Cómo protegemos tu información personal.",
  "privacy-index-13": "Modificaciones de esta política.",
  "privacy-controller": "1. ¿Quién es el responsable del tratamiento de tus datos personales?",
  "privacy-controller-1": "Tus datos personales serán tratados por la empresa CRUSH SOCIAL NETWORK, S.L. (",
  "privacy-controller-2": "'CRUSH'",
  "privacy-controller-3": "), con NIF B-72599830 y cuyos datos de contacto son los siguientes:",
  "privacy-controller-4": "Dirección:",
  "privacy-controller-5": "Calle Pintor Velázquez, 3, 1, 30530 Cieza, Murcia (España)",
  "privacy-controller-6": "Email de contacto:",
  "privacy-dpo": "1.1. Nuestro Delegado de Protección de Datos",
  "privacy-dpo-1": "Desde CRUSH, ponemos a tu disposición los datos de contacto de nuestro Delegado de Protección de Datos, a quién puedes dirigir cualquier consulta que tengas en relación con la presente Política de Privacidad o el tratamiento de tus datos personales.",
  "privacy-dpo-2": "E-mail:",
  "privacy-requirements": "2. ¿Qué requisitos debes cumplir para suministrarnos tus datos personales?",
  "privacy-requirements-1": "2.1. Edad mínima. Para suministrarnos tus datos personales y utilizar nuestro Sitio Web y nuestra App deberás ser mayor de 15 años.",
  "privacy-requirements-2": "2.2. Veracidad. Cuando nos proporcionas tus datos para utilizar nuestros servicios, garantizas que los datos e información facilitada es real, veraz, actualizada y además te pertenece a ti y no a terceras personas.",
  "privacy-requirements-3": "Además, deberás notificarnos cualquier modificación que se produzca en los datos facilitados, respondiendo en cualquier caso de la veracidad y exactitud de los datos suministrados en cada momento.",
  "privacy-requirements-4": "2.3. Control de Edad y Veracidad. Desde CRUSH nos reservamos el derecho a verificar tu edad e información identificativa, si es necesario, incluso requiriéndote un documento oficial acreditativo o procedimiento equivalente y, en caso de detección de fraude que se compruebe o se sospeche que eres menor de la edad indicada, a eliminar, desactivar temporalmente y/o cancelar tu cuenta.",
  "privacy-processing": "3. ¿Qué tratamientos de datos realizamos a través del Sitio Web y la App y cuáles son sus principales características?",
  "privacy-processing-1": "A continuación, te explicamos cómo tratamos tu información personal y te proporcionamos, en detalle, toda la información relevante relativa a tu privacidad:",
  "privacy-contact": "3.1. Cuando contactas con nosotros a través de nuestros canales (formulario de contacto, chat, correo electrónico)",
  "privacy-contact-1": "¿Cuáles son las vías de recogida de los datos?",
  "privacy-contact-2": "Formulario de contacto.",
  "privacy-contact-3": "Chat.",
  "privacy-contact-4": "Envío de correos electrónicos a la dirección",
  "privacy-contact-5": "u otras direcciones electrónicas de CRUSH.",
  "privacy-contact-6": "¿Qué datos recogemos?",
  "privacy-contact-7": "Información identificativa y de contacto.",
  "privacy-contact-8": "Recogemos tus datos identificativos (nombre y apellidos) y la dirección de correo electrónico, así como cualquier otra que incluyas de forma voluntaria en las comunicaciones que nos remitas.",
  "privacy-contact-9": "Podremos solicitarte información adicional si es necesario para cumplir con tu solicitud o requerimiento.",
  "privacy-contact-10": "¿Cuáles son las finalidades del tratamiento de tus datos personales?",
  "privacy-contact-11": "Contestar a tus solicitudes.",
  "privacy-contact-12": "La principal finalidad del tratamiento de estos datos será la de contestar tus solicitudes, resolver tus dudas y/o suministrarte la información requerida, así como, en su caso, hacer el seguimiento de tus peticiones.",
  "privacy-contact-13": "Mejorar el servicio de atención al cliente.",
  "privacy-contact-14": "Toda la información derivada de las dudas, consultas, y el asesoramiento ofrecido a los interesados, así como la forma en la que se resuelven las peticiones nos permite conocer cómo prestamos nuestro propio servicio de atención al cliente, permitiéndonos mejorar la calidad del mismo.",
  "privacy-contact-15": "Asimismo, toda la información recabada, finalizado el tiempo de conservación indicado más adelante, se anonimiza y se utiliza a los efectos de poder analizar las preguntas más realizadas a través del chat y poder automatizar las más frecuentes, elaborar FAQs o conservarse a efectos estadísticos para desarrollar estrategias comerciales.",
  "privacy-contact-16": "¿Cuál es la base de legitimación que nos permite tratar tus datos? ¿Es obligatorio el suministro de estos datos?",
  "privacy-contact-17": "Consentimiento.",
  "privacy-contact-18": "Los datos suministrados para las finalidades anteriores se tratarán en base a tu consentimiento, otorgado cuando, de manera voluntaria, te pones en contacto con nosotros a través de los medios puestos a tu disposición para solicitarnos información o realizarnos alguna petición.",
  "privacy-contact-19": "Toda la información recabada por el departamento de atención al cliente la trataremos para fines estadísticos que nos servirá para mejorar la calidad del servicio de atención al cliente prestado. Esta finalidad se considera que es compatible con la inicial.",
  "privacy-contact-20": "La información que deberás suministrar de manera obligatoria estará indicada con un asterisco o de forma similar. Sin dicha información no sería posible atender tus consultas o solicitudes.",
  "privacy-contact-21": "¿Cuánto tiempo conservamos tu información?",
  "privacy-contact-22": "Toda tu información personal la trataremos durante el tiempo en el que se estén tramitando tus solicitudes y, en caso necesario, para hacer el seguimiento de estas. Una vez finalizado este periodo, CRUSH conservará, bloqueada, dicha información durante los plazos previstos en la legislación para atender eventuales responsabilidades y para demostrar el cumplimiento de nuestras obligaciones. A partir de este momento, CRUSH solo tratará la información de manera anonimizada, por lo que no será posible vincular la información estadística con los/as usuarios/as concretos/as a quien hace referencia.",
  "privacy-contact-23": "¿A quién cedemos tu información personal?",
  "privacy-contact-24": "No realizamos ninguna cesión adicional para llevar a cabo este tratamiento que aquellas indicadas, con carácter general, en el punto",
  "privacy-contact-25": "4. ¿A quién cedemos tu información personal?",
  "privacy-contact-26": "En este sentido, algunos canales a través de los cuales puedes ponerte en contacto con nosotros están gestionados por proveedores de servicios, que actúan como Encargados de Tratamiento. Encontrarás más información sobre cómo actúan estos proveedores de servicios en el punto 4, mencionado anteriormente.",
  "privacy-app-use": "3.2. Cuando te registras y utilizas nuestra App 'Crush'",
  "privacy-app-use-1": "¿Cuáles son las vías de recogida de los datos?",
  "privacy-app-use-2": "Formulario de registro en nuestra App y uso de los diferentes espacios de esta.",
  "privacy-app-use-3": "¿Qué datos recogemos?",
  "privacy-app-use-4": "Datos básicos para el registro:",
  "privacy-app-use-5": "Para poder darte de alta en nuestra App y utilizar los servicios será necesario que te registres en nuestra App y crees un",
  "privacy-app-use-6": "'perfil'.",
  "privacy-app-use-7": "Durante el proceso de registro te requerimos a ti, como usuario, los siguientes datos:",
  "privacy-app-use-8": "Nombre de usuario.",
  "privacy-app-use-9": "Nombre.",
  "privacy-app-use-10": "Email.",
  "privacy-app-use-11": "Fecha de nacimiento.",
  "privacy-app-use-12": "Datos adicionales:",
  "privacy-app-use-13": "Además de lo anterior, nuestra App te permite compartir de forma voluntaria información relativa a:",
  "privacy-app-use-14": "Género.",
  "privacy-app-use-15": "Orientación sexual.",
  "privacy-app-use-16": "Intereses.",
  "privacy-app-use-17": "País, ciudad o región.",
  "privacy-app-use-18": "Descripción sobre ti.",
  "privacy-app-use-19": "Profesión y estudios.",
  "privacy-app-use-20": "Gustos musicales.",
  "privacy-app-use-21": "Mejor amigo.",
  "privacy-app-use-22": "Si lo autorizas, tu geolocalización precisa, la cual, si lo has permitido, se lleva a cabo en segundo plano.",
  "privacy-app-use-23": "Datos de uso: se podrá recopilar información sobre tus interacciones con el resto de los usuarios (chats con otros usuarios, usuarios con los que conectas e interactúas, momento, frecuencia de interacción u otras características de tus interacciones).",
  "privacy-app-use-24": "Algunos de los datos que puedes suministrar voluntariamente se consideran sensibles, ya que están relacionados con tu orientación sexual. Debes tener en cuenta que, si decides incluir dicha información, CRUSH la procesará junto con el resto de información para cumplir con las finalidades indicadas más abajo. Asimismo, esta información podrá ser compartida con el resto de los usuarios (se podrá hacer pública), por lo que un uso no autorizado de la App por parte de otros usuarios podría provocar la filtración de estos datos. CRUSH no se hace responsable del uso inadecuado de los datos por parte de otros usuarios.",
  "privacy-app-use-25": "Te informamos, además, que CRUSH utiliza los datos anteriores con la finalidad de elaborar perfiles con la finalidad de recomendarte contenido que pueda ser de tu interés y con las características personales que incluyas sobre ti, como se explica más abajo.",
  "privacy-app-use-26": "Además, si lo autorizas tendremos acceso a:",
  "privacy-app-use-27": "Cámara (imágenes): otorgar permiso a la cámara le permite al usuario cargar cualquier imagen directamente a la App.",
  "privacy-app-use-28": "Galería de fotos (imágenes): otorgar acceso a la galería de fotos permite al usuario cargar cualquier imagen de su galería de fotos.",
  "privacy-app-use-29": "Micrófono (audio): otorgar acceso al micrófono le permite al usuario utilizar la grabadora de voz del dispositivo directamente desde la App.",
  "privacy-app-use-30": "Contenido publicado por ti:",
  "privacy-app-use-31": "Al utilizar nuestra App, tienes la posibilidad de interactuar con otros/as usuarios/as a través de nuestra comunidad, pudiendo subir publicaciones con contenido audiovisual (fotos, audios, etc., si has otorgado los respectivos permisos) y añadir comentarios con tus opiniones, plantear dudas y resolver inquietudes de otros/as usuarios/as.",
  "privacy-app-use-32": "También ofrecemos un emocionante modo 'Stalker', donde tus amigos pueden capturar GIFs de ti con tu consentimiento. Sin embargo, la responsabilidad de capturar y solicitar la publicación de estos GIFs en tu perfil recae en el usuario que inicia la solicitud. El GIF no se publicará en tu perfil hasta que aceptes la solicitud. Además, todos los colaboradores pueden eliminar permanentemente la publicación, ya sea eliminándola directamente o rechazando la solicitud.",
  "privacy-app-use-33": "CRUSH utiliza la tecnología de reconocimiento facial de Amazon Web Services para procesar los datos biométricos de los usuarios de CRUSH como su proveedor de servicios ('Procesador'). Amazon puede procesar y almacenar plantillas de rostros con el fin de proporcionar servicios de verificación y/o identificación de rostros en nombre de CRUSH, y solo según las instrucciones de CRUSH. Amazon almacenará estos datos siempre que CRUSH lo solicite, que no superará un período de gracia limitado después de la fecha en que (i) CRUSH deje de tener una relación con Amazon o (ii) cuando CRUSH solicite la eliminación.",
  "privacy-app-use-34": "En caso de que publiques posts en la App (lo cual es meramente opcional, no te obligamos a ello) se utilizará la tecnología de reconocimiento facial de Amazon, que permite detectar rostros y encontrar usuarios similares a ti (gemelos) dentro de nuestro sistema. Este es el único uso que damos a esta tecnología de reconocimiento.",
  "privacy-app-use-35": "Nuestro procesador, Amazon, se compromete a su propio cumplimiento con el RGPD, así como a proporcionar una variedad de productos, funciones, documentación y recursos para ayudar a sus clientes a cumplir con sus obligaciones de cumplimiento en virtud del RGPD.",
  "privacy-app-use-36": "Datos de uso de la App:",
  "privacy-app-use-37": "También recogemos automáticamente datos de uso de la App, en concreto datos técnicos e información relacionada con el uso que haces de la App, incluyendo, el tipo de dispositivo móvil, la identificación única de tu dispositivo móvil, la dirección IP de tu dispositivo móvil, el sistema operativo móvil, en su caso, el tipo de navegadores de Internet que utilizas e información sobre la forma en que utiliza la App, como la frecuencia y tiempo de uso, anuncios en los que has hecho 'click', etc.",
  "privacy-app-use-38": "Datos sobre ti proporcionados por terceros:",
  "privacy-app-use-39": "Otros usuarios/as de la App pueden proporcionarnos información sobre ti cuando utilizan nuestros servicios; por ejemplo, cuando interactúan contigo o si nos envían una denuncia que te involucra.",
  "privacy-app-use-40": "¿Cuáles son las finalidades del tratamiento de tus datos personales?",
  "privacy-app-use-41": "Darte de alta y administrar tu cuenta",
  "privacy-app-use-42": "de forma que podamos verificar tu correo electrónico y edad para darte de alta en la App, interactuar contigo y darte acceso a nuestros servicios.",
  "privacy-app-use-43": "Gestionar y ejecutar la relación contractual que nos une:",
  "privacy-app-use-44": "realizamos distintas gestiones necesarias para ejecutar el contrato suscrito contigo, de forma que, por ejemplo, podamos prestarte el servicio, contactar contigo para ayudarte a resolver dudas, para verificar la información que nos has proporcionado. Asimismo, podremos enviarte comunicaciones de carácter administrativo, sobre actualizaciones, cambios en nuestras condiciones de servicio, alertas de seguridad, así como dar respuesta a cualquier solicitud o requerimiento que nos envíes.",
  "privacy-app-use-45": "Publicar tu contenido.",
  "privacy-app-use-46": "Cuando compartes contenido o información a través de la App, el mismo aparecerá publicado para el público que hayas seleccionado que puede ver lo que compartes. Para añadir cierto contenido, como fotos y vídeos, deberás darnos acceso a tu cámara o a tu galería de fotos.",
  "privacy-app-use-47": "Cualquier usuario de nuestra App puede visualizar tu nombre de usuario y tu perfil público en la App, que incluye contenido que has compartido con el público en general. Asimismo, ten en cuenta que tu contenido publicado en la App podrá ser compartido por el resto de los usuarios dentro o fuera de la App, tomar una captura de pantalla de este o descargarlo.",
  "privacy-app-use-48": "Publicar contenido sobre ti.",
  "privacy-app-use-49": "Otros usuarios de la App pueden crear y compartir contenido en el que aparezca tu imagen o datos con el público que elijan. Si no te sientes cómodo con el contenido que otras personas han compartido sobre ti, por favor, denuncialo dando uso de los formularios de la App o contacta con nosotros por correo.",
  "privacy-app-use-50": "Ofrecerte contenido (noticias, artículos, experiencias entre otros)",
  "privacy-app-use-51": "creado directamente por CRUSH o en colaboración con personas externas que pueden ser de tu interés.",
  "privacy-app-use-52": "Envío de notificaciones push y alertas dentro de la App.",
  "privacy-app-use-53": "Si lo has autorizado, recibirás nuestras notificaciones push en tu navegador web o en tu dispositivo móvil. Las notificaciones push pueden incluir recordatorios, comunicaciones promocionales, alertas y actualizaciones relativas a la App y los servicios.",
  "privacy-app-use-54": "Si activas estas notificaciones, recibirás avisos personalizados, en base a un perfil que elaboramos sobre ti y el uso que haces de la App. Para la personalización de avisos, se puede combinar la información obtenida mediante los datos introducidos por ti en la App y los datos captados relativos al uso de la App (contenido visitado) para crear un perfil en relación con tus intereses y ofrecerte principalmente aquel contenido e información que encajan contigo.",
  "privacy-app-use-55": "Puedes desactivar las notificaciones push en cualquier momento ajustando la configuración de tu dispositivo móvil, aunque dicha desactivación no afectará al resto de comunicaciones que puedas recibir de CRUSH, tales como las comunicaciones enviadas por correo electrónico.",
  "privacy-app-use-56": "Prevención, detección y enjuiciamiento de actividades ilícitas o contrarias a las condiciones del servicio o que pongan en peligro la seguridad de la información o la App:",
  "privacy-app-use-57": "CRUSH podrá procesar los datos para controlar y evitar cualquier forma de abuso de nuestros servicios, como por ejemplo actividades fraudulentas, ataques de denegación de servicios, envío de spam, accesos no autorizados a las cuentas de nuestros usuarios, así como cualquier otra práctica que sea contraria a las Condiciones Generales y de Uso de la App o ponga en peligro la seguridad de la información o la propia integridad de la App, pudiendo eliminar el contenido o la cuenta que sean contrarios a la ley, a nuestras Condiciones Generales o a las Normas de la Comunidad.",
  "privacy-app-use-58": "Dentro de esta finalidad, se incluye también el procesamiento de denuncias que presentes contra otros usuarios o las que se presenten contra ti, existiendo la opción de contactar con CRUSH para analizar el caso concreto y garantizando que existirá intervención humana para corregir o subsanar la cuestión que se nos plantee.",
  "privacy-app-use-59": "También podemos usar herramientas para detectar contenido inapropiado (por ejemplo, contenido para adultos o violento) en el contenido visual que publiques.",
  "privacy-app-use-60": "Para ofrecerte un mejor servicio también se tratarán tus datos con la finalidad de:",
  "privacy-app-use-61": "Ayudarte a conectar con otros usuarios, permitiendo tu interacción con ellos a través de pantallas de chat y otros mecanismos.",
  "privacy-app-use-62": "Recomendarte a otros usuarios que sean afines a ti.",
  "privacy-app-use-63": "Para realizar las recomendaciones, utilizamos la información que nos proporcionas, incluyendo información sobre tu ubicación si das tu permiso, así como la información que obtenemos del uso de este espacio (por ejemplo, características de las personas con las que realmente interactúas) para elaborar un perfil automatizado sobre ti y mostrarte preferentemente aquellos usuarios que encajen con tu perfil y con lo que estás buscando.",
  "privacy-app-use-64": "En este sentido, se te mostrarán preferentemente usuarios activos en la App, que estén cerca de ti, que encajen con las preferencias indicadas y tu perfil elaborado a partir de tus interacciones, asegurándonos, además, que tus características y perfil encajan con las preferencias de los usuarios recomendados.",
  "privacy-app-use-65": "Finalidades estadísticas y otras realizadas con datos anónimos.",
  "privacy-app-use-66": "Podemos anonimizar toda la información personal recopilada de los usuarios que utilizan la App para finalidades estadísticas y otras, como, por ejemplo, entrenamiento de algoritmos, para medir la eficacia de las campañas publicitarias disponibles en la App, la navegabilidad y usabilidad de la App para poder mejorar el diseño, presentación y funcionalidades disponibles u otros.",
  "privacy-app-use-67": "Para mostrarte publicidad:",
  "privacy-app-use-68": "Nuestra App puede incluir publicidad que, en caso de que prestes tu consentimiento, será personalizada de acuerdo con tus gustos y preferencias.",
  "privacy-app-use-69": "¿Cuál es la base de legitimación que nos permite tratar tus datos? ¿Es obligatorio el suministro de estos datos?",
  "privacy-app-use-70": "Ejecutar y mantener la relación contractual entre las Partes.",
  "privacy-app-use-71": "La razón por la que procesamos tu información para las finalidades citadas anteriormente es para cumplir con el contrato que tenemos contigo.",
  "privacy-app-use-72": "Consentimiento.",
  "privacy-app-use-73": "Algunas funcionalidades se basan en tu consentimiento expreso: (i) publicación de contenido propio, (ii) acceso a la cámara o galería de fotos para publicar determinado contenido en la App, (iii) el acceso a la geolocalización del dispositivo para recomendación de usuarios por proximidad (iv) la recepción de notificaciones push personalizadas (v) visualización de contenido publicitario personalizado. Asimismo, si decides proporcionarnos la información voluntaria adicional para completar tu perfil, dicha información será utilizada en base a tu consentimiento.",
  "privacy-app-use-74": "Recuerda que puedes revocar tu consentimiento en cualquier momento a través de la configuración de tu dispositivo, eliminando tu contenido o a través de las opciones disponibles en la App.",
  "privacy-app-use-75": "Interés legítimo.",
  "privacy-app-use-76": "Se tratarán en base a nuestro interés legítimo los datos en relación con la prevención de actividades ilícitas y otras relacionadas con la seguridad de la App, para garantizar la seguridad de la red y de la información para prevenir sucesos que puedan comprometer la disponibilidad, autenticidad, integridad y confidencialidad de los datos personales o la propia plataforma, así como el cumplimiento de las normas de la comunidad.",
  "privacy-app-use-77": "También podremos mostrarte publicidad no personalizada a través de la App en base a nuestro interés legítimo.",
  "privacy-app-use-78": "Si deseas obtener más información sobre la ponderación que hemos realizado sobre los intereses legítimos de CRUSH en relación con las anteriores finalidades, puedes solicitarla escribiéndonos a",
  "privacy-app-use-79": "Tratamiento de datos especialmente protegidos:",
  "privacy-app-use-80": "Los datos relacionados con tus intereses o aspectos relacionados con la orientación sexual se tratarán en base a tu consentimiento explícito.",
  "privacy-app-use-81": "¿Cuánto tiempo conservamos tu información?",
  "privacy-app-use-82": "Toda tu información personal la guardaremos durante el tiempo en el que se mantenga vigente nuestra relación contractual, es decir hasta que elimines tu cuenta. Una vez finalizada la relación contractual o cancelada tu cuenta, CRUSH conservará, bloqueada, toda la información durante los plazos previstos en la legislación para investigar conductas ilícitas o perjudiciales, atender eventuales responsabilidades y para demostrar el cumplimiento de nuestras obligaciones.",
  "privacy-app-use-83": "En relación con el contenido publicado por ti a través de nuestra App, debes tener en cuenta que cualquier publicación o comentario que hayas publicado en nuestra comunidad se mantendrá en la App mientras no lo elimines o hasta que elimines tu cuenta. No obstante, cualquier otro contenido donde aparezcas y que haya sido publicado por otros usuarios (por ejemplo, fotos o videos) podría permanecer visible mientras dicho usuario no lo elimine o cierre su cuenta.",
  "privacy-app-use-84": "No obstante, nos reservamos el derecho a usar tu información de forma agregada después de cerrar tu cuenta con finalidades estadísticas y de mejora de nuestros servicios. El uso de dicha información no permitirá que se te pueda identificar personalmente.",
  "privacy-app-use-85": "¿A quién cedemos tu información personal?",
  "privacy-app-use-86": "No existen cesiones concretas de tus datos personales para cumplir con la finalidad indicada en este apartado. No obstante, podemos recurrir a los servicios de proveedores de servicios, quienes tendrán un acceso limitado a los datos y estarán ligados a un deber de confidencialidad (para más información sobre cómo actúan nuestros proveedores de servicios, ver el punto",
  "privacy-app-use-87": "4. ¿A quién cedemos tu información personal? > Proveedores de Servicios",
  "privacy-app-use-88": "No obstante, ten en cuenta que cuando publicas contenido en nuestra App, puedes estar compartiendo información personal con otros usuarios (incluido tu perfil público).",
  "privacy-app-use-89": "Asimismo, si alguien presenta una denuncia que te implica, podremos comunicar al denunciante las medidas que hemos tomado a raíz de su denuncia, en caso de que las haya.",
  "privacy-cookies": "3.3. Navegación por el Sitio Web (cookies)",
  "privacy-cookies-1": "En nuestro Sitio Web, así como en nuestra App utilizamos cookies u otras herramientas de seguimiento y rastreo para recabar información acerca del uso que los usuarios realizan del Sitio Web y de la App.",
  "privacy-cookies-2": "Para más información sobre el tratamiento que realizamos a través de dichas herramientas de seguimiento, visita nuestra",
  "privacy-cookies-3": "Política de Cookies",
  "privacy-social-profiles": "3.4. Perfiles de CRUSH en redes sociales",
  "privacy-social-profiles-1": "CRUSH cuenta con perfil en las principales redes sociales, tales como Twitter, Instagram, LinkedIn o TikTok.",
  "privacy-social-profiles-2": "Cuando te haces seguidor de alguna de nuestras páginas en las redes sociales, el tratamiento de los datos se regirá por las condiciones de uso, políticas de privacidad y normativas de acceso que pertenezcan a la red social correspondiente y aceptadas previamente por el usuario.",
  "privacy-social-profiles-3": "CRUSH, en este sentido, tratará tus datos con las finalidades de administrar correctamente su presencia en la red social, informándote de actividades, productos o servicios, así como para cualquier otra finalidad que las normativas de las redes sociales permitan.",
  "privacy-social-profiles-4": "Ten en cuenta que nosotros no tenemos influencia sobre la información que la red social recoge o cómo la procesa, por lo que te recomendamos que te mantengas informado del propósito y alcance de la recogida de información que se realiza a través de dichas redes sociales.",
  "privacy-transfer": "4. ¿A quién cedemos tu información personal?",
  "privacy-transfer-1": "Por lo general, desde CRUSH no comunicaremos tus datos a terceros. No obstante, además de las cesiones que te indicamos específicamente en el apartado en que te explicamos las características de las diferentes operaciones (punto 3), te informamos de las comunicaciones que, con carácter general, podemos realizar, así como su base legitimadora, que afectan a todos los tratamientos anteriores.",
  "privacy-transfer-2": "Proveedores de servicios esenciales",
  "privacy-transfer-3": "para ejecutar el servicio que te ofrecemos (por ejemplo, empresas de alojamiento informático o plataformas de envío de las comunicaciones comerciales). No obstante lo anterior, estas entidades han firmado los correspondientes acuerdos de confidencialidad y solo tratarán tus datos según nuestras instrucciones, no pudiendo utilizarlos para finalidades propias o aparte del servicio que nos prestan.",
  "privacy-transfer-4": "Organismos Públicos.",
  "privacy-transfer-5": "Podremos revelar a las autoridades públicas competentes los datos y cualquier otra información que esté en nuestro poder o que sea accesible a través de nuestros sistemas cuando exista una obligación legal para ello, así como cuando sea requerida, por ejemplo, cuando la finalidad sea la de prevenir o perseguir abusos de los servicios o actividades fraudulentas a través de nuestro Sitio Web o Aplicación. En estos casos, los datos de carácter personal que nos facilites se conservarían y pondrían a disposición de las autoridades administrativas o judiciales.",
  "privacy-transfer-6": "En caso de una operación societaria:",
  "privacy-transfer-7": "En caso de una fusión, una adquisición, venta de la totalidad o parte de sus activos o cualquier otro tipo de operación societaria en la que intervenga un tercero, podremos compartir, divulgar o transferir los datos de los usuarios a la entidad sucesora (incluso durante la fase previa a la operación).",
  "privacy-transfer-8": "A terceros después de una agregación o anonimización:",
  "privacy-transfer-9": "podremos divulgar o utilizar datos agregados o anonimizados (es decir, los cuales no sean vinculables a una persona física identificada o identificable) para cualquier finalidad.",
  "privacy-transfer-10": "A terceros con el consentimiento del usuario u otra base legítima:",
  "privacy-transfer-11": "En el caso de que queramos compartir datos con terceros fuera del ámbito de esta Política de privacidad se solicitará, en todo caso, su consentimiento o se informará sobre la misma y su base legítima.",
  "privacy-transfer-12": "Asimismo, te informamos de que esta Política de Privacidad sólo se refiere a la recogida, tratamiento y uso de información (relativa a datos personales) por nuestra parte a través de la interacción que realizas con nuestro Sitio Web o Aplicación. El acceso a páginas Web de terceros a las que puedas acceder a través de enlaces desde el Sitio Web o la App tienen sus propias políticas de privacidad sobre las que no tenemos ningún control. Por ello, antes de facilitarles alguna información personal, te recomendamos que te informes sobre sus Políticas de Privacidad.",
  "privacy-eea": "5. ¿Se transfieren tus datos personales a terceros países fuera del Espacio Económico Europeo?",
  "privacy-eea-1": "Algunos de nuestros prestadores de servicios están ubicados en países fuera del Espacio Económico Europeo (",
  "privacy-eea-2": "'EEE'",
  "privacy-eea-3": "La ubicación de estas compañías fuera del EEE implican la existencia de una transferencia internacional de tus datos personales, lo que podría suponer un menor grado de protección que el recogido en la normativa europea. No obstante, desde CRUSH hemos aplicado medidas para que dichas transferencias no den lugar a un menor grado de protección de tus datos personales.",
  "privacy-eea-4": "En este sentido, los proveedores de servicios que se encuentran fuera del EEE han suscrito las correspondientes cláusulas contractuales tipo aprobadas por la Comisión Europea (",
  "privacy-eea-5": "'CCT'",
  "privacy-eea-6": "), acuerdo firmado entre ambas entidades por el cual la empresa extracomunitaria garantiza que aplica los estándares europeos de protección de datos.",
  "privacy-eea-7": "Por ello, la utilización de estos proveedores no da lugar a un menor grado de protección de tus datos personales que el que tendría la utilización de proveedores ubicados en la Unión Europea. Puedes consultar el contenido de las CCT en el siguiente enlace:",
  "privacy-eea-8": "A continuación, te indicamos cuales son las transferencias internacionales que realizamos desde CRUSH:",
  "privacy-eea-9": "Nombre",
  "privacy-eea-10": "Actividades afectadas",
  "privacy-eea-11": "País",
  "privacy-eea-12": "Mecanismo de transferencia",
  "privacy-eea-13": "Todas",
  "privacy-eea-14": "Detección de rostros y búsqueda de similares",
  "privacy-eea-15": "Notificaciones push",
  "privacy-eea-16": "Envío de correos electrónicos",
  "privacy-eea-usa": "EE.UU",
  "privacy-eea-sccs": "Cláusulas Contractuales Tipo (CCS)",
  "privacy-rights": "6. ¿Cuáles son los derechos que puedes ejercer como interesad@?",
  "privacy-rights-1": "Puedes ejercer los derechos que la ley te garantiza en relación con el tratamiento de tus datos personales contactando con nuestro Delegado de Protección de Datos a través del correo electrónico",
  "privacy-rights-2": "Cualquier petición de derechos que recibamos la resolveremos lo antes posible y, en cualquier caso, dentro del plazo máximo establecido por la legislación, a partir de la fecha de recepción. En algunos casos, será necesario solicitarte una copia de tu documento de identidad u otro documento identificativo para verificar tu identidad.",
  "privacy-rights-3": "Los derechos que te corresponden como interesad@, son los siguientes:",
  "privacy-rights-4": "Derecho a retirar el consentimiento otorgado",
  "privacy-rights-5": "Puedes revocar tu consentimiento en relación con todos los procesamientos basados en él en cualquier momento. No obstante, la retirada del consentimiento no afectará a la licitud del tratamiento basada en el consentimiento previo a su retirada.",
  "privacy-rights-6": "Derecho de acceso",
  "privacy-rights-7": "Tienes derecho a saber qué datos están siendo objeto de tratamiento, si procede y, si es así, obtener una copia de estos, así como a obtener información relativa a:",
  "privacy-rights-8": "el origen y los destinatarios de los datos;",
  "privacy-rights-9": "las finalidades para las que se tratan;",
  "privacy-rights-10": "si existe un proceso de toma de decisiones automatizado, incluida la elaboración de perfiles;",
  "privacy-rights-11": "el período de retención de los datos; y",
  "privacy-rights-12": "los derechos previstos por la normativa.",
  "privacy-rights-13": "Derecho de rectificación",
  "privacy-rights-14": "Tienes derecho a obtener la rectificación de tus datos personales o a completarlos cuando están incompletos.",
  "privacy-rights-15": "Derecho de supresión",
  "privacy-rights-16": "Tienes derecho a solicitar la supresión de tus datos personales si ya no son necesarios para el propósito para el que fueron recopilados o, en su caso, si ya no estamos autorizados para tratarlos.",
  "privacy-rights-17": "Derecho a la portabilidad de datos",
  "privacy-rights-18": "Tienes derecho a solicitar la portabilidad de tus datos en el caso de los tratamientos que estén basados en tu consentimiento o en la ejecución de un contrato, siempre y cuando el procesamiento se haya realizado por medios automatizados. En caso de ejercicio de este derecho, recibirás tus datos personales en un formato estructurado, de uso común y legible por cualquier dispositivo electrónico. No obstante, también puedes solicitar, cuando sea posible, que tus datos sean transmitidos directamente a otra empresa.",
  "privacy-rights-19": "Derecho a la limitación del tratamiento de tus datos personales",
  "privacy-rights-20": "Tienes derecho a la limitación del procesamiento de tus datos en los siguientes casos:",
  "privacy-rights-21": "Cuando hayas solicitado la rectificación de tus datos personales, durante el período en el que verificamos la exactitud de los mismos.",
  "privacy-rights-22": "Cuando consideres que no estamos autorizados para procesar tus datos. En ese caso, puedes solicitarnos que limitemos su uso en vez de solicitar su supresión.",
  "privacy-rights-23": "Cuando consideres que ya no es necesario que sigamos tratando tus datos y quieras que los conservemos a los efectos del ejercicio o la defensa de reclamaciones.",
  "privacy-rights-24": "En los casos en los que exista un procesamiento basado en nuestro interés legítimo y hayas ejercitado tu derecho de oposición al mismo, puedes pedirnos que limitemos el uso de tus datos durante la verificación de la prevalencia de dichos intereses con respecto a los tuyos.",
  "privacy-rights-25": "Derecho de oposición",
  "privacy-rights-26": "Tienes derecho a oponerte en cualquier momento al procesamiento de tus datos personales en función de nuestro interés legítimo, incluida la elaboración de perfiles.",
  "privacy-rights-27": "Derecho a presentar una reclamación ante la Autoridad de Control",
  "privacy-rights-28": "Recuerda que, en cualquier momento, y en caso de que consideres que hemos vulnerado tu derecho a la protección de tus datos, podrás dirigirte en su defensa a la Autoridad de Control correspondiente, en caso de España, la Agencia Española de Protección de Datos (",
  "privacy-confidence": "7. ¿Cómo garantizamos la confidencialidad de tu información?",
  "privacy-confidence-1": "La seguridad de tus datos personales es una prioridad para nosotros. Por ello, CRUSH ha implantado todas las medidas de seguridad necesarias para garantizar un uso y tratamiento de los datos de carácter personal facilitados por el/la usuario eficaz, salvaguardando la intimidad, privacidad, confidencialidad e integridad de estos, y hace uso de los medios técnicos necesarios para evitar la alteración, pérdida, acceso no autorizado o tratamiento de sus datos, según el estado de la tecnología en cada momento.",
  "privacy-confidence-2": "Consecuentemente, cumplimos con los estándares de seguridad recomendados para protegerlos. Sin embargo, es imposible garantizar de un modo total su seguridad por la propia naturaleza de Internet y porque pueden existir actuaciones dolosas de terceros fuera de nuestro control.",
  "privacy-confidence-3": "Nos comprometemos a actuar con rapidez y diligencia en caso de que la seguridad de los datos se vea en peligro o comprometida, y a informarte sobre ello en caso de que sea relevante.",
  "privacy-modifications": "8. Modificaciones de la presente política",
  "privacy-modifications-1": "Desde CRUSH podemos modificar el contenido de esta Política de Privacidad en cualquier momento, especialmente cuando se produzcan modificaciones legislativas, jurisprudenciales o de interpretación de la Agencia Española de Protección de Datos que afecten a los tratamientos de datos realizados por CRUSH a través del Sitio Web o la App. Cualquier nueva versión de esta Política de Privacidad entrará en vigor en la fecha de entrada en vigor publicada.",
  "privacy-modifications-2": "Si la versión revisada incluye un cambio sustancial que afecte al tratamiento de tus datos, te lo notificaremos con, al menos, 30 días de antelación publicando un aviso en nuestro Sitio Web o nuestra App o comunicándotelo a través del correo electrónico. De todas maneras, te recomendamos que revises periódicamente esta Política de Privacidad para estar informado de cómo se tratan y protegen tus datos personales, así como de los derechos que te asisten.",
  "cookies-title": "Crush - Cookies",
  "cookies-date": "Última modificación: 8 diciembre, 2023",
  "cookies-header": "Política de cookies",
  "cookies-introduction-1": "Tanto en el sitio web www.crushsocialnetwork.com (en adelante, el",
  "cookies-introduction-2": "'Sitio Web'",
  "cookies-introduction-3": ") como a través de la aplicación móvil para Smartphone con el nombre 'Crush' (en adelante, la",
  "cookies-introduction-4": "'App'",
  "cookies-introduction-5": ") utilizamos cookies u otras herramientas de seguimiento y rastreo para recabar información acerca del uso que los/as usuarios/as realizan del Sitio Web y la App.",
  "cookies-introduction-6": "En este documento descubrirás qué cookies le damos al Monstruo de las Galletas, así como cualquier otro tipo de información relacionada con este maravilloso bocado.",
  "cookies-definition": "1. ¿Qué es una cookie?",
  "cookies-definition-1": "Una cookie es un archivo que se instala en el navegador de tu dispositivo (ordenador, móvil, etc.) con la finalidad de almacenar y recuperar datos. Las cookies pueden tener multitud de finalidades, según para que se hayan configurado: por ejemplo, guardar preferencias, recoger estadísticas de uso, permitir funcionalidades técnicas, entre otras.",
  "cookies-responsible": "2. ¿Quién es el responsable?",
  "cookies-responsible-1": "El responsable del tratamiento de la información recopilada mediante cookies es CRUSH SOCIAL NETWORK, S.L. (CRUSH), con NIF B-72599830 y dirección en Calle Pintor Velázquez, 3, 1, 30530 Cieza, Murcia (España).",
  "cookies-responsible-2": "Si tuvieras cualquier duda relacionada con el uso de cookies, por favor, escribe un email a",
  "cookies-responsible-3": "y te responderemos tan pronto como sea posible.",
  "cookies-data": "3. ¿Qué datos se recogen a través de las cookies?",
  "cookies-data-1": "Cuando accedes al Sitio Web o instalas y usas la App, aunque no te hayas registrado, se recopila la siguiente información mediante las cookies:",
  "cookies-data-2": "Información del dispositivo: Durante la utilización del Sitio Web o de la App, recopilamos información sobre el dispositivo móvil desde el que accedes a la plataforma. La información que obtenemos es el modelo del dispositivo, el sistema operativo y la versión, el identificador único del dispositivo y la red.",
  "cookies-data-3": "Información sobre tu ubicación: También obtenemos la dirección IP, zona horaria y cuál es el proveedor de servicio móvil, en su caso, lo que nos permite obtener una ubicación general.",
  "cookies-data-4": "Información de la navegación del usuario: Recopilamos información sobre el uso que haces del Sitio Web y de la App. En concreto, la frecuencia de uso, los apartados que visitas, uso de funciones específicas, etc.",
  "cookies-types": "4. ¿Qué tipo de cookies existen?",
  "cookies-types-1": "Según su finalidad,",
  "cookies-types-2": "podemos clasificar las cookies entre las siguientes:",
  "cookies-types-3": "Cookies técnicas:",
  "cookies-types-4": "Estas cookies son las estrictamente necesarias para navegar por el Sitio Web o la App y permiten funciones básicas como la navegación de una sección a otra. Se encargan de aspectos técnicos como identificar perfiles o sesiones y gestionar preferencias de herramientas en el uso, interacción y localización del acceso a los contenidos. También pertenecen a esta categoría, por su naturaleza técnica, aquellas cookies que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios incluidos en el Sitio Web y la App, sin que se recopile información de los usuarios con fines distintos, como puede ser personalizar anuncios u otros tipos de contenido.",
  "cookies-types-5": "Cookies estadísticas:",
  "cookies-types-6": "Estas cookies miden y recogen información estadística relativa al uso que realiza el usuario del Sitio Web o la App, como el tiempo de permanencia en la plataforma, el número de visitas o los contenidos visitados. Dicha información permite comprender cómo interactúan los usuarios con el Sitio Web y la App, evaluar el rendimiento, qué secciones son las más o menos visitadas, incluida la cuantificación de los impactos de los anuncios introducidos en el Sitio Web y la App (en su caso). Teniendo esta información se pueden solucionar errores, y mejorar el diseño del sistema y el contenido que se ofrece a través del mismo.",
  "cookies-types-7": "Cookies de preferencias o de personalización:",
  "cookies-types-8": "Las cookies de preferencias permiten que el Sitio Web o la App recuerden información del usuario para que este acceda al servicio con determinadas características que pueden diferenciar su experiencia de la de otros usuarios. Por ejemplo, su idioma preferido o la región desde la que accede, el número de resultados a mostrar cuando el usuario realiza una búsqueda, el aspecto o contenido del servicio en función del tipo de dispositivo desde el cual el usuario accede a él, etc.",
  "cookies-types-9": "Cookies de publicidad comportamental:",
  "cookies-types-10": "Estas cookies permiten la gestión de los espacios publicitarios que, en su caso, haya incluidos en el Sitio Web o la App. No obstante, la gestión de los espacios publicitarios se hace en base al perfil de navegación del usuario. Estas cookies almacenan información de la navegación de los usuarios obtenida a través de la observación continuada de sus hábitos de navegación, desarrollando un perfil específico del usuario, por lo que los anuncios que aparecen en estos espacios serán personalizados para el usuario según dichos hábitos.",
  "cookies-types-11": "En función de la entidad que las gestione:",
  "cookies-types-12": "Cookies propias:",
  "cookies-types-13": "son aquellas que se envían al dispositivo del usuario desde un equipo o dominio gestionado por el responsable del Sitio Web o la App, en este caso, CRUSH, y desde el que se presta el servicio solicitado por el/la usuario/a.",
  "cookies-types-14": "Cookies de terceros:",
  "cookies-types-15": "son aquellas que se envían al dispositivo del usuario desde un equipo o dominio que no es gestionado por el responsable del Sitio Web o la App, sino por otra entidad que trata los datos obtenidos a través de las cookies.",
  "cookies-types-16": "En función del tiempo que permanecen activas en el dispositivo:",
  "cookies-types-17": "Cookies de sesión:",
  "cookies-types-18": "Están diseñadas para recabar y almacenar datos durante el tiempo en el que el/la usuario/a está navegando por el Sitio Web o la App, y se desactivan al finalizar la navegación.",
  "cookies-types-19": "Cookies persistentes:",
  "cookies-types-20": "Estas almacenan los datos que pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie. Sus tiempos de vida pueden comprender minutos o años.",
  "cookies-used": "5. ¿Qué cookies utilizamos concretamente en nuestra plataforma?",
  "cookies-used-1": "En nuestra plataforma, utilizamos las siguientes cookies:",
  "cookies-used-2": "Nombre",
  "cookies-used-3": "Finalidad",
  "cookies-used-4": "Cookies Propias/Terceros",
  "cookies-used-5": "Caducidad",
  "cookies-used-6": "14 meses",
  "cookies-used-7": "Indefinida",
  "cookies-used-stats": "Estadística",
  "cookies-used-technical": "Técnica",
  "cookies-used-third-party-google": "Terceros (Google LLC.)",
  "cookies-legitimation": "6. ¿Cuál es la base de legitimación que nos permite tratar sus datos a través de las cookies? En su caso, ¿son obligatorias?",
  "cookies-legitimation-1": "La información que recogemos a través de las cookies y demás dispositivos de rastreo se basa en tu consentimiento, el cual te solicitamos cuando accedes al Sitio Web o cuando instalas la App a través de un pop-up informativo o de la forma que establece Google (cuando se trata de Android) o Apple (cuando se trata de iOS).",
  "cookies-legitimation-2": "En este sentido, en cualquier momento puedes retirar tu consentimiento como te explicamos en el apartado relativo a la revocación del consentimiento.",
  "cookies-legitimation-3": "Las cookies técnicas no pueden desactivarse, ya que, de lo contrario, se vería comprometido el funcionamiento correcto del Sitio Web o la App. Por lo general, solo se configuran en respuesta a acciones realizadas al solicitarnos servicios, como establecer tus preferencias en relación con las propias cookies, iniciar sesión o completar formularios. En el caso de que se produjera la desactivación, esta podría provocar que el uso de algunos de los servicios del Sitio Web o de la App sea limitado por no poder acceder a determinadas funcionalidades y por tanto tu experiencia sería menos satisfactoria.",
  "cookies-transfer": "7. ¿A quién cedemos tu información personal? ¿Existen transferencias internacionales de datos?",
  "cookies-transfer-1": "Utilizamos algunas cookies de terceros, que son aquellas cookies recabadas y gestionadas por un tercero, por lo que dichas entidades recibirán tus datos personales recabados a través de las cookies para las finalidades que se indiquen para cada cookie. Este tipo de cookies están indicadas en la tabla del apartado 5, como 'Cookies de Terceros'. En dicha tabla se identifica el tercero que gestiona cada cookie.",
  "cookies-transfer-2": "Puedes informarte de las transferencias a terceros países que, en su caso, realizan los terceros identificados en esta Política de Cookies en sus correspondientes políticas.",
  "cookies-consent": "8. ¿Cuál es la forma de aceptar, denegar o revocar el consentimiento para el uso de cookies en este sitio web?",
  "cookies-consent-1": "Cuando accedes por primera vez a nuestro Sitio Web o a nuestra App, se te muestra una ventana en la que puedes indicar si aceptas las cookies o si deseas configurarlas o rechazarlas, salvo aquellas estrictamente necesarias. Asimismo, puedes retirar el consentimiento otorgado previamente en cualquier momento.",
  "cookies-consent-2": "Si quisieses retirar en cualquier momento tu consentimiento relacionado con la Política de Cookies, tienes dos opciones: (i) retirar tu consentimiento a través de los botones habilitados al efecto en esta Política de cookies; o (ii) eliminar las cookies almacenadas en el dispositivo a través de los ajustes y configuraciones de tu navegador de Internet.",
  "cookies-consent-3": "Ten en cuenta que, si aceptas las cookies de terceros, deberás eliminarlas desde las opciones del navegador o desde el sistema ofrecido por el propio tercero.",
  "cookies-consent-4": "Puedes ampliar la información sobre cómo deshabilitar las cookies, dependiendo de tu navegador, en los siguientes enlaces:",
  "cookies-consent-5": "; para iOS",
  "cookies-rights": "9. ¿Cuáles son los derechos que puedes ejercer como interesado?",
  "cookies-rights-1": "Los derechos que dispones como interesado/a en relación con la protección de sus datos personales están indicados en el apartado '¿Cuáles son los derechos que puedes ejercer como interesado?' dentro de la",
  "cookies-rights-2": "Política de Privacidad",
  "cookies-modifications": "10. Modificaciones en esta política",
  "cookies-modifications-1": "Es posible que actualicemos la Política de Cookies de nuestra plataforma periódicamente. Por ello, te recomendamos revisar esta política cada vez que accedas a nuestro Sitio Web o App con el objetivo de estar adecuadamente informado sobre cómo y para qué utilizamos las cookies."
}
