/**
 * @file i18n.
 * @author Victorio Molina <https://github.com/VictorioMolina>
 * @copyright Crush Social Network 2022.
 * @license MIT
 * @module i18n
 */

export { default as DOMTranslator } from "./DOMTranslator";
export * from "./i18n";
