/* eslint-disable no-param-reassign */
import { t } from "./i18n";
import {
  SUPPORTED_LANGUAGES,
  DEFAULT_LANGUAGE,
  getNavigatorLanguage,
} from "../../utils/languages";

/**
 * DOM translator.
 *
 * @memberof module:i18n
 * @class
 */
export default class DOMTranslator {
  /**
   * Creates a DOM translator.
   *
   * @param {string} attribute
   *   HTML attribute which serves as a selector of the DOM elements
   *   to translate.
   * @param {string} [language]
   *   The locale of the language to which the DOM will be translated.
   * @param {string} [fallbackLanguage] - Fallback language.
   * @param {string} [fallbackText] - Fallback text.
   * @requires module:i18n/Functions.getNavigatorLanguage
   * @requires module:i18n/Constants.DEFAULT_LANGUAGE
   */
  constructor(
    attribute,
    language = getNavigatorLanguage() ?? DEFAULT_LANGUAGE,
    fallbackLanguage = DEFAULT_LANGUAGE,
    fallbackText = ""
  ) {
    /**
     * Common HTML attribute of the translated nodes.
     *
     * @private
     * @type {string}
     */
    this.attribute = attribute;

    /**
     * Language of the translation.
     *
     * @private
     * @type {string}
     */
    this.language = language;

    /**
     * Fallback language of the translation.
     *
     * @private
     * @type {string}
     */
    this.fallbackLanguage = fallbackLanguage;

    /**
     * Fallback text (used if the translation scope is not found).
     *
     * @private
     * @type {string}
     */
    this.fallbackText = fallbackText;
  }

  /**
   * Sets the HTML document's language based on the defined properties.
   *
   * @private
   * @function _setHTMLLanguage
   * @returns {void} Nothing.
   * @requires module:i18n/Constants.SUPPORTED_LANGUAGES
   */
  _setHTMLLanguage() {
    const language =
      SUPPORTED_LANGUAGES.find((language) => language === this.language) ??
      this.fallbackLanguage;

    document.documentElement.setAttribute("lang", language);
  }

  /**
   * Parses and translates the DOM, and defines the document's language, based
   * on the defined properties.
   *
   * @function process
   * @returns {void} Nothing.
   * @requires module:i18n.t
   */
  process() {
    const self = this;

    // Set the document's language
    self._setHTMLLanguage();

    document.querySelectorAll(`[${self.attribute}]`).forEach((element) => {
      const scope = element.getAttribute(self.attribute);
      const translatedText = t(
        scope,
        self.language,
        self.fallbackLanguage,
        self.fallbackText
      );

      element.textContent = translatedText;
    });
  }
}
