{
  "404-title": "Crush - Non trovato",
  "404-not-found-1": "Non trovato",
  "404-not-found-2": "Spiacenti, la pagina che stai cercando non è disponibile.",
  "navbar-mission": "Missione",
  "navbar-download": "Scarica",
  "footer-follow": "Seguici",
  "footer-follow-1": "Instagram",
  "footer-follow-2": "TikTok",
  "footer-follow-3": "Twitter",
  "footer-follow-4": "LinkdIn",
  "footer-docs": "Documenti",
  "footer-docs-1": "Missione",
  "footer-docs-2": "Avviso legale",
  "footer-docs-3": "Privacy",
  "footer-docs-4": "Cookie",
  "footer-docs-5": "Linee guida",
  "footer-contact": "Contattaci",
  "footer-contact-1": "Supporto",
  "footer-contact-2": "Lavora con noi",
  "footer-contact-3": "Investitori",
  "home-title": "Crush - Dove i media incontrano il platonico",
  "home-hero-slogan-1": "Where media",
  "home-hero-slogan-2": "meets platonic ™",
  "home-concept-1": "Trova la tua gente",
  "home-concept-2": "Connetti con persone come te nelle diverse fasi della tua vita.",
  "home-concept-3": "Vibes cool",
  "home-concept-4": "Condividi buone vibrazioni e momenti reali. Niente tossicità o pretenziosità.",
  "home-attributes-1": "Aesthetic",
  "home-attributes-2": "Promuoviamo la pubblicazione di contenuti aesthetic e di alta qualità; il web è già invaso da foto di gatti.",
  "home-attributes-3": "Stalker",
  "home-attributes-4": "I tuoi amici sono le star. Scatta alcune raffiche di foto quando sono impreparati e pubblica alcuni GIF sui loro account.",
  "home-attributes-5": "Thread",
  "home-attributes-6": "Partecipa a discussioni vivaci che spaziano dagli argomenti più attuali alle tendenze che plasmeranno il domani.",
  "home-attributes-7": "Gemelli",
  "home-attributes-8": "Connetti con individui che condividono tratti del viso simili ai tuoi, ampliando la tua rete sociale in modo completamente nuovo e divertente.",
  "home-attributes-9": "Segmentazione per età",
  "home-attributes-10": "Raggruppando gli utenti per età, simile alle classi superiori, le connessioni sono limitate agli individui della stessa generazione per un'interazione più sicura ed efficiente.",
  "home-attributes-11": "Crush",
  "home-attributes-12": "Non è un'app di incontri — solo amici e relazioni rilassate. Rivivi la nostalgia dei legami delle superiori in un ambiente digitale.",
  "home-partners": "Powered By",
  "mission-title": "Crush - Missione",
  "mission-date": "Ultimo modifica: 8 dicembre, 2023",
  "mission-header": "Missione e valori",
  "mission-introduction": "Nel cuore di Crush batte qualcosa di più di una semplice rete sociale; è un universo intessuto di valori etici, una filosofia e uno scopo. Questo documento è il viaggio attraverso i fondamenti della nostra visione, dalla nascita dell'idea all'outline meticoloso della creazione che stiamo plasmando.",
  "mission-origin": "L'origine",
  "mission-origin-1": "L'origine di Crush risale a un seme piantato molto prima del suo sviluppo concreto nel 2020 e del suo lancio nel 2023. La nostra visione di fondere i social network con il tocco distintivo di Crush è stata concepita nel 2016, ispirata da 'The Social Network', il notevole lavoro cinematografico riconosciuto da Quentin Tarantino come un gioiello dell'ultima decade. Questo film, che racconta la storia dei giovani fondatori di Facebook, in particolare la figura più riconosciuta, Mark Zuckerberg — il padre dei social network, ci ha fornito una prospettiva illuminante.",
  "mission-origin-2": "Da Crush, crediamo fermamente che il nucleo dei social network odierni risieda nell'amore platonico. Se hai avuto l'opportunità di vedere questo film, potresti aver colto le critiche di Zuckerberg e Eduardo Saverin nei confronti delle app di appuntamenti. In una scena chiave, intorno al minuto 30:00, durante la causa dei fratelli Winklevoss per presunto furto di idee, Saverin menziona:",
  "mission-origin-3": "\"It really hadn't much to do with the Winklevoss' dating site\".",
  "mission-origin-4": "È curioso perché alcuni minuti dopo, intorno al minuto 36:05, vediamo Mark implementare elementi come lo stato della relazione e gli interessi nel suo progetto:",
  "mission-origin-5": "\"Relationship status, interests... This is what drives life in college. Are you having sex or aren't you? That's why people take classes, sit where they sit, and do what they do. It's the center, you know? That's what The Facebook is going to be.\".",
  "mission-origin-6": "Infine, nell'ultima scena, assistiamo a un Zuckerberg innamorato che invia una richiesta di amicizia alla sua crush.",
  "mission-origin-7": "Questo film pone una domanda intrigante:",
  "mission-origin-8": "I social media sono in realtà app di appuntamenti mascherate?",
  "mission-purpose": "Il nostro scopo",
  "mission-purpose-1": "Non esiste una risposta definitiva alla domanda posta nella sezione precedente. Forse sì, forse no... alla fine, dipende dai valori etici e culturali, dalle intenzioni e dall'educazione dell'utente finale. Quello che sappiamo con certezza è che questo apre la porta a problemi reali e molto seri che, a differenza del mondo reale, possono essere effettivamente risolti.",
  "mission-purpose-2": "Che sia per gli standard predefiniti dalla società o per l'anonimato che porta a comportamenti inappropriati online, l'amore nel mondo digitale, che sia platonico e genuino sui social media o più romantico sulle app di incontri, è stato sfortunatamente sessualizzato negli ultimi anni. Perché qualcosa di così bello e fondamentale nella nostra vita sta venendo distorto in questo universo parallelo?",
  "mission-purpose-3": "Cerchiamo di essere chiari e completamente trasparenti. In Spagna, il nostro paese d'origine, secondo dati e statistiche verificabili,",
  "mission-purpose-4": "7 ragazzi su 10 sotto i 15 anni possiedono un dispositivo mobile.",
  "mission-purpose-5": "Ciò significa che circa il 70% dei minori ha accesso illimitato a un mondo virtuale in cui l'interazione con adulti non è stata regolamentata o limitata, esponendoli a rischi come molestie, bullismo o pedofilia, tra gli altri.",
  "mission-purpose-6": "Attualmente, indipendentemente dalla piattaforma di social network a cui ti iscrivi, è comune trovare persone che vendono contenuti inappropriati o condividono qualsiasi tipo di contenuto non adatto. Più passa il tempo, più la situazione diventa disgustosa.",
  "mission-purpose-7": "Il nostro obiettivo è eliminare la sessualizzazione e la tossicità della rete affrontandone direttamente l'origine.",
  "mission-purpose-8": "Crush si propone come una ridefinizione dei social network da una prospettiva diversa, concentrata sulle relazioni platoniche.",
  "mission-purpose-9": "Non offriamo un sistema di incontri o la creazione di relazioni romantiche/serie, poiché ciò contraddice le",
  "mission-purpose-10": "linee guida della nostra comunità.",
  "mission-purpose-11": "Invece, offriamo ciò che è stato immaginato nel film 'The Social Network', ma con una soluzione al problema e un approccio più cool.",
  "mission-purpose-12": "La nostra missione va oltre il tipico \"condividi momenti reali\" o \"ti aiutiamo a connetterti\". Oltre ad accompagnare l'utente attraverso le varie fasi della sua vita, ci dedichiamo a risolvere i problemi menzionati in precedenza. Sulla nostra piattaforma, al momento della stesura di questo documento, è già impossibile per un minore interagire con un adulto (e viceversa), non solo per chiudere le porte a queste questioni etiche, ma anche per incoraggiare la creazione di legami tra persone della stessa generazione, con una mentalità simile. Prevediamo di introdurre soluzioni più efficaci nella prossima decade di sviluppo; questa è la nostra promessa.",
  "guidelines-title": "Crush - Linee guida",
  "guidelines-date": "Ultimo modifica: 4 aprile, 2024",
  "guidelines-header": "Linee guida",
  "guidelines-introduction-1": "In Crush, si tratta di stabilire connessioni platoniche, condividere momenti autentici della vita con gli amici, socializzare e creare nuove amicizie che si allineano al tuo modo di pensare. Crediamo fortemente nella libertà di espressione, ma valorizziamo anche la capacità di distinguere tra giusto e sbagliato. Questo documento delinea gli standard del nostro social network, essenziali per realizzare il",
  "guidelines-introduction-2": "nostro scopo principale.",
  "guidelines-introduction-3": "Attendiamo un comportamento appropriato, contenuti accurati, autenticità, umiltà e, soprattutto, responsabilità. Qualsiasi violazione dei nostri principi fondamentali potrebbe comportare la sospensione permanente del tuo account, senza possibilità di ri-registrarti nel nostro sistema.",
  "guidelines-introduction-4": "Proprio come nella vita reale, ci sono conseguenze per le azioni. Si prega di rispettare le nostre regole e di non utilizzare il nostro sistema in modi contrari alla legge. Vogliamo sottolineare la nostra serietà e rigore; un tono giocoso non è gradito in questo contesto.",
  "guidelines-dating": "Anti-appuntamenti",
  "guidelines-dating-1": "Crush è una combinazione di social media adatta a tutte le persone",
  "guidelines-dating-2": "di età superiore ai 15 anni,",
  "guidelines-dating-3": "che siano o meno in una relazione romantica. Non confondere amicizie e relazioni platoniche (opzionali) con relazioni romantiche o impegni reali.",
  "guidelines-dating-4": "Di seguito, ti elenchiamo una serie di comportamenti che devi adottare e rispettare per rimanere sulla nostra piattaforma:",
  "guidelines-dating-5": "Non utilizzare il nostro servizio per cercare o richiedere incontri romantici.",
  "guidelines-dating-6": "Se l'amore deve entrare nella tua vita, che non sia attraverso uno schermo.",
  "guidelines-dating-7": "Non pubblicare cose sporche.",
  "guidelines-dating-8": "Non molestare o infastidire nessun utente.",
  "guidelines-dating-9": "Non mentire sulla tua età o informazioni personali, e tanto meno assumere l'identità di un'altra persona.",
  "guidelines-dating-10": "È un crimine molto grave che potrebbe comportare azioni legali contro di te.",
  "guidelines-content": "Aesthetic",
  "guidelines-content-1": "Per noi, ogni forma di software è arte e l'arte va rispettata. In un social network, la qualità della piattaforma si riflette nei contenuti che gli utenti generano. Nel nostro caso, incoraggiamo la creatività e l'ispirazione. Ti chiediamo di pubblicare mantenendo uno standard minimo di qualità.",
  "guidelines-content-2": "Non pubblicare contenuti visivamente scadenti.",
  "guidelines-content-3": "Fai uno sforzo.",
  "guidelines-content-4": "Lo spam viene rimosso immediatamente.",
  "guidelines-content-5": "Se vuoi promuovere il tuo Instagram, usa gli strumenti che ti forniamo.",
  "guidelines-content-6": "Non condividere contenuti che violano la proprietà intellettuale altrui.",
  "guidelines-content-7": "Per favore, evita di pubblicare foto di gattini;",
  "guidelines-content-8": "ce ne sono già molte in rete.",
  "guidelines-content-9": "Non fare la figura del ridicolo pubblicando nudità non necessarie.",
  "guidelines-content-10": "Siamo stufi delle foto senza maglietta.",
  "guidelines-vibes": "Solo buone vibrazioni",
  "guidelines-vibes-1": "In Crush, non tolleriamo comportamenti inappropriati. Proprio come curi la tua immagine nella vita reale, ti chiediamo di essere responsabile e mantenere questa integrità online. Mostra cortesia e rispetto sia verso gli utenti della nostra comunità che verso i creatori della piattaforma.",
  "guidelines-vibes-2": "Lo sfruttamento dei minori, la sextorsione o qualsiasi altro tipo di abuso, in qualsiasi forma, sono severamente vietati.",
  "guidelines-vibes-3": "Il nostro impegno contro il suicidio e l'autolesionismo è assoluto.",
  "guidelines-vibes-4": "Qualsiasi contenuto che promuova, incoraggi o glorifichi queste azioni comporterà una sospensione permanente.",
  "guidelines-vibes-5": "Prestiamo grande attenzione alla legalità.",
  "guidelines-vibes-6": "Evita di condividere contenuti che promuovono attività illegali, così come qualsiasi contenuto relativo a armi o droghe.",
  "guidelines-vibes-7": "Contenuti offensivi o violenti non hanno posto sulla nostra piattaforma e non sono permessi.",
  "guidelines-vibes-8": "La modalità Stalker è pensata per divertirsi tra amici.",
  "guidelines-vibes-9": "Tuttavia, anche se alla fine il contenuto richiede l'approvazione dell'utente (o degli utenti) nel GIF, evita di condividere foto dei tuoi amici senza il loro consenso preventivo.",
  "legal-title": "Crush - Avviso legale",
  "legal-date": "Ultimo modifica: 4 aprile, 2024",
  "legal-header": "Avviso legale",
  "legal-introduction-1": "Ogni applicazione è un universo, e tutti gli universi sono soggetti a regole. In questo documento, troverai l'avviso legale e i termini generali d'uso della nostra piattaforma, che sono integrati da quelli definiti nelle nostre",
  "legal-introduction-2": "linee guida della community.",
  "legal-owner": "1. Identificazione del titolare",
  "legal-owner-1": "Il sito web www.crushsocialnetwork.com (in pio, il",
  "legal-owner-2": "'Sito Web'",
  "legal-owner-3": ") l'applicazione mobile per smartphone denominata 'Crush' (in poi, la",
  "legal-owner-4": "'App'",
  "legal-owner-5": ") sono di propietà di CRUSH SOCIAL NETWORK, S.R.L., fornito con NIF: B-72599830 e iscritta al Registro Mercantile di Valencia con i seguenti dati anagrafici: T. 3606, F.75, I.1, H. MU-108656, e i cui dati di contatto sono:",
  "legal-owner-6": "Sede legale:",
  "legal-owner-7": "Calle Pintor Velázquez, 3, 1, 30530 Cieza, Murcia (Spagna)",
  "legal-owner-8": "E-mail di contatto:",
  "legal-area": "2. Campo di applicazione",
  "legal-area-1": "Le presenti Condizioni Generali d'Uso del Sito Web (in poi, las",
  "legal-area-2": "'Condizioni d'uso'",
  "legal-area-3": ") raccogliere i termini e le condizioni che regolano l'accesso, la navigazione e l'utilizzo del Sito Web e dell'App.",
  "legal-area-4": "In questo senso, l'accesso, l'uso e la navigazione attraverso il Sito Web e l'App conferiscono la condizione di utente(s) (in poi, il",
  "legal-area-5": "'Utente'",
  "legal-area-6": "o gli",
  "legal-area-7": "'Utenti'",
  "legal-area-8": "), e implica l'accettazione di ognuna delle presenti Condizioni d'Uso. L'Utente è pienamente consapevole che la mera navigazione del Sito Web o dell'App implica l'accettazione delle presenti Condizioni d'Uso e, pertanto, se l'Utente non è d'accordo con esse, non deve utilizzare il Sito Web o questa App.",
  "legal-area-9": "Il Sito Web e l'App sono rivolti principalmente agli Utenti residenti in Spagna. Crush non garantisce che il Sito Web o l'App siano conformi alle leggi di altri paesi, in tutto o in parte. Se l'Utente risiede o ha il suo domicilio in un altro luogo e decide di accedere e/o navigare nel Sito Web o accedere o utilizzare l'App, lo farà a proprio rischio e deve garantire che tale accesso, navigazione o utilizzo sia conforme alla legislazione locale a lui applicabile, non assumendo Crush alcuna responsabilità che possa derivare da tale accesso o utilizzo.",
  "legal-area-10": "Crush può, in qualsiasi momento e senza preavviso, modificare le presenti Condizioni d'Uso, che saranno pubblicate man mano che tali modifiche si verificano.",
  "legal-conditions": "3. Condizioni di accesso e uso",
  "legal-conditions-1": "L'accesso al Sito Web o all'App da parte dell'Utente, di norma, è gratuito. Nel caso in cui vi siano funzionalità o servizi riservati a determinati Utenti o che sia necessario pagare un prezzo, questi saranno debitamente identificati nel Sito Web o nel App.",
  "legal-conditions-2": "L'accesso al Sito Web o all'App avviene sotto la sola responsabilità dell'Utente e non implica alcun tipo di rapporto commerciale tra Crush e l'Utente. L'Utente è responsabile di garantire che le informazioni e i contenuti inclusi nel Sito Web o nell'App siano conformi ai propri requisiti specifici.",
  "legal-conditions-3": "L'Utente si impegna a:",
  "legal-conditions-4": "Accedere e utilizzare sia il Sito Web che l'App in conformità con buona fede e in conformità con le presenti Condizioni d'Uso, la legge, la morale e l'ordine pubblico, con il prerequisito aggiuntivo di raggiungere un'età minima di 15 anni.",
  "legal-conditions-5": "Presso Crush, certifichiamo di rispettare la legislazione applicabile sulla sicurezza dei minori e prendiamo seriamente il materiale di abuso sessuale sui minori. Adottiamo misure adeguate, come la rimozione di tale materiale una volta venuti a conoscenza effettiva della sua esistenza, in conformità ai nostri standard e alle leggi pertinenti.",
  "legal-conditions-6": "Fornire informazioni veritiere, aggiornate e lecite nei moduli offerti sia sul Sito Web che nell'App. In ogni caso, l'Utente comunicherà immediatamente a Crush qualsiasi fatto che consenta l'uso improprio delle informazioni registrate in detti moduli, quali, ma non solo, furto, smarrimento o accesso non autorizzato a identificativi e/o password, al fine di procedere alla sua immediata cancellazione.",
  "legal-conditions-7": "Non intraprendere alcuna azione sul Sito Web o sull'App che possa causare danni o alterazioni ai contenuti, ai programmi o ai sistemi del Sito Web o dell'App, inclusa l'introduzione di virus informatici, l'installazione di robot o qualsiasi software o file dannoso e difettoso, o in definitiva che possa causare danni ai nostri sistemi informatici.",
  "legal-conditions-8": "L'Utente sarà responsabile in ogni caso dei danni che potrebbero essere causati sia a Crush che a terzi e Crush potrà adottare le misure tecniche, legali e qualsiasi altra misura che ritenga appropriata per prevenire, mitigare o fermare le conseguenze dei precedenti comportamenti vietati e per esigere tutte le responsabilità ritenute opportune.",
  "legal-property": "4. Proprietà intellettuale e industriale",
  "legal-property-1": "Tutti i contenuti sia del Sito Web che dell'App, inclusi, a titolo esemplificativo, testi, immagini, fotografie, video, grafica, segni distintivi di qualsiasi tipo, icone, interfacce, nonché il software, il codice sorgente, i disegni, l'architettura, presentazione, disposizione e classificazione dei contenuti e di ogni altro elemento presente nel Sito o nell'App che possa essere oggetto di diritti di proprietà intellettuale sono di proprietà esclusiva di Crush o di terzi che ne hanno concesso in licenza, autorizzato o acconsentito al loro utilizzo sul Sito Web o nell'App.",
  "legal-property-2": "In questo senso, quando l'Utente accede, naviga e utilizza il Sito Web o accede o utilizza l'App, non vengono concessi diritti di sfruttamento che esistano o possano esistere su tutti o parte di questi, Crush si riserva tutti questi diritti. L'Utente può solo visualizzare gli elementi del Sito Web e dell'App e utilizzarli nella misura strettamente necessaria al loro corretto utilizzo. In particolare, è vietato utilizzare o vendere a fini commerciali qualsiasi materiale o contenuto presente sul Sito o sull'App senza la preventiva autorizzazione di Crush.",
  "legal-property-3": "Crush si riserva il diritto di modificare, in qualsiasi momento e senza preavviso, la presentazione e la configurazione del Sito Web o dell'App e i contenuti e i servizi che possono essere incorporati in essi L'Utente riconosce e accetta che in qualsiasi momento Crush possa interrompere, disattivare e/o cancellare uno qualsiasi di questi elementi integrati nel Sito Web e nell'App o l'accesso ad essi.",
  "legal-property-4": "Se l'Utente ritiene che uno qualsiasi dei contenuti del Sito Web o dell'App costituisca una violazione dei diritti di protezione della proprietà intellettuale, deve informare immediatamente Crush tramite le informazioni di contatto nella sezione 1 (Identificazione del Titolare) delle presenti Condizioni d'Uso.",
  "legal-disclaimer": "5. Esclusione di garanzie e responsabilità",
  "legal-disclaimer-1": "Le informazioni pubblicate sul Sito Web o sull'App potrebbero non essere esaustive o non completamente aggiornate, pertanto Crush non si assume alcuna responsabilità derivante dalla mancanza di integrità, aggiornamento o accuratezza dei dati e delle informazioni contenute nelle diverse pagine che fanno parte del Sito Web o dell'App.",
  "legal-disclaimer-2": "Crush non garantisce che l'accesso all'App o al Sito Web sarà ininterrotto o privo di errori. Allo stesso modo, Crush non garantisce che il contenuto o il software presente sul Sito Web o sull'App non causerà danni al sistema informatico dell'Utente (software e hardware). Crush non sarà responsabile per perdite, danni o perdite di qualsiasi tipo derivanti dall'accesso, dalla navigazione e dall'uso del Sito Web o dell'App, inclusi, a titolo esemplificativo ma non esaustivo, quelli causati ai sistemi informatici o quelli causati dall'introduzione di virus.",
  "legal-disclaimer-3": "Crush, parimenti, è esonerata da ogni responsabilità derivante dall'uso inappropriato del Sito o dell'App da parte dell'Utente.",
  "legal-links": "6. Link",
  "legal-links-1": "Si segnala che sia il Sito Web che l'App possono trovare, tra gli altri, link, banner, pulsanti, directory e motori di ricerca che consentono agli Utenti di accedere ad altri siti web o app appartenenti a e/o gestiti da terzi e che, pertanto, sono al di fuori del controllo di Crush, che non può assumersi responsabilità per il contenuto che appare su dette pagine o app.",
  "legal-links-2": "Nel caso in cui ritieni che tali contenuti siano inappropriati o contrari agli scopi di Crush, ti preghiamo di informarci attraverso i mezzi di contatto sopra indicati, in modo che possiamo intraprendere le azioni appropriate.",
  "legal-jurisdiction": "7. Legge applicabile e giurisdizione",
  "legal-jurisdiction-1": "Le presenti Condizioni d'Uso e la loro esecuzione sono soggette alla legge spagnola.",
  "legal-jurisdiction-2": "In caso di controversie sull'interpretazione o l'applicazione delle presenti Condizioni d'Uso, le Parti negozieranno in buona fede per tentare di risolvere tale discrepanza o reclamo. Tuttavia, nel caso in cui la discrepanza o il reclamo non venga risolto, le Parti si sottoporranno alle corti o ai tribunali che corrispondono secondo la legge.",
  "privacy-title": "Crush - Privacy",
  "privacy-date": "Ultimo modifica: 8 dicembre, 2023",
  "privacy-header": "Politica sulla riservatezza",
  "privacy-introduction-1": "Presso Crush, ci impegniamo a garantire che i tuoi dati personali siano protetti e non vengano utilizzati per scopi diversi da quelli indicati nella presente Informativa sulla privacy. Per questo motivo, in questa sezione informiamo gli utenti e gli interessati di tutto ciò che riguarda il trattamento dei loro dati personali, rispettando così le norme sulla protezione dei dati applicabili nel nostro paese: Regolamento Generale sulla Protezione dei Dati (UE) 2016/679, del 27 aprile relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personali, nonché alla libera circolazione di tali dati (di seguito,",
  "privacy-introduction-2": "'RGPD'",
  "privacy-introduction-3": ") e Legge Organica 3/2018, del 5 dicembre, Protezione dei Dati Personali e garanzia dei diritti digitali (in poi,",
  "privacy-introduction-4": "'LOPDGDD'",
  "privacy-introduction-5": "La presente politica sulla riservatezza è applicabile al trattamento dei dati che CRUSH SOCIAL NETWORK, S.R.L., effettuato sia attraverso il sito web: www.crushsocialnetwork.com (in poi, il 'Sito Web'), sia attraverso l'applicazione mobile per Smartphone denominata 'Crush' (di seguito, la 'App') e/o quelle indicate. Ti consigliamo di leggerlo attentamente prima di utilizzare il Sito Web o l'App e di fornire i tuoi dati attraverso di essi. Non esitate a farci qualsiasi domanda al riguardo via e-mail:",
  "privacy-index": "Indice dei contenuti",
  "privacy-index-1": "In questa Policy troverai tutte le informazioni relative al trattamento dei tuoi dati personali e i diritti che puoi esercitare per mantenere il controllo su di essi. In questo senso, troverai informazioni su:",
  "privacy-index-2": "Chi è responsabile del trattamento dei tuoi dati.",
  "privacy-index-3": "Quali requisiti devi soddisfare per fornirci i tuoi dati personali.",
  "privacy-index-4": "Quali trattamenti di dati effettuiamo attraverso l'App e quali sono le sue principali caratteristiche, spiegando:",
  "privacy-index-5": "Quali dati raccogliamo e quali sono le modalità per raccoglierli.",
  "privacy-index-6": "Per quali finalità raccogliamo i dati richiesti.",
  "privacy-index-7": "Qual è la legittimità del suo trattamento.",
  "privacy-index-8": "Quanto li conserviamo?",
  "privacy-index-9": "A quali destinatari vengono comunicati i tuoi dati.",
  "privacy-index-10": "Esistenza di trasferimenti internazionali dei tuoi dati.",
  "privacy-index-11": "Quali sono i tuoi diritti e come puoi esercitarli?",
  "privacy-index-12": "Come proteggiamo le tue informazioni personali.",
  "privacy-index-13": "Modifiche a questa politica.",
  "privacy-controller": "1. Chi è responsabile del trattamento dei tuoi dati personali?",
  "privacy-controller-1": "I tuoi dati personali saranno trattati dalla società CRUSH SOCIAL NETWORK, S.R.L. (",
  "privacy-controller-2": "'CRUSH'",
  "privacy-controller-3": "), con NIF B-72599830 e i cui recapiti sono i seguenti:",
  "privacy-controller-4": "Indirizzo:",
  "privacy-controller-5": "Calle Pintor Velázquez, 3, 1, 30530 Cieza, Murcia (Spagna)",
  "privacy-controller-6": "Contatto email:",
  "privacy-dpo": "1.1 Il nostro responsabile della protezione dei dati",
  "privacy-dpo-1": "Da CRUSH, mettiamo a tua disposizione i dettagli di contatto del nostro responsabile della protezione dei dati, al quale puoi rivolgere qualsiasi domanda tu possa avere in relazione alla presente Informativa sulla privacy o al trattamento dei tuoi dati personali.",
  "privacy-dpo-2": "E-mail",
  "privacy-requirements": "2. Quali requisiti devi soddisfare per fornirci i tuoi dati personali?",
  "privacy-requirements-1": "2.1. Età minima. Per fornirci i tuoi dati personali e utilizzare il nostro Sito Web e la nostra App, devi avere più di 15 anni.",
  "privacy-requirements-2": "2.2. Veracità. Quando ci fornisci i tuoi dati per utilizzare i nostri servizi, garantisci che i dati e le informazioni fornite sono reali, veritiere, aggiornate e appartengono anche a te e non a terzi.",
  "privacy-requirements-3": "Inoltre, è necessario che ci comunichiate qualsiasi cambiamento intervenga nei dati forniti, rispondendo in ogni caso della veridicità e dell'esattezza dei dati forniti in ogni momento.",
  "privacy-requirements-4": "2.3. Controllo dell'età e della veridicità. Da CRUSH ci riserviamo il diritto di verificare la tua età e le informazioni identificative, se necessario, anche richiedendo un documento ufficiale o una procedura equivalente e, in caso di rilevamento di frode verificata o sospetta che tu abbia meno dell'età indicata, di eliminare, temporaneamente disattivare e/o cancellare il tuo account.",
  "privacy-processing": "3. Quali trattamenti di dati effettuiamo attraverso il Sito Web e l'App e quali sono le sue principali caratteristiche?",
  "privacy-processing-1": "Di seguito, ti spieghiamo come trattiamo i tuoi dati personali e ti forniamo, nel dettaglio, tutte le informazioni rilevanti in merito alla tua privacy:",
  "privacy-contact": "3.1. Quando ci contatti attraverso i nostri canali (modulo di contatto, chat, email)",
  "privacy-contact-1": "Quali sono le modalità di raccolta dei dati?",
  "privacy-contact-2": "Modulo di Contatto.",
  "privacy-contact-3": "Chat.",
  "privacy-contact-4": "Invio di email a",
  "privacy-contact-5": "o altri indirizzi e-mail CRUSH.",
  "privacy-contact-6": "Quali dati raccogliamo?",
  "privacy-contact-7": "Identificazione e informazioni di contatto.",
  "privacy-contact-8": "Raccogliamo i tuoi dati identificativi (nome e cognome) e indirizzo email, nonché ogni altra informazione che includi volontariamente nelle comunicazioni che ci invii.",
  "privacy-contact-9": "Potremmo richiederti ulteriori informazioni se necessario per soddisfare la tua richiesta o esigenza.",
  "privacy-contact-10": "Quali sono le finalità del trattamento dei tuoi dati personali?",
  "privacy-contact-11": "Rispondi alle tue richieste.",
  "privacy-contact-12": "Lo scopo principale del trattamento di questi dati sarà rispondere alle tue richieste, rispondere alle tue domande e/o fornirti le informazioni richieste, nonché, se del caso, dare seguito alle tue richieste.",
  "privacy-contact-13": "Migliora il servizio clienti.",
  "privacy-contact-14": "Tutte le informazioni derivate da dubbi, domande e consigli offerti alle parti interessate, nonché il modo in cui le richieste vengono risolte, ci consentono di sapere come forniamo il nostro servizio clienti, consentendoci di migliorarne la qualità.",
  "privacy-contact-15": "Allo stesso modo, tutte le informazioni raccolte, trascorso il periodo di conservazione sotto indicato, vengono rese anonime e utilizzate allo scopo di poter analizzare le domande più frequenti attraverso la chat e poter automatizzare quelle più frequenti, preparare FAQ o conservarle a fini statistici per lo sviluppo di strategie aziendali.",
  "privacy-contact-16": "Qual è la base giuridica che ci consente di trattare i tuoi dati? Il conferimento di questi dati è obbligatorio?",
  "privacy-contact-17": "Consenso.",
  "privacy-contact-18": "I dati forniti per le finalità di cui sopra saranno trattati sulla base del Suo consenso, concesso quando Lei volontariamente ci contatterà attraverso i mezzi messi a Sua disposizione per richiedere informazioni o effettuare una richiesta.",
  "privacy-contact-19": "Tutte le informazioni raccolte dal servizio clienti saranno elaborate a fini statistici che ci aiuteranno a migliorare la qualità del servizio clienti fornito. Tale finalità è ritenuta compatibile con quella iniziale.",
  "privacy-contact-20": "Le informazioni che dovrai fornire saranno indicate con un asterisco o simili. Senza queste informazioni non sarebbe possibile rispondere alle vostre domande o richieste.",
  "privacy-contact-21": "Per quanto tempo conserviamo le tue informazioni?",
  "privacy-contact-22": "Tratteremo tutte le tue informazioni personali durante il tempo in cui le tue richieste vengono elaborate e, se necessario, per dar loro seguito. Trascorso tale periodo, CRUSH conserverà, bloccate, tali informazioni durante i periodi previsti dalla normativa per far fronte ad eventuali responsabilità e per dimostrare il rispetto dei nostri obblighi. Da questo momento CRUSH tratterà le informazioni solo in forma anonima, quindi non sarà possibile collegare le informazioni statistiche con gli specifici utenti a cui si riferiscono.",
  "privacy-contact-23": "A chi diamo i tuoi dati personali?",
  "privacy-contact-24": "Non effettuiamo alcun trasferimento ulteriore per svolgere questo trattamento rispetto a quelli indicati, in generale, al punto",
  "privacy-contact-25": "4. A chi forniamo i tuoi dati personali?",
  "privacy-contact-26": "In tal senso, alcuni canali attraverso i quali puoi contattarci sono gestiti da fornitori di servizi, che agiscono in qualità di Responsabili del trattamento. Troverai maggiori informazioni su come agiscono questi fornitori di servizi al punto 4, menzionato sopra.",
  "privacy-app-use": "3.2. Quando ti registri e utilizzi la nostra App 'Crush'",
  "privacy-app-use-1": "Quali sono le modalità di raccolta dei dati?",
  "privacy-app-use-2": "Modulo di registrazione nella nostra App e utilizzo dei suoi diversi spazi.",
  "privacy-app-use-3": "Quali dati raccogliamo?",
  "privacy-app-use-4": "Dati di base per la registrazione:",
  "privacy-app-use-5": "Per registrarti nella nostra App e utilizzare i servizi, dovrai registrarti nella nostra App e creare un",
  "privacy-app-use-6": "'profilo'.",
  "privacy-app-use-7": "Durante il processo di registrazione ti richiediamo, come utente, i seguenti dati:",
  "privacy-app-use-8": "Nome utente.",
  "privacy-app-use-9": "Nome.",
  "privacy-app-use-10": "E-mail.",
  "privacy-app-use-11": "Data di nascita.",
  "privacy-app-use-12": "Dati aggiuntivi:",
  "privacy-app-use-13": "Oltre a quanto sopra, la nostra App ti consente di condividere volontariamente informazioni relative a:",
  "privacy-app-use-14": "Genere.",
  "privacy-app-use-15": "Orientamento sessuale.",
  "privacy-app-use-16": "Interessi.",
  "privacy-app-use-17": "Paese, città o regione.",
  "privacy-app-use-18": "Descrizione su di te.",
  "privacy-app-use-19": "Professione e studi.",
  "privacy-app-use-20": "Gusti musicali.",
  "privacy-app-use-21": "Migliore amico.",
  "privacy-app-use-22": "Se lo autorizzi, la tua precisa geolocalizzazione, che, se lo hai consentito, viene eseguita in background.",
  "privacy-app-use-23": "Dati di utilizzo: possono essere raccolte informazioni sulle tue interazioni con altri utenti (chat con altri utenti, utenti con cui ti connetti e interagisci, tempo, frequenza di interazione o altre caratteristiche delle tue interazioni).",
  "privacy-app-use-24": "Alcuni dei dati che puoi fornire volontariamente sono considerati sensibili, in quanto relativi al tuo orientamento sessuale. Tieni presente che, se decidi di includere tali informazioni, CRUSH le elaborerà insieme al resto delle informazioni per soddisfare le finalità indicate di seguito. Allo stesso modo, queste informazioni possono essere condivise con il resto degli utenti (possono essere rese pubbliche), in modo che l'uso non autorizzato dell'App da parte di altri utenti possa causare la fuga di questi dati. CRUSH non è responsabile per l'uso inappropriato dei dati da parte di altri utenti.",
  "privacy-app-use-25": "Ti informiamo inoltre che CRUSH utilizza i dati sopra menzionati allo scopo di creare profili al fine di consigliarti contenuti che potrebbero interessarti e con le caratteristiche personali che includi su te stesso, come spiegato di seguito.",
  "privacy-app-use-26": "Inoltre, se lo autorizzi, avremo accesso a:",
  "privacy-app-use-27": "Fotocamera (immagini): la concessione dell'autorizzazione alla fotocamera consente all'utente di caricare qualsiasi immagine direttamente sull'App.",
  "privacy-app-use-28": "Galleria fotografica (immagini): la concessione dell'accesso alla galleria fotografica consente all'utente di caricare qualsiasi immagine dalla propria galleria fotografica.",
  "privacy-app-use-29": "Microfono (audio): la concessione dell'accesso al microfono consente all'utente di utilizzare il registratore vocale del dispositivo direttamente dall'App.",
  "privacy-app-use-30": "Contenuto pubblicato da te:",
  "privacy-app-use-31": "Utilizzando la nostra App, hai la possibilità di interagire con altri utenti attraverso la nostra community, potendo caricare pubblicazioni con contenuti audiovisivi (foto, audio, ecc., se hai concesso i rispettivi permessi) e aggiungere commenti con le tue opinioni, chiedere domande e risolvere le preoccupazioni di altri utenti.",
  "privacy-app-use-32": "Offriamo anche una modalità 'Stalker' emozionante, in cui i tuoi amici possono catturare GIF di te con il tuo consenso. Tuttavia, la responsabilità di catturare e richiedere la pubblicazione di queste GIF sul tuo profilo è dell'utente che avvia la richiesta. La GIF non verrà pubblicata sul tuo profilo finché non avrai accettato la richiesta. Inoltre, tutti i collaboratori possono eliminare permanentemente la pubblicazione, sia cancellandola direttamente sia rifiutando la richiesta.",
  "privacy-app-use-33": "CRUSH utilizza la tecnologia di riconoscimento facciale di Amazon Web Services per elaborare i dati biometrici degli utenti di CRUSH in qualità di fornitore di servizi ('Processore'). Amazon può elaborare e archiviare modelli di volto allo scopo di fornire servizi di verifica e/o identificazione del volto per conto di CRUSH e solo secondo le istruzioni di CRUSH. Amazon memorizzerà questi dati fino a quando CRUSH lo richiederà, che non sarà più lungo di un periodo di tolleranza limitato dopo la data in cui (i) CRUSH cesserà di avere un rapporto con Amazon o (ii) quando CRUSH richiederà l'eliminazione.",
  "privacy-app-use-34": "Nel caso in cui pubblichi post nell'App (che è meramente facoltativo, non ti obblighiamo a farlo) verrà utilizzata la tecnologia di riconoscimento facciale di Amazon, che consente di rilevare volti e trovare utenti simili a te (gemelli) all'interno del nostro sistema. Questo è l'unico uso che facciamo di questa tecnologia di riconoscimento.",
  "privacy-app-use-35": "Il nostro processore, Amazon, si impegna a garantire la propria conformità al GDPR, nonché a fornire una varietà di prodotti, funzionalità, documentazione e risorse per aiutare i propri clienti a soddisfare gli obblighi di conformità previsti dal GDPR.",
  "privacy-app-use-36": "Dati sull'utilizzo dell'App:",
  "privacy-app-use-37": "Raccogliamo inoltre automaticamente dati sull'utilizzo dell'App, in particolare dati tecnici e informazioni relative al tuo utilizzo dell'App, incluso il tipo di dispositivo mobile, l'ID univoco del tuo dispositivo mobile, l'indirizzo IP del tuo dispositivo mobile, il sistema operativo mobile, se qualsiasi, il tipo di browser Internet che utilizzi e le informazioni sul modo in cui utilizzi l'App, come la frequenza e il tempo di utilizzo, gli annunci su cui hai fatto clic, ecc.",
  "privacy-app-use-38": "Dati su di te forniti da terze parti:",
  "privacy-app-use-39": "Altri utenti dell'App possono fornirci informazioni su di te quando utilizzano i nostri servizi; ad esempio, quando interagiscono con te o se ci inviano una segnalazione che ti coinvolge.",
  "privacy-app-use-40": "Quali sono le finalità del trattamento dei tuoi dati personali?",
  "privacy-app-use-41": "Registrati e gestisci il tuo account",
  "privacy-app-use-42": "in modo che possiamo verificare il tuo indirizzo e-mail e l'età per registrarti all'App, interagire con te e darti accesso ai nostri servizi.",
  "privacy-app-use-43": "Gestire ed eseguire il rapporto contrattuale che ci unisce:",
  "privacy-app-use-44": "svolgiamo diversi passaggi necessari all'esecuzione del contratto sottoscritto con te, così che, ad esempio, possiamo fornirti il servizio, contattarti per aiutarti a risolvere dubbi, verificare le informazioni che ci hai fornito. Allo stesso modo, potremmo inviarti comunicazioni di natura amministrativa, su aggiornamenti, modifiche alle nostre condizioni di servizio, avvisi di sicurezza, nonché rispondere a qualsiasi richiesta o esigenza che ci invii.",
  "privacy-app-use-45": "Pubblica i tuoi contenuti.",
  "privacy-app-use-46": "Quando condividi contenuti o informazioni tramite l'App, questi appariranno pubblicati per il pubblico che hai selezionato che può vedere ciò che condividi. Per aggiungere determinati contenuti, come foto e video, dovrai fornirci l'accesso alla tua fotocamera o alla tua galleria fotografica.",
  "privacy-app-use-47": "Qualsiasi utente della nostra App può visualizzare il tuo nome utente e il tuo profilo pubblico sull'App, che include i contenuti che hai condiviso con il pubblico in generale. Inoltre, tieni presente che i tuoi contenuti pubblicati nell'App possono essere condivisi da altri utenti all'interno o all'esterno dell'App, prendine uno screenshot o scaricalo.",
  "privacy-app-use-48": "Pubblica contenuti su di te.",
  "privacy-app-use-49": "Altri utenti dell'App possono creare e condividere contenuti con la tua immagine o dati con un pubblico di tua scelta. Se non ti senti a tuo agio con i contenuti che altri hanno condiviso su di te, ti preghiamo di segnalarlo utilizzando i moduli dell'App o di contattarci via e-mail.",
  "privacy-app-use-50": "Offrirti contenuti (notizie, articoli, esperienze, tra gli altri)",
  "privacy-app-use-51": "creato direttamente da CRUSH o in collaborazione con persone esterne che potrebbero interessarti.",
  "privacy-app-use-52": "Invio di notifiche push e avvisi all'interno dell'App.",
  "privacy-app-use-53": "Se hai aderito, riceverai notifiche push da noi nel tuo browser web o sul tuo dispositivo mobile. Le notifiche push possono includere promemoria, comunicazioni promozionali, avvisi e aggiornamenti sull'App e sui Servizi.",
  "privacy-app-use-54": "Se attivi queste notifiche, riceverai avvisi personalizzati, basati su un profilo che sviluppiamo su di te e sul tuo utilizzo dell'App. Per la personalizzazione degli avvisi, le informazioni ottenute attraverso i dati da te inseriti nell'App e i dati raccolti sull'utilizzo dell'App (contenuti visitati) per creare un profilo in relazione ai tuoi interessi e offrirti principalmente quei contenuti e informazioni che ti sta bene.",
  "privacy-app-use-55": "Puoi disattivare le notifiche push in qualsiasi momento modificando le impostazioni sul tuo dispositivo mobile, sebbene la disattivazione non influirà su altre comunicazioni che potresti ricevere da CRUSH, come le comunicazioni e-mail.",
  "privacy-app-use-56": "Prevenzione, rilevamento e perseguimento di attività illegali o contrarie alle condizioni di servizio o che mettono in pericolo la sicurezza delle informazioni o dell'App:",
  "privacy-app-use-57": "CRUSH può elaborare i dati per controllare e prevenire qualsiasi forma di abuso dei nostri servizi, come attività fraudolente, attacchi denial-of-service, invio di spam, accesso non autorizzato agli account dei nostri utenti, nonché qualsiasi altra pratica contraria a le Condizioni Generali e l'Uso dell'App o metta in pericolo la sicurezza delle informazioni o l'integrità dell'App stessa, potendo eliminare il contenuto o l'account contrari alla legge, alle nostre Condizioni Generali o alle Linee Guida della Community.",
  "privacy-app-use-58": "All'interno di questa finalità è inclusa anche l'elaborazione dei reclami che presenti contro altri utenti o quelli che vengono presentati contro di te, con la possibilità di contattare CRUSH per analizzare il caso specifico e garantire che ci sarà un intervento umano per correggere o rettificare il problema che ci sorge.",
  "privacy-app-use-59": "Potremmo anche utilizzare strumenti per rilevare contenuti inappropriati (ad esempio contenuti per adulti o violenti) nei contenuti visivi che pubblichi.",
  "privacy-app-use-60": "Per offrirti un servizio migliore, i tuoi dati saranno inoltre trattati allo scopo di:",
  "privacy-app-use-61": "Aiutarti a connetterti con altri utenti, permettendoti di interagire con loro attraverso schermate di chat e altri meccanismi.",
  "privacy-app-use-62": "Raccomandarti ad altri utenti simili a te.",
  "privacy-app-use-63": "Per formulare raccomandazioni, utilizziamo le informazioni che ci fornisci, comprese le informazioni sulla tua posizione se dai il tuo permesso, nonché le informazioni che otteniamo dal tuo utilizzo di questo spazio (ad esempio, le caratteristiche delle persone con cui interagisci effettivamente) per sviluppare un profilo automatizzato su di te e preferibilmente mostrarti quegli utenti che corrispondono al tuo profilo e a ciò che stai cercando.",
  "privacy-app-use-64": "In tal senso, ti verranno mostrati preferibilmente gli utenti attivi nell'App, che ti sono vicini, che corrispondono alle preferenze indicate e il tuo profilo creato dalle tue interazioni, assicurandoti altresì che le tue caratteristiche e il tuo profilo corrispondano alle preferenze degli utenti consigliati.",
  "privacy-app-use-65": "Finalità statistiche ed altre effettuate con dati anonimi.",
  "privacy-app-use-66": "Potremmo rendere anonime tutte le informazioni personali raccolte dagli utenti che utilizzano l'App per scopi statistici e di altro tipo, come la formazione di algoritmi, per misurare l'efficacia delle campagne pubblicitarie disponibili sull'App, la navigabilità e l'usabilità dell'App per poter migliorare la design, presentazione e funzionalità disponibili o altro.",
  "privacy-app-use-67": "Per mostrarti pubblicità:",
  "privacy-app-use-68": "La nostra App può contenere pubblicità che, previo tuo consenso, sarà personalizzata secondo i tuoi gusti e le tue preferenze.",
  "privacy-app-use-69": "Qual è la base giuridica che ci consente di trattare i tuoi dati? Il conferimento di questi dati è obbligatorio?",
  "privacy-app-use-70": "Eseguire e mantenere il rapporto contrattuale tra le Parti.",
  "privacy-app-use-71": "Il motivo per cui elaboriamo le tue informazioni per gli scopi sopra menzionati è per adempiere al contratto che abbiamo con te.",
  "privacy-app-use-72": "Consenso.",
  "privacy-app-use-73": "Alcune funzionalità si basano sul tuo espresso consenso: (i) pubblicazione dei tuoi contenuti, (ii) accesso alla fotocamera o alla galleria fotografica per pubblicare determinati contenuti nell'App, (iii) accesso alla geolocalizzazione del dispositivo per la raccomandazione dell'utente da parte di prossimità (iv) ricezione di notifiche push personalizzate (v) visualizzazione di contenuti pubblicitari personalizzati. Inoltre, se decidi di fornirci ulteriori informazioni volontarie per completare il tuo profilo, tali informazioni verranno utilizzate in base al tuo consenso.",
  "privacy-app-use-74": "Ricorda che puoi revocare il tuo consenso in qualsiasi momento attraverso le impostazioni del tuo dispositivo, cancellando i tuoi contenuti o attraverso le opzioni disponibili nell'App.",
  "privacy-app-use-75": "Interesse legittimo.",
  "privacy-app-use-76": "I dati saranno trattati in base al nostro legittimo interesse in relazione alla prevenzione di attività illecite e altri connessi alla sicurezza dell'App, a garantire la sicurezza della rete e delle informazioni per prevenire eventi che possano comprometterne la disponibilità, l'autenticità, integrità e riservatezza dei dati personali o della piattaforma stessa, nonché rispetto degli standard comunitari.",
  "privacy-app-use-77": "Potremmo anche mostrarti pubblicità non personalizzata attraverso l'App in base al nostro legittimo interesse.",
  "privacy-app-use-78": "Se desideri ottenere maggiori informazioni sulla ponderazione che abbiamo effettuato sui legittimi interessi di CRUSH in relazione alle finalità di cui sopra, puoi richiederle scrivendoci a",
  "privacy-app-use-79": "Trattamento di dati particolarmente protetti.",
  "privacy-app-use-80": "I dati relativi ai tuoi interessi o aspetti legati all'orientamento sessuale saranno trattati sulla base del tuo esplicito consenso.",
  "privacy-app-use-81": "Per quanto tempo conserviamo le tue informazioni?",
  "privacy-app-use-82": "Conserveremo tutte le tue informazioni personali per tutto il tempo in cui il nostro rapporto contrattuale sarà in vigore, cioè fino a quando non eliminerai il tuo account. Una volta terminato il rapporto contrattuale o cancellato il tuo account, CRUSH conserverà, bloccate, tutte le informazioni durante i periodi previsti dalla legge per indagare su comportamenti illegali o dannosi, far fronte a eventuali responsabilità e dimostrare il rispetto dei nostri obblighi.",
  "privacy-app-use-83": "In relazione ai contenuti da te pubblicati tramite la nostra App, devi tenere presente che qualsiasi pubblicazione o commento che hai pubblicato nella nostra community rimarrà nell'App fino a quando non lo elimini o fino a quando non elimini il tuo account. Tuttavia, qualsiasi altro contenuto in cui appari e che è stato pubblicato da altri utenti (ad esempio foto o video) può rimanere visibile fino a quando tale utente non lo elimina o chiude il proprio account.",
  "privacy-app-use-84": "Tuttavia, ci riserviamo il diritto di utilizzare le tue informazioni in forma aggregata dopo la chiusura del tuo account per fini statistici e per migliorare i nostri servizi. L'utilizzo di tali informazioni non consentirà di essere identificati personalmente.",
  "privacy-app-use-85": "A chi diamo i tuoi dati personali?",
  "privacy-app-use-86": "Non sono previsti specifici trasferimenti dei tuoi dati personali per adempiere alla finalità indicata in questa sezione. Tuttavia, possiamo utilizzare i servizi dei fornitori di servizi, che avranno un accesso limitato ai dati e saranno vincolati da un obbligo di riservatezza (per ulteriori informazioni su come agiscono i nostri fornitori di servizi, vedere il punto",
  "privacy-app-use-87": "4. A chi forniamo i tuoi dati personali? > Fornitori di servizi",
  "privacy-app-use-88": "Tuttavia, tieni presente che quando pubblichi contenuti sulla nostra App, potresti condividere informazioni personali con altri utenti (incluso il tuo profilo pubblico).",
  "privacy-app-use-89": "Inoltre, se qualcuno presenta una segnalazione che ti coinvolge, potremmo informare il denunciante delle azioni che abbiamo intrapreso a seguito della sua segnalazione, se del caso.",
  "privacy-cookies": "3.3. Navigazione sul Sito Web (cookie)",
  "privacy-cookies-1": "Sul nostro Sito Web, così come nella nostra App, utilizziamo cookie o altri strumenti di tracciamento e tracciamento per raccogliere informazioni sull'uso che gli utenti fanno del Sito Web e dell'App.",
  "privacy-cookies-2": "Per maggiori informazioni sul trattamento che svolgiamo tramite detti strumenti di tracciamento, visita la nostra",
  "privacy-cookies-3": "Politica sui Cookie",
  "privacy-social-profiles": "3.4. Profili CRUSH sui social network",
  "privacy-social-profiles-1": "CRUSH ha un profilo sui principali social network, come Twitter, Instagram, LinkedIn o TikTok.",
  "privacy-social-profiles-2": "Quando diventi un follower di una delle nostre pagine sui social network, il trattamento dei dati sarà regolato dalle condizioni d'uso, dalle politiche sulla privacy e dai regolamenti di accesso che appartengono al social network corrispondente e precedentemente accettate dall'utente.",
  "privacy-social-profiles-3": "CRUSH, in tal senso, tratterà i tuoi dati allo scopo di gestire correttamente la sua presenza sul social network, informandoti di attività, prodotti o servizi, nonché per ogni altra finalità che le normative dei social network consentono.",
  "privacy-social-profiles-4": "Tieni presente che non abbiamo alcuna influenza sulle informazioni che il social network raccoglie o su come le elabora, quindi ti consigliamo di tenerti informato sullo scopo e sulla portata della raccolta di informazioni che viene effettuata attraverso detti social network.",
  "privacy-transfer": "4. A chi forniamo i tuoi dati personali?",
  "privacy-transfer-1": "In generale, da CRUSH non comunicheremo i tuoi dati a terzi. Tuttavia, oltre ai bonifici che indichiamo specificamente nella sezione in cui spieghiamo le caratteristiche delle diverse operazioni (punto 3), ti informiamo delle comunicazioni che, in generale, possiamo effettuare, nonché del loro fondamento legittimante, che influenzano tutti i trattamenti precedenti.",
  "privacy-transfer-2": "Fornitori di servizi essenziali",
  "privacy-transfer-3": "per eseguire il servizio che ti offriamo, (ad esempio, società di hosting informatico o piattaforme per l'invio di comunicazioni commerciali). Nonostante quanto sopra, queste entità hanno firmato i corrispondenti accordi di riservatezza e tratteranno i tuoi dati solo secondo le nostre istruzioni, non potendo utilizzarli per i propri scopi o al di fuori del servizio che ci forniscono.",
  "privacy-transfer-4": "Enti pubblici.",
  "privacy-transfer-5": "Potremmo divulgare alle autorità pubbliche competenti i dati e qualsiasi altra informazione in nostro possesso o accessibile attraverso i nostri sistemi quando vi è un obbligo legale in tal senso, nonché quando richiesto, ad esempio, quando lo scopo è quello di prevenire o perseguire abusi di servizi o attività fraudolente attraverso il nostro Sito Web o App. In questi casi, i dati personali che ci fornisci saranno conservati e messi a disposizione delle autorità amministrative o giudiziarie.",
  "privacy-transfer-6": "In caso di operazione societaria:",
  "privacy-transfer-7": "In caso di fusione, acquisizione, vendita di tutto o parte dei suoi beni o qualsiasi altro tipo di transazione aziendale che coinvolga una terza parte, potremmo condividere, divulgare o trasferire i dati dell'utente all'entità successiva (anche durante il pre- fase operativa).",
  "privacy-transfer-8": "A terzi previa aggregazione o anonimizzazione:",
  "privacy-transfer-9": "potremmo divulgare o utilizzare dati aggregati o resi anonimi (ovvero dati che non sono collegabili a una persona fisica identificata o identificabile) per qualsiasi scopo.",
  "privacy-transfer-10": "A terzi con il consenso dell'utente o altra base legittima:",
  "privacy-transfer-11": "Nel caso in cui desideriamo condividere i dati con terze parti al di fuori dell'ambito della presente Informativa sulla privacy, verrà comunque richiesto il tuo consenso o sarai informato su di esso e sulla sua base legittima.",
  "privacy-transfer-12": "Allo stesso modo, ti informiamo che la presente Informativa sulla privacy si riferisce solo alla raccolta, al trattamento e all'utilizzo delle informazioni (relative ai dati personali) da parte nostra attraverso l'interazione che effettui con il nostro Sito Web o App. L'accesso a pagine Web di terze parti a cui è possibile accedere tramite collegamenti dal Sito Web o dall'App dispone di proprie politiche sulla privacy sulle quali non abbiamo alcun controllo. Pertanto, prima di fornire loro qualsiasi informazione personale, ti consigliamo di informarti sulle loro politiche sulla privacy.",
  "privacy-eea": "5. I tuoi dati personali vengono trasferiti in paesi terzi al di fuori dello Spazio Economico Europeo?",
  "privacy-eea-1": "Alcuni dei nostri fornitori di servizi si trovano in paesi al di fuori dello Spazio economico europeo (",
  "privacy-eea-2": "'SEE'",
  "privacy-eea-3": "L'ubicazione di queste società al di fuori del SEE implica l'esistenza di un trasferimento internazionale dei tuoi dati personali, che potrebbe implicare un grado di protezione inferiore rispetto a quello previsto dalle normative europee. Tuttavia, da CRUSH abbiamo applicato misure affinché tali trasferimenti non diano luogo a un grado inferiore di protezione dei dati personali.",
  "privacy-eea-4": "In tal senso, i fornitori di servizi che sono al di fuori del SEE hanno sottoscritto le corrispondenti clausole contrattuali standard approvate dalla Commissione Europea (",
  "privacy-eea-5": "'CCT'",
  "privacy-eea-6": "), un accordo firmato tra i due enti con il quale la società extra UE garantisce di applicare gli standard europei di protezione dei dati.",
  "privacy-eea-7": "Pertanto, l'uso di questi fornitori non comporta un grado di protezione dei dati personali inferiore rispetto all'uso di fornitori situati nell'Unione Europea. È possibile consultare il contenuto del CCT al seguente link:",
  "privacy-eea-8": "Successivamente, indichiamo quali sono i trasferimenti internazionali che effettuiamo da CRUSH:",
  "privacy-eea-9": "Nome",
  "privacy-eea-10": "Attività interessate",
  "privacy-eea-11": "Paese",
  "privacy-eea-12": "Meccanismo di trasferimento",
  "privacy-eea-13": "Tutto",
  "privacy-eea-14": "Rilevamento dei volti e ricerca di volti dall'aspetto simile",
  "privacy-eea-15": "Le notifiche push",
  "privacy-eea-16": "Inviando e-mail",
  "privacy-eea-usa": "Stati Uniti d'America",
  "privacy-eea-sccs": "Clausole contrattuali standard (SCC)",
  "privacy-rights": "6. Quali sono i diritti che puoi esercitare in qualità di interessato?",
  "privacy-rights-1": "Puoi esercitare i diritti che la legge ti garantisce in relazione al trattamento dei tuoi dati personali contattando via mail il nostro Delegato alla Protezione dei Dati Personali",
  "privacy-rights-2": "Qualsiasi richiesta di diritti che riceviamo sarà risolta nel più breve tempo possibile e, in ogni caso, entro il termine massimo stabilito dalla legge, dalla data di ricezione. In alcuni casi, sarà necessario richiedere una copia del proprio documento di identità o altro documento di riconoscimento per verificare la propria identità.",
  "privacy-rights-3": "I diritti che ti spettano in qualità di interessato sono i seguenti:",
  "privacy-rights-4": "Diritto di revocare il consenso concesso",
  "privacy-rights-5": "Puoi revocare in qualsiasi momento il tuo consenso in relazione a tutti i trattamenti basati su di esso. Tuttavia, la revoca del consenso non pregiudicherà la liceità del trattamento basata sul consenso prestato prima della revoca.",
  "privacy-rights-6": "Diritto di accesso",
  "privacy-rights-7": "Lei ha il diritto di conoscere, se del caso, quali dati vengono trattati e, in tal caso, di ottenerne copia, nonché di ottenere informazioni in merito a:",
  "privacy-rights-8": "l'origine e i destinatari dei dati;",
  "privacy-rights-9": "le finalità per le quali sono trattati;",
  "privacy-rights-10": "se esiste un processo decisionale automatizzato, compresa la profilazione;",
  "privacy-rights-11": "il periodo di conservazione dei dati; e",
  "privacy-rights-12": "i diritti previsti dalla normativa.",
  "privacy-rights-13": "Diritto di rettifica",
  "privacy-rights-14": "Hai il diritto di ottenere la rettifica dei tuoi dati personali o di completarli quando sono incompleti.",
  "privacy-rights-15": "Diritto di cancellazione",
  "privacy-rights-16": "Hai il diritto di richiedere la cancellazione dei tuoi dati personali se non sono più necessari per lo scopo per il quale sono stati raccolti o, se del caso, se non siamo più autorizzati a trattarli.",
  "privacy-rights-17": "Diritto alla portabilità dei dati",
  "privacy-rights-18": "Hai il diritto di richiedere la portabilità dei tuoi dati nel caso di trattamenti basati sul tuo consenso o sull'esecuzione di un contratto, purché il trattamento sia stato effettuato con mezzi automatizzati. In caso di esercizio di tale diritto, riceverai i tuoi dati personali in un formato strutturato, di uso comune e leggibile da qualsiasi dispositivo elettronico. Tuttavia, puoi anche richiedere, quando possibile, che i tuoi dati siano trasmessi direttamente ad un'altra società.",
  "privacy-rights-19": "Diritto alla limitazione del trattamento dei dati personali",
  "privacy-rights-20": "Hai il diritto di limitare il trattamento dei tuoi dati nei seguenti casi:",
  "privacy-rights-21": "Quando hai richiesto la rettifica dei tuoi dati personali, durante il periodo in cui verifichiamo la loro accuratezza.",
  "privacy-rights-22": "Quando consideri che non siamo autorizzati a trattare i tuoi dati. In tal caso, puoi chiederci di limitarne l'uso invece di richiederne la cancellazione.",
  "privacy-rights-23": "Quando ritieni che non sia più necessario per noi continuare a elaborare i tuoi dati e desideri che li conserviamo ai fini dell'esercizio o della difesa dei reclami.",
  "privacy-rights-24": "Nei casi in cui vi sia un trattamento basato sul nostro legittimo interesse e tu abbia esercitato il tuo diritto di opporti, puoi chiederci di limitare l'utilizzo dei tuoi dati in sede di verifica della prevalenza di detti interessi rispetto ai tuoi.",
  "privacy-rights-25": "Diritto di opposizione",
  "privacy-rights-26": "Hai il diritto di opporti in qualsiasi momento al trattamento dei tuoi dati personali basato sul nostro legittimo interesse, compresa la profilazione.",
  "privacy-rights-27": "Diritto di proporre reclamo a un'Autorità di Controllo",
  "privacy-rights-28": "Ricorda che, in qualsiasi momento, e nel caso in cui ritieni che abbiamo violato il tuo diritto alla protezione dei tuoi dati, puoi rivolgere la tua difesa all'autorità di controllo corrispondente, nel caso della Spagna, l'Agenzia spagnola per la protezione dei dati (",
  "privacy-confidence": "7. Come garantiamo la riservatezza delle tue informazioni?",
  "privacy-confidence-1": "La sicurezza dei tuoi dati personali è per noi una priorità. Per questo motivo, CRUSH ha implementato tutte le misure di sicurezza necessarie per garantire un uso e un trattamento effettivo dei dati personali forniti dall'utente, salvaguardando l'intimità, la privacy, la riservatezza e l'integrità degli stessi, e si avvale dei mezzi tecnici necessari per evitare l'alterazione, perdita, accesso non autorizzato o trattamento dei tuoi dati, secondo lo stato della tecnologia in ogni momento.",
  "privacy-confidence-2": "Di conseguenza, rispettiamo gli standard di sicurezza raccomandati per proteggerli. Tuttavia, è impossibile garantire completamente la tua sicurezza a causa della natura stessa di Internet e perché potrebbero esserci azioni dannose da parte di terzi al di fuori del nostro controllo.",
  "privacy-confidence-3": "Promettiamo di agire rapidamente e diligentemente nel caso in cui la sicurezza dei dati sia in pericolo o compromessa e di informarti in merito nel caso in cui sia rilevante.",
  "privacy-modifications": "8. Modifiche a questa politica",
  "privacy-modifications-1": "Da CRUSH possiamo modificare il contenuto della presente Informativa sulla privacy in qualsiasi momento, in particolare in caso di modifiche legislative, giurisprudenziali o interpretative dell'Agenzia spagnola per la protezione dei dati che influiscano sul trattamento dei dati effettuato da CRUSH tramite il Sito Web o l'App. Qualsiasi nuova versione della presente Informativa sulla privacy entrerà in vigore alla data di entrata in vigore pubblicata.",
  "privacy-modifications-2": "Se la versione rivista include una modifica sostanziale che influisce sul trattamento dei tuoi dati, ti informeremo con almeno 30 giorni di anticipo pubblicando un avviso sul nostro Sito Web o sulla nostra App o inviandoti una notifica via e-mail. In ogni caso, ti consigliamo di rivedere periodicamente la presente Privacy Policy per essere informato su come vengono trattati e protetti i tuoi dati personali, nonché sui tuoi diritti.",
  "cookies-title": "Crush - Cookie",
  "cookies-date": "Ultimo modifica: 8 dicembre, 2023",
  "cookies-header": "Politica sui cookie",
  "cookies-introduction-1": "Entrambi sul sito web www.crushsocialnetwork.com (in poi, il",
  "cookies-introduction-2": "'Sito Web'",
  "cookies-introduction-3": ") nonché attraverso l'applicazione mobile per smartphone con il nome 'Crush' (in poi, la",
  "cookies-introduction-4": "'App'",
  "cookies-introduction-5": ") utilizziamo i cookie o altri strumenti di tracciamento e tracciamento per raccogliere informazioni sull'uso che gli utenti fanno del Sito Web e dell'App.",
  "cookies-introduction-6": "In questo documento, scoprirai quali biscotti diamo al Cookie Monster, così come qualsiasi altra informazione correlata riguardante questo meraviglioso morso.",
  "cookies-definition": "1. Cos'è un cookie?",
  "cookies-definition-1": "Un cookie è un file che viene installato nel browser del tuo terminale (computer, cellulare, ecc.) per memorizzare e recuperare dati. I cookie possono avere una moltitudine di scopi, a seconda di ciò per cui sono stati impostati: ad esempio, salvare le preferenze, raccogliere statistiche sull'utilizzo, abilitare funzionalità tecniche, tra gli altri.",
  "cookies-responsible": "2. Chi è il responsabile?",
  "cookies-responsible-1": "I cookie possono avere una moltitudine di scopi, a seconda di ciò per cui sono stati configurati: ad esempio, salvare le preferenze, raccogliere statistiche sull'utilizzo, abilitare funzionalità tecniche, tra gli altri. Il responsabile del trattamento delle informazioni raccolte attraverso i cookie è CRUSH SOCIAL NETWORK, S.R.L. (CRUSH), con NIF B-72599830 e indirizzo in Calle Pintor Velázquez, 3, 1, 30530 Cieza, Murcia (Spagna).",
  "cookies-responsible-2": "In caso di domande relative all'uso dei cookie, scrivere una e-mail a",
  "cookies-responsible-3": "e ti risponderemo il prima possibile.",
  "cookies-data": "3. Quali dati vengono raccolti tramite i cookie?",
  "cookies-data-1": "Quando accedi al Sito Web o installi e utilizzi l'App, anche se non sei registrato, tramite i cookie vengono raccolte le seguenti informazioni:",
  "cookies-data-2": "Informazioni sul dispositivo: durante l'utilizzo del Sito Web o dell'App, raccogliamo informazioni sul dispositivo mobile da cui accedi alla piattaforma. Le informazioni che raccogliamo sono il modello del dispositivo, il sistema operativo e la versione, l'identificatore univoco del dispositivo e la rete.",
  "cookies-data-3": "Informazioni sulla tua posizione: otteniamo anche l'indirizzo IP, il fuso orario e l'eventuale provider di servizi mobili, che ci consente di ottenere una posizione generale.",
  "cookies-data-4": "Informazioni sulla navigazione dell'utente: raccogliamo informazioni sull'uso che fai del Sito Web e dell'App. Nello specifico, la frequenza di utilizzo, le sezioni visitate, l'utilizzo di specifiche funzioni, ecc.",
  "cookies-types": "4. Quali tipi di cookie esistono?",
  "cookies-types-1": "Secondo il loro scopo,",
  "cookies-types-2": "possiamo classificare i cookie tra i seguenti:",
  "cookies-types-3": "Cookie tecnici:",
  "cookies-types-4": "Questi cookie sono strettamente necessari per navigare sul Sito Web o sull'App e consentono funzioni di base come la navigazione da una sezione all'altra. Si occupano di aspetti tecnici come l'identificazione di profili o sessioni e la gestione delle preferenze dello strumento nell'uso, nell'interazione e nella posizione di accesso ai contenuti. Appartengono a questa categoria, per la loro natura tecnica, anche quei cookie che consentono la gestione, nel modo più efficiente possibile, degli spazi pubblicitari inseriti nel Sito Web e nell'App, senza raccogliere informazioni dagli utenti per finalità diverse, come ad esempio personalizzare annunci o altri tipi di contenuti.",
  "cookies-types-5": "Cookie statistici:",
  "cookies-types-6": "Questi cookie misurano e raccolgono informazioni statistiche sull'uso che l'utente fa del Sito Web o dell'App, come il tempo trascorso sulla piattaforma, il numero di visite o i contenuti visitati. Tali informazioni ci consentono di capire come gli utenti interagiscono con il Sito Web e l'App, valutare le performance, quali sezioni sono le più o meno visitate, compresa la quantificazione dell'impatto delle inserzioni introdotte sul Sito Web e sull'App (se applicabile). Avere queste informazioni può risolvere errori e migliorare il design del sistema e il contenuto offerto attraverso di esso.",
  "cookies-types-7": "Cookie di preferenza o personalizzazione:",
  "cookies-types-8": "I cookie di preferenza consentono al Sito Web o all'App di ricordare informazioni specifiche dell'utente in modo che possa accedere al servizio con determinate caratteristiche che possono differenziare la sua esperienza da quella di altri utenti. Ad esempio, la tua lingua preferita o la regione da cui accedi, il numero di risultati da visualizzare quando l'utente effettua una ricerca, l'aspetto o il contenuto del servizio a seconda del tipo di dispositivo da cui l'utente accede, ecc.",
  "cookies-types-9": "Cookie pubblicitari comportamentali:",
  "cookies-types-10": "Questi cookie consentono la gestione degli spazi pubblicitari che, se del caso, sono stati inseriti nel Sito Web o nell'App. Tuttavia, la gestione degli spazi pubblicitari avviene in base al profilo di navigazione dell'utente. Questi cookie memorizzano le informazioni di navigazione dell'utente ottenute attraverso l'osservazione continua delle sue abitudini di navigazione, sviluppando un profilo utente specifico, in modo che gli annunci che compaiono in questi spazi saranno personalizzati per l'utente in base a tali abitudini.",
  "cookies-types-11": "A seconda dell'ente che li gestisce:",
  "cookies-types-12": "Cookies propri:",
  "cookies-types-13": "sono quelli che vengono inviati al dispositivo dell'utente da un computer o dominio gestito dal responsabile del Sito Web o dell'App, in questo caso CRUSH, e dal quale viene erogato il servizio richiesto dall'utente.",
  "cookies-types-14": "Cookie di terze parti:",
  "cookies-types-15": "sono quelli che vengono inviati al dispositivo dell'utente da un computer o dominio non gestito dal responsabile del Sito Web o dell'App, ma da un'altra entità che elabora i dati ottenuti tramite i cookie.",
  "cookies-types-16": "A seconda del tempo in cui rimangono attivi sul dispositivo:",
  "cookies-types-17": "Cookie di sessione:",
  "cookies-types-18": "Sono progettati per raccogliere e conservare dati durante la navigazione dell'utente sul Sito Web o sull'App e vengono disattivati al termine della navigazione.",
  "cookies-types-19": "Cookie persistenti:",
  "cookies-types-20": "Questi memorizzano dati a cui è possibile accedere ed elaborare durante un periodo definito dalla persona responsabile del cookie. La loro vita può variare da minuti ad anni.",
  "cookies-used": "5. Quali cookie utilizziamo specificamente sulla nostra piattaforma?",
  "cookies-used-1": "Sulla nostra piattaforma utilizziamo i seguenti cookie:",
  "cookies-used-2": "Nome",
  "cookies-used-3": "Scopo",
  "cookies-used-4": "Cookie propri/di terze parti",
  "cookies-used-5": "Scadenza",
  "cookies-used-6": "14 mesi",
  "cookies-used-7": "Indefinita",
  "cookies-used-stats": "Statistiche",
  "cookies-used-technical": "Tecnica",
  "cookies-used-third-party-google": "Terza parte (Google LLC.)",
  "cookies-legitimation": "6. Qual è la base di legittimazione che ci consente di trattare i tuoi dati attraverso i cookie? Se sì, sono obbligatori?",
  "cookies-legitimation-1": "Le informazioni che raccogliamo attraverso i cookie e altri dispositivi di tracciamento si basano sul tuo consenso, che ti chiediamo quando accedi al Sito Web o quando installi l'App attraverso un pop-up informativo o secondo le modalità stabilite da Google (quando si tratta di Android) o Apple (quando si tratta di iOS).",
  "cookies-legitimation-2": "In tal senso, in ogni momento lei potrà revocare il suo consenso come spiegato nella sezione relativa alla revoca del consenso.",
  "cookies-legitimation-3": "I cookie tecnici non possono essere disabilitati, altrimenti il corretto funzionamento del Sito Web o dell'App sarebbe compromesso. In generale, vengono impostati solo in risposta ad azioni intraprese durante la richiesta di servizi da parte nostra, come l'impostazione delle preferenze in relazione ai cookie stessi, l'accesso o la compilazione di moduli. Nel caso in cui la disattivazione avvenga, ciò potrebbe comportare la limitazione dell'utilizzo di alcuni servizi del Sito Web o dell'App a causa dell'impossibilità di accedere a determinate funzionalità e pertanto la tua esperienza sarebbe meno soddisfacente.",
  "cookies-transfer": "7. A chi forniamo i tuoi dati personali? Esistono trasferimenti internazionali di dati?",
  "cookies-transfer-1": "Utilizziamo alcuni cookie di terze parti, ovvero quei cookie raccolti e gestiti da una terza parte, pertanto tali entità riceveranno i tuoi dati personali raccolti tramite i cookie per le finalità indicate per ciascun cookie. Questi tipi di cookie sono indicati nella tabella della sezione 5, come 'Cookie di terze parti'. Questa tabella identifica la terza parte che gestisce ciascun cookie.",
  "cookies-transfer-2": "Puoi conoscere i trasferimenti verso paesi terzi che, se del caso, vengono effettuati dalle terze parti identificate nella presente Politica sui Cookie nelle loro politiche corrispondenti.",
  "cookies-consent": "8. Come posso accettare, negare o revocare il consenso all'uso dei cookie su questo sito web?",
  "cookies-consent-1": "Quando accedi per la prima volta al nostro Sito Web o alla nostra App, ti verrà mostrata una finestra in cui puoi indicare se accetti i cookie o se desideri configurarli o rifiutarli, ad eccezione di quelli strettamente necessari. Allo stesso modo, puoi revocare in qualsiasi momento il consenso precedentemente concesso.",
  "cookies-consent-2": "Se desideri revocare il tuo consenso relativo alla Cookies Policy in qualsiasi momento, hai due possibilità: (i) revocare il tuo consenso attraverso i pulsanti abilitati a tale scopo nella presente Cookies Policy; o (ii) eliminare i cookie memorizzati sul dispositivo attraverso le impostazioni e le configurazioni del proprio browser Internet.",
  "cookies-consent-3": "Tieni presente che, se accetti i cookie di terze parti, devi eliminarli dalle opzioni del browser o dal sistema offerto dalla terza parte stessa.",
  "cookies-consent-4": "È possibile espandere le informazioni su come disabilitare i cookie, a seconda del browser, nei seguenti collegamenti:",
  "cookies-consent-5": "; per iOS:",
  "cookies-rights": "9. Quali sono i diritti che puoi esercitare in qualità di interessato?",
  "cookies-rights-1": "I diritti che hai in qualità di interessato in relazione alla protezione dei tuoi dati personali sono indicati nella sezione 'Quali sono i diritti che puoi esercitare in qualità di interessato?' all'interno del",
  "cookies-rights-2": "Politica sulla Riservatezza",
  "cookies-modifications": "10. Modifiche a questa politica",
  "cookies-modifications-1": "Di tanto in tanto potremmo aggiornare la Politica sui Cookie della nostra piattaforma. Per questo motivo, ti consigliamo di rivedere questa politica ogni volta che accedi al nostro Sito Web o alla nostra App per essere adeguatamente informato su come e per cosa utilizziamo i cookie."
}
