{
  "404-title": "Crush - Não encontrado",
  "404-not-found-1": "Não encontrado",
  "404-not-found-2": "Desculpe, a página que você está procurando não está disponível.",
  "navbar-mission": "Missão",
  "navbar-download": "Baixar",
  "footer-follow": "Siga-nos",
  "footer-follow-1": "Instagram",
  "footer-follow-2": "TikTok",
  "footer-follow-3": "Twitter",
  "footer-follow-4": "LinkdIn",
  "footer-docs": "Documentos",
  "footer-docs-1": "Missão",
  "footer-docs-2": "Aviso legal",
  "footer-docs-3": "Privacidade",
  "footer-docs-4": "Cookies",
  "footer-docs-5": "Diretrizes",
  "footer-contact": "Contate-nos",
  "footer-contact-1": "Suporte",
  "footer-contact-2": "Trabalhos",
  "footer-contact-3": "Investidores",
  "home-title": "Crush - Onde a mídia encontra o platônico",
  "home-hero-slogan-1": "Where media",
  "home-hero-slogan-2": "meets platonic ™",
  "home-concept-1": "Encontre suas pessoas",
  "home-concept-2": "Conecte-se com pessoas como você nas diferentes etapas da sua vida.",
  "home-concept-3": "Vibes legais",
  "home-concept-4": "Compartilhe boas vibrações e momentos reais. Nada de toxicidade ou pretensão.",
  "home-attributes-1": "Aesthetic",
  "home-attributes-2": "Promovemos a publicação de conteúdo aesthetic e de alta qualidade; a web já está inundada de fotos de gatos.",
  "home-attributes-3": "Stalker",
  "home-attributes-4": "Seus amigos são as estrelas. Tire algumas rajadas de fotos quando eles não estão preparados e poste alguns GIFs em suas contas.",
  "home-attributes-5": "Tópicos",
  "home-attributes-6": "Participe de discussões vibrantes que abrangem desde os tópicos mais urgentes de hoje até as tendências que moldarão o amanhã.",
  "home-attributes-7": "Gêmeos",
  "home-attributes-8": "Conecte-se com indivíduos que compartilham características faciais semelhantes às suas, ampliando sua rede social de maneira completamente nova e divertida.",
  "home-attributes-9": "Segmentado por idade",
  "home-attributes-10": "Ao agrupar os usuários por idade, semelhante às classes do ensino médio, as conexões são limitadas a indivíduos da mesma geração para uma interação mais segura e eficiente.",
  "home-attributes-11": "Crush",
  "home-attributes-12": "Não é um aplicativo de namoro — apenas amigos e relacionamentos relaxados. Reviva a nostalgia das conexões do ensino médio em um ambiente digital.",
  "home-partners": "Powered By",
  "mission-title": "Crush - Missão",
  "mission-date": "Última modificação: 8 dezembro, 2023",
  "mission-header": "Missão e valores",
  "mission-introduction": "No coração do Crush pulsa algo mais do que apenas uma simples rede social; é um universo tecido com valores éticos, uma filosofia e um propósito. Este documento é a jornada através dos alicerces da nossa visão, desde o nascimento da ideia até ao esboço meticuloso da criação que estamos a moldar.",
  "mission-origin": "A origem",
  "mission-origin-1": "A origem do Crush remonta a uma semente plantada muito antes de seu desenvolvimento concreto em 2020 e seu lançamento em 2023. Nossa visão de mesclar redes sociais com o toque distintivo do Crush foi concebida em 2016, inspirada em 'A Rede Social', a notável obra cinematográfica reconhecida por Quentin Tarantino como uma joia da década passada. Este filme, que conta a história dos jovens fundadores do Facebook, especialmente sua figura mais reconhecida, Mark Zuckerberg — o pai das redes sociais, nos proporcionou uma perspectiva esclarecedora.",
  "mission-origin-2": "No Crush, acreditamos fervorosamente que o cerne das redes sociais atuais reside no amor platônico. Se você teve a oportunidade de assistir a este filme, pode ter percebido as críticas de Zuckerberg e Eduardo Saverin aos aplicativos de encontros. Em uma cena-chave, por volta dos 30 minutos, durante o processo dos irmãos Winklevoss por suposto roubo de ideia, Saverin menciona:",
  "mission-origin-3": "\"It really hadn't much to do with the Winklevoss' dating site\".",
  "mission-origin-4": "É curioso, pois alguns minutos depois, por volta dos 36 minutos e 5 segundos, vemos Mark implementando elementos como estado de relacionamento e interesses em seu projeto:",
  "mission-origin-5": "\"Relationship status, interests... This is what drives life in college. Are you having sex or aren't you? That's why people take classes, sit where they sit, and do what they do. It's the center, you know? That's what The Facebook is going to be.\".",
  "mission-origin-6": "Finalmente, na cena final, testemunhamos um Zuckerberg apaixonado enviando um pedido de amizade para seu crush.",
  "mission-origin-7": "Este filme nos apresenta uma pergunta intrigante:",
  "mission-origin-8": "As redes sociais são na verdade aplicativos de namoro disfarçados?",
  "mission-purpose": "Nosso propósito",
  "mission-purpose-1": "Não há uma resposta definitiva para a pergunta levantada na seção anterior. Talvez sim, talvez não... no final, isso depende dos valores éticos e culturais, das intenções e da educação do usuário final. O que sabemos com certeza é que isso abre a porta para problemas reais e muito sérios que, ao contrário do mundo real, podem de fato ser resolvidos.",
  "mission-purpose-2": "Seja pelas normas pré-estabelecidas pela sociedade ou pelo anonimato que leva a comportamentos inadequados online, o amor no mundo digital, seja ele platônico e genuíno nas redes sociais ou mais romântico nos aplicativos de relacionamento, infelizmente tem sido sexualizado nos últimos anos. Por que algo tão bonito e fundamental em nossas vidas está sendo distorcido nesse universo paralelo?",
  "mission-purpose-3": "Vamos ser claros e completamente transparentes. Na Espanha, nosso país de origem, segundo dados e estatísticas verificáveis,",
  "mission-purpose-4": "7 em cada 10 menores de 15 anos têm um dispositivo móvel.",
  "mission-purpose-5": "Isso significa que cerca de 70% dos menores têm acesso irrestrito a um mundo virtual onde a interação com adultos não foi regulada ou restrita, expondo-os a riscos como assédio, bullying ou pedofilia, entre outros.",
  "mission-purpose-6": "Atualmente, independentemente da rede social em que você se inscreve, é comum encontrar pessoas vendendo conteúdo inapropriado ou compartilhando qualquer tipo de conteúdo inadequado. Quanto mais o tempo passa, mais repugnante é a situação.",
  "mission-purpose-7": "Nosso objetivo é eliminar a sexualização e a toxicidade da rede, abordando diretamente sua origem.",
  "mission-purpose-8": "Crush é proposto como uma redefinição das redes sociais a partir de uma perspectiva diferente, focando em relacionamentos platônicos.",
  "mission-purpose-9": "Não oferecemos um sistema de namoro ou a criação de relacionamentos românticos/sérios, pois isso contradiz nossas",
  "mission-purpose-10": "diretrizes da comunidade.",
  "mission-purpose-11": "Em vez disso, oferecemos o que foi idealizado no filme 'A Rede Social', mas com uma solução para o problema e uma abordagem mais descontraída.",
  "mission-purpose-12": "Nossa missão vai além do típico \"compartilhar momentos reais\" ou \"ajudamos você a se conectar\". Além de acompanhar o usuário nas várias etapas de sua vida, estamos dedicados a resolver os problemas mencionados anteriormente. Em nossa plataforma, no momento da redação deste documento, já é impossível que um menor interaja com um adulto (e vice-versa), não apenas para fechar as portas para essas questões éticas, mas também para incentivar a criação de laços entre pessoas da mesma geração, com uma mentalidade semelhante. Planejamos apresentar soluções mais eficazes na próxima década de desenvolvimento; esta é nossa promessa.",
  "guidelines-title": "Crush - Diretrizes",
  "guidelines-date": "Última modificação: 4 abril, 2024",
  "guidelines-header": "Diretrizes da comunidade",
  "guidelines-introduction-1": "No Crush, trata-se de estabelecer conexões platônicas, compartilhar momentos autênticos da vida com amigos, socializar e criar novas amizades alinhadas com sua mentalidade. Acreditamos firmemente na liberdade de expressão, mas também valorizamos a capacidade de distinguir entre o certo e o errado. Este documento detalha os padrões de nossa rede social, essenciais para cumprir",
  "guidelines-introduction-2": "nosso propósito principal.",
  "guidelines-introduction-3": "Esperamos um comportamento adequado, cuidado com o conteúdo, autenticidade, humildade e, acima de tudo, responsabilidade. Qualquer violação de nossos princípios fundamentais pode resultar na suspensão permanente de sua conta, sem possibilidade de se registrar novamente em nosso sistema.",
  "guidelines-introduction-4": "Assim como na vida real, há consequências para as ações. Por favor, respeite nossas regras e não use nosso sistema de maneira contrária à lei. Queremos enfatizar nossa seriedade e rigor; um tom brincalhão não é bem-vindo nesse contexto.",
  "guidelines-dating": "Anti-encontros",
  "guidelines-dating-1": "Crush é uma combinação de redes sociais adequada para todas as pessoas",
  "guidelines-dating-2": "com 15 anos ou mais,",
  "guidelines-dating-3": "estejam ou não em um relacionamento romântico. Não confunda amigos e relacionamentos platônicos (opcionais) com relacionamentos românticos ou compromissos reais.",
  "guidelines-dating-4": "A seguir, detalhamos uma série de comportamentos que deves adotar e respeitar para permanecer na nossa plataforma:",
  "guidelines-dating-5": "Não utilize nosso serviço para buscar ou solicitar encontros românticos.",
  "guidelines-dating-6": "Se o amor chegar à tua vida, que não seja através de uma tela.",
  "guidelines-dating-7": "Não publique coisas sujas.",
  "guidelines-dating-8": "Não assedies ou incomodes nenhum usuário.",
  "guidelines-dating-9": "Não mintas sobre a tua idade ou informações pessoais, muito menos faças-se passar por outra pessoa.",
  "guidelines-dating-10": "É um crime muito grave que poderia resultar em ações legais contra ti.",
  "guidelines-content": "Aesthetic",
  "guidelines-content-1": "Para nós, qualquer forma de software é arte e a arte deve ser respeitada. Em uma rede social, a qualidade da plataforma se reflete no conteúdo gerado pelos usuários. No nosso caso, incentivamos a criatividade e a inspiração. Pedimos que publique mantendo um padrão mínimo de qualidade.",
  "guidelines-content-2": "Não poste conteúdo visualmente ruim.",
  "guidelines-content-3": "Faça um esforço.",
  "guidelines-content-4": "Spam é remoção imediata.",
  "guidelines-content-5": "Se você deseja promover o seu Instagram, use as ferramentas que disponibilizamos para isso.",
  "guidelines-content-6": "Não compartilhe conteúdo que viole a propriedade intelectual de terceiros.",
  "guidelines-content-7": "Por favor, evite postar fotos de gatinhos;",
  "guidelines-content-8": "a internet já está cheia delas.",
  "guidelines-content-9": "Não faças papel de ridículo ao publicar nudez desnecessária.",
  "guidelines-content-10": "Estamos cansados de fotos sem camisa.",
  "guidelines-vibes": "Apenas boas vibrações",
  "guidelines-vibes-1": "Na Crush, não toleramos comportamentos inapropriados. Assim como cuida da sua imagem na vida real, pedimos que seja responsável e mantenha essa integridade online. Demonstre cortesia e respeito tanto para os usuários de nossa comunidade quanto para os criadores da plataforma.",
  "guidelines-vibes-2": "A exploração infantil, a extorsão sexual ou qualquer outro tipo de abuso, em qualquer forma, são estritamente proibidos.",
  "guidelines-vibes-3": "Nosso compromisso contra o suicídio e a automutilação é absoluto.",
  "guidelines-vibes-4": "Qualquer conteúdo que promova, encoraje ou glorifique essas ações resultará em suspensão permanente.",
  "guidelines-vibes-5": "Levamos a legalidade a sério.",
  "guidelines-vibes-6": "Evite compartilhar conteúdo que promova atividades ilegais, assim como qualquer conteúdo relacionado a armas ou drogas.",
  "guidelines-vibes-7": "Conteúdo ofensivo ou violento não tem lugar em nossa plataforma e não é permitido.",
  "guidelines-vibes-8": "O modo Stalker destina-se à diversão entre amigos.",
  "guidelines-vibes-9": "No entanto, mesmo que o conteúdo necessite de aprovação do usuário (ou usuários) no GIF, evite compartilhar fotos dos seus amigos sem o consentimento prévio deles.",
  "legal-title": "Crush - Aviso legal",
  "legal-date": "Última modificação: 4 abril, 2024",
  "legal-header": "Aviso legal",
  "legal-introduction-1": "Cada aplicativo é um universo e todos os universos estão sujeitos a regras. Neste documento, você encontrará o aviso legal e os termos gerais de uso de nossa plataforma, complementados pelos definidos em nossas",
  "legal-introduction-2": "diretrizes da comunidade.",
  "legal-owner": "1. Identificação do titular",
  "legal-owner-1": "O website www.crushsocialnetwork.com (doravante, o",
  "legal-owner-2": "'Website'",
  "legal-owner-3": ") e a aplicação móvel para Smartphone com o nome 'Crush' (doravante, o",
  "legal-owner-4": "'App'",
  "legal-owner-5": ") são propriedade de CRUSH SOCIAL NETWORK, Lda., provida de NIF: B-72599830 e inscrita no Registo Mercantil de València com os seguintes dados de registo: T. 3606, F.75, I.1, H. MU-108656, e cujos dados de contato são:",
  "legal-owner-6": "Sede social:",
  "legal-owner-7": "Calle Pintor Velázquez, 3, 1, 30530 Cieza, Murcia (Espanha)",
  "legal-owner-8": "Email de contato:",
  "legal-area": "2. Âmbito de aplicação",
  "legal-area-1": "As presentes Condições Gerais de Uso do Website (doravante, as",
  "legal-area-2": "'Condições de Uso'",
  "legal-area-3": ") recolhem os termos e condições que regulam o acesso, navegação e utilização do Website e da App.",
  "legal-area-4": "Neste sentido, o acesso, utilização e navegação no Website e na App confere a condição de usuário(s) (doravante, o",
  "legal-area-5": "'Usuário'",
  "legal-area-6": "ou os",
  "legal-area-7": "'Usuários'",
  "legal-area-8": "), e implica a aceitação de todas e cada uma das presentes Condições de Uso. O Usuário está plenamente consciente de que a mera navegação através do Website ou App implica a aceitaçao destas Condições de Utilização e, portanto, se o Usuário não concordar com eles, não deve utilizar o Website ou esta App.",
  "legal-area-9": "O Webite e a App destinam-se principalmente a Usuários residentes em Espanha. Crush não garante que o Website ou App cumpram as leis de outros países, total ou parcialmente. Se o Usuário residir ou tiver o seu domicílio noutro local e decide aceder e/ou navegar no Website ou aceder ou utilizar a App, fá-lo-á sob a sua própia responsabilidade e deverá assegurar-se de que tal acesso, navegação ou utilização está em conformidade com a legislação local que lhe é aplicável, não assumindo a Crush qualquer responsabilidade que possa resultar desse acesso ou uso.",
  "legal-area-10": "Crush pode, a qualquer momento, e sem aviso prévio, modificar as presentes Condições de Uso, as quais serão publicadas à medida que essas modificações ocorrerem.",
  "legal-conditions": "3. Condições de acesso e uso",
  "legal-conditions-1": "O acesso ao Website ou à App pelo Utilizador, em regra, é gratuito. No caso de existirem funcionalidades ou serviços reservados a determinados Usuários ou que seja necessário pagar um preço, estes serão devidamente identificados na Website ou no App.",
  "legal-conditions-2": "O acesso ao Website ou à App é feito sob a exclusiva responsabilidade do Utilizador, e não implica qualquer tipo de relação comercial entre a Crush e o Usuário. O Utilizador é responsável por garantir que as informações e conteúdos incluídos no Website ou App cumprem os seus requisitos específicos.",
  "legal-conditions-3": "O usuário concorda em:",
  "legal-conditions-4": "Acesso e utilização tanto do Website como da App de acordo com a boa-fé e de acordo com os presentes Termos de Utilização, a Lei, a moral e a ordem pública, com o requisito adicional de atingir a idade mínima de 15 anos.",
  "legal-conditions-5": "Na Crush, certificamos que cumprimos a legislação aplicável sobre segurança infantil e levamos a sério o material de abuso sexual infantil. Tomamos medidas adequadas, como a remoção desse material após tomarmos conhecimento efetivo de sua existência, de acordo com nossos padrões e as leis relevantes.",
  "legal-conditions-6": "Forneça informações verdadeiras, atuais e legais nos formulários oferecidos tanto no Website como na App. Em qualquer caso, o Utilizador notificará imediatamente a Crush sobre qualquer facto que permita o uso indevido das informações registadas nos referidos formulários, tais como, mas não só, roubo, perda ou acesso não autorizado a identificadores e/ou senhas, a fim de proceder ao seu cancelamento imediato.",
  "legal-conditions-7": "Não tomar qualquer ação no Webste ou na App que possa causar danos ou alterações nos conteúdos, programas ou sistemas do Website ou da App, incluindo a introdução de vírus de computador, a instalação de robôs ou qualquer software ou arquivo nocivo, defeituoso ou, em última análise, que possa causar danos nos nossos sistemas informáticos.",
  "legal-conditions-8": "Em qualquer caso, o Utilizador será responsável por danos que possam ser causados tanto à Crush como a terceiros e a Crush poderá adotar as medidas técnicas, legais e quaisquer outras medidas que considere adequadas para prevenir, mitigar ou parar as consequências dos comportamentos anteriores proibidos e exigir o máximo de responsabilidades que considere adequadas.",
  "legal-property": "4. Propriedade intelectual e industrial",
  "legal-property-1": "Todos os conteúdos do Website e da App, incluindo, sem limitação, textos, imagens, fotografias, vídeos, gráficos, sinais distintivos de qualquer tipo, ícones, interfaces, bem como software, código fonte, desenhos, arquitetura, apresentação, arranjo e classificação dos conteúdos e qualquer outro elemento presente no Website ou na App que possa estar sujeito a direitos de propriedade intelectual são propriedade exclusiva da Crush ou terceiros que tenham  licenciado, autorizado ou consentido para a sua utilização no Website ou na App.",
  "legal-property-2": "Neste sentido, quando o Utilizador acede, navega e utiliza o Website ou acede ou utiliza a App, não são concedidos direitos de exploração que existam ou possam existir sobre todos ou parte destes, a Crush reservando todos estes direitos. O Utilizador só pode ver os elementos do Website e da App e usá-los na medida estritamente necessária para uma utilização correta destes. Em particular, é proibido utilizar ou revender para fins comerciais qualquer material ou conteúdo presente no Website ou na App sem a autorização prévia da Crush.",
  "legal-property-3": "A Crush reserva-se o direito de modificar, a qualquer momento, e sem aviso prévio, a apresentação e configuração do Website ou da App e os conteúdos e serviços que possam ser incorporados nos mesmos. O Usuário reconhece e aceita que a qualquer momento a Crush pode interromper, desativar e/ou cancelar qualquer um destes elementos que estejam integrados no Website e na App ou no acesso aos mesmos.",
  "legal-property-4": "Se o Utilizador considerar que algum dos conteúdos do Website ou da App constitui uma violação dos direitos de proteção da propriedade intelectual, deve notificar imediatamente a Crush através dos dados de contacto da secção 1 (Identificação do titular) das presentes Condições de Utilização.",
  "legal-disclaimer": "5. Exclusão de garantias e responsabilidade",
  "legal-disclaimer-1": "As informações publicadas no Website ou na App podem não ser exaustivas ou não completamente atualizadas, pelo que a Crush não assume qualquer responsabilidade derivada da falta de integridade, atualização ou precisão dos dados e informações contidas nas diferentes páginas que fazem parte do Website ou da App.",
  "legal-disclaimer-2": "A Crush não garante que o acesso à App ou Website seja ininterrupto ou isento de erros. Da mesma forma, a Crush não garante que o conteúdo ou software presente no Website ou na App não cause danos no sistema informático do Usuário (software e hardware). A Crush não será responsável por perdas, danos ou perdas de qualquer tipo decorrentes do acesso, navegação e utilização do Website ou da App, incluindo, mas não se limitando a, as causadas a sistemas informáticos ou as causadas pela introdução de vírus.",
  "legal-disclaimer-3": "A Crush, da mesma forma, fica exonerada de qualquer responsabilidade derivada do uso indevido do Website ou do App pelo Usuário.",
  "legal-links": "6. Links",
  "legal-links-1": "Informa-se que tanto o Website quanto o App podem encontrar links, banners, botões, diretórios e buscadores, entre outros, que permitem aos Usuários acessar outros sites ou apps pertencentes e/ou administrados por terceiros e que, por isso, eles estão fora do controle do Crush, que não pode assumir qualquer responsabilidade pelo conteúdo que aparece em tais páginas ou aplicativos.",
  "legal-links-2": "Caso considere que o referido conteúdo é impróprio ou contrário aos propósitos do Crush, por favor comunique-nos através dos meios de contacto acima referidos, para que possamos adotar as medidas cabíveis.",
  "legal-jurisdiction": "7. Lesgilação aplicável e jurisdição",
  "legal-jurisdiction-1": "Estas Condições de Uso e sua execução estão sujeitas à lei espanhola.",
  "legal-jurisdiction-2": "Se surgir qualquer disputa sobre a interpretação ou aplicação destes Termos de Uso, as Partes negociarão de boa fé para tentar resolver tal discrepância ou reclamação. No entanto, caso a discrepância ou reclamação não seja resolvida, as Partes se submeterão aos tribunais ou tribunais correspondentes de acordo com a lei.",
  "privacy-title": "Crush - Privacidade",
  "privacy-date": "Última modificação: 8 dezembro, 2023",
  "privacy-header": "Política de privacidade",
  "privacy-introduction-1": "Na Crush. Estamos empenhados em garantir que os seus dados pessoais estão protegidos e não são utilizados para fins diferentes dos indicados nesta Política de Privacidade. Por esta razão, nesta secção informamos os utilizadores e interessados de tudo o que se relacione com o tratamento dos seus dados pessoais, cumprindo assim com a normativa de proteção de dados aplicável no nosso país: Regulamento Geral de Proteção de Dados (UE) 2016/679, de 27 de abril sobre a proteção das pessoas físicas no que diz respeito ao tratamento de dados pessoais e à livre circulação desses dados (doravante,",
  "privacy-introduction-2": "'RGPD'",
  "privacy-introduction-3": ") e Lei Orgânica 3/2018, de 5 de dezembro, Proteção de Dados Pessoais e garantia de direitos digitais (doravante,",
  "privacy-introduction-4": "'LOPDGDD'",
  "privacy-introduction-5": "Esta Política de Privacidade é aplicável ao processamento de dados que CRUSH SOCIAL NETWORK, Lda. realizadas tanto através do website: www.crushsocialnetwork.com (doravante, o 'Website'), como através do aplicativo móvel para Smartphone com o nome 'Crush' (doravante, o 'App') e/ou os indicados. Recomendamos que você leia atentamente antes de usar o Website ou o App e fornecer seus dados através deles. Não hesite em nos fazer qualquer pergunta a esse respeito via e-mail:",
  "privacy-index": "Índice de conteúdos",
  "privacy-index-1": "Nesta Política encontrará toda a informação relacionada com o tratamento dos seus dados pessoais e os direitos que pode exercer para manter o controlo sobre os mesmos. Nesse sentido, você encontrará informações sobre:",
  "privacy-index-2": "Quem é o responsável pelo tratamento dos seus dados.",
  "privacy-index-3": "Quais requisitos você deve atender para nos fornecer seus dados pessoais.",
  "privacy-index-4": "Que tratamento de dados realizamos através da Aplicação e quais são as suas principais características, explicando:",
  "privacy-index-5": "Quais dados coletamos e quais são as formas de coleta.",
  "privacy-index-6": "Para que finalidades recolhemos os dados que solicitamos.",
  "privacy-index-7": "Qual a legitimidade para o seu tratamento.",
  "privacy-index-8": "Quanto tempo os guardamos?",
  "privacy-index-9": "Para quais destinatários seus dados são comunicados.",
  "privacy-index-10": "Existência de transferências internacionais dos seus dados.",
  "privacy-index-11": "Quais são os seus direitos e como pode exercê-los?",
  "privacy-index-12": "Como protegemos suas informações pessoais.",
  "privacy-index-13": "Alterações a esta política.",
  "privacy-controller": "1. Quem é o responsável pelo tratamento dos seus dados pessoais?",
  "privacy-controller-1": "Os seus dados pessoais serão tratados pela empresa CRUSH SOCIAL NETWORK, Lda. (",
  "privacy-controller-2": "'CRUSH'",
  "privacy-controller-3": "), com NIF B-72599830 e cujos contactos são os seguintes:",
  "privacy-controller-4": "Endereço:",
  "privacy-controller-5": "Calle Pintor Velázquez, 3, 1, 30530 Cieza, Murcia (Espanha)",
  "privacy-controller-6": "Email de contato:",
  "privacy-dpo": "1.1. Nosso Encarregado da Proteção de Dados:",
  "privacy-dpo-1": "A partir do CRUSH, colocamos à sua disposição os dados de contacto do nosso Encarregado da Proteção de Dados, a quem poderá dirigir todas as questões que possa ter relativamente a esta Política de Privacidade ou ao tratamento dos seus dados pessoais.",
  "privacy-dpo-2": "Email:",
  "privacy-requirements": "2. Que requisitos deve cumprir para nos fornecer os seus dados pessoais?",
  "privacy-requirements-1": "2.1. Idade minima. Para nos fornecer seus dados pessoais e usar nosso Website e nosso App, você deve ser maior de 15 anos.",
  "privacy-requirements-2": "2.2. Veracidade. Ao nos fornecer seus dados para utilização de nossos serviços, você garante que os dados e informações fornecidos são reais, verdadeiros, atualizados e também pertencem a você e não a terceiros.",
  "privacy-requirements-3": "Além disso, você deve nos notificar de qualquer alteração que ocorra nos dados fornecidos, respondendo em qualquer caso pela veracidade e precisão dos dados fornecidos em todos os momentos.",
  "privacy-requirements-4": "2.3. Controle de idade e veracidade. A partir do CRUSH reservamo-nos o direito de verificar a sua idade e dados identificativos, se necessário, mesmo exigindo documento oficial ou procedimento equivalente e, em caso de deteção de fraude que se verifique ou suspeite de ser menor de idade indicado, de eliminar, temporariamente desativar e/ou cancelar sua conta.",
  "privacy-processing": "3. Que tratamento de dados realizamos através do Website e da App e quais são as suas principais características?",
  "privacy-processing-1": "Abaixo, explicamos como tratamos suas informações pessoais e fornecemos, detalhadamente, todas as informações relevantes sobre sua privacidade:",
  "privacy-contact": "3.1. Quando você entra em contato conosco através de nossos canais (formulário de contato, chat, e-mail)",
  "privacy-contact-1": "Quais são as formas de coletar os dados?",
  "privacy-contact-2": "Formulário de contato.",
  "privacy-contact-3": "Chat.",
  "privacy-contact-4": "Enviando e-mails para",
  "privacy-contact-5": "ou outros endereços de e-mail do CRUSH.",
  "privacy-contact-6": "Que dados recolhemos?",
  "privacy-contact-7": "Identificação e informações de contato.",
  "privacy-contact-8": "Recolhemos os seus dados de identificação (nome e apelido) e endereço de correio eletrónico, bem como qualquer outra informação que inclua voluntariamente nas comunicações que nos envia.",
  "privacy-contact-9": "Podemos solicitar informações adicionais de você, se necessário, para atender à sua solicitação ou exigência.",
  "privacy-contact-10": "Quais são as finalidades do tratamento dos seus dados pessoais?",
  "privacy-contact-11": "Responda aos seus pedidos.",
  "privacy-contact-12": "A principal finalidade do tratamento destes dados será responder aos seus pedidos, esclarecer as suas dúvidas e/ou fornecer-lhe as informações solicitadas, bem como, se for o caso, dar seguimento aos seus pedidos.",
  "privacy-contact-13": "Melhore o atendimento ao cliente.",
  "privacy-contact-14": "Todas as informações derivadas de dúvidas, consultas e conselhos oferecidos às partes interessadas, bem como a forma como as solicitações são resolvidas, permitem-nos saber como prestamos o nosso próprio serviço ao cliente, permitindo-nos melhorar a sua qualidade.",
  "privacy-contact-15": "Da mesma forma, toda a informação recolhida, após o período de conservação abaixo indicado, é anonimizada e utilizada com a finalidade de poder analisar as perguntas mais frequentes através do chat e poder automatizar as mais frequentes, preparar FAQs ou guardá-las para fins estatísticos para desenvolver estratégias de negócios.",
  "privacy-contact-16": "Qual é a base legal que nos permite tratar os seus dados? O fornecimento destes dados é obrigatório?",
  "privacy-contact-17": "Consentimento.",
  "privacy-contact-18": "Os dados fornecidos para as finalidades anteriores serão tratados com base no seu consentimento, concedido quando nos contacta voluntariamente através dos meios que lhe são disponibilizados para solicitar informações ou efetuar um pedido.",
  "privacy-contact-19": "Toda a informação recolhida pelo departamento de apoio ao cliente será tratada para efeitos estatísticos que nos ajudarão a melhorar a qualidade do serviço prestado ao cliente. Esta finalidade é considerada compatível com a inicial.",
  "privacy-contact-20": "As informações que você deve fornecer serão indicadas com um asterisco ou similar. Sem esta informação não seria possível atender às suas dúvidas ou solicitações.",
  "privacy-contact-21": "Por quanto tempo mantemos suas informações?",
  "privacy-contact-22": "Processaremos todas as suas informações pessoais durante o tempo em que suas solicitações estiverem sendo processadas e, se necessário, para acompanhá-las. Findo este prazo, a CRUSH manterá, bloqueada, referidas informações durante os prazos previstos na legislação para atender a eventuais responsabilidades e demonstrar o cumprimento de nossas obrigações. A partir deste momento, o CRUSH apenas tratará a informação de forma anónima, pelo que não será possível relacionar a informação estatística com os utilizadores específicos a que se refere.",
  "privacy-contact-23": "A quem damos suas informações pessoais?",
  "privacy-contact-24": "Não fazemos nenhuma transferência adicional para realizar este tratamento além dos indicados, em geral, no ponto",
  "privacy-contact-25": "4. A quem damos suas informações pessoais?",
  "privacy-contact-26": "Nesse sentido, alguns canais pelos quais você pode entrar em contato conosco são geridos por prestadores de serviços, que atuam como Gestores de Tratamento. Você encontrará mais informações sobre como esses prestadores de serviços atuam no ponto 4, mencionado acima.",
  "privacy-app-use": "3.2. Quando você se registra e usa nosso App 'Crush'",
  "privacy-app-use-1": "Quais são as formas de coletar os dados?",
  "privacy-app-use-2": "Ficha de inscrição na nossa App e utilização dos seus diferentes espaços.",
  "privacy-app-use-3": "Que dados recolhemos?",
  "privacy-app-use-4": "Dados básicos para cadastro:",
  "privacy-app-use-5": "Para se registrar em nosso App e usar os serviços, você precisará se registrar em nosso App e criar um",
  "privacy-app-use-6": "'perfil'.",
  "privacy-app-use-7": "Durante o processo de registo solicitamos-lhe, enquanto utilizador, os seguintes dados:",
  "privacy-app-use-8": "Nome de usuário.",
  "privacy-app-use-9": "Nome completo.",
  "privacy-app-use-10": "E-mail.",
  "privacy-app-use-11": "Data de nascimento.",
  "privacy-app-use-12": "Dados adicionais:",
  "privacy-app-use-13": "Além do acima, nosso App permite que você compartilhe voluntariamente informações relacionadas a:",
  "privacy-app-use-14": "Gênero.",
  "privacy-app-use-15": "Orientação sexual.",
  "privacy-app-use-16": "Interesses.",
  "privacy-app-use-17": "País, cidade ou região.",
  "privacy-app-use-18": "Descrição sobre você.",
  "privacy-app-use-19": "Profissão e estudos.",
  "privacy-app-use-20": "Gostos musicais.",
  "privacy-app-use-21": "Melhor amigo.",
  "privacy-app-use-22": "Se você autorizar, sua geolocalização precisa, que, se você permitiu, é realizada em segundo plano.",
  "privacy-app-use-23": "Dados de uso: podem ser coletadas informações sobre suas interações com outros usuários (chats com outros usuários, usuários com os quais você se conecta e interage, tempo, frequência de interação ou outras características de suas interações).",
  "privacy-app-use-24": "Alguns dos dados que pode fornecer voluntariamente são considerados sensíveis, uma vez que estão relacionados com a sua orientação sexual. Deve ter em conta que, se decidir incluir tal informação, o CRUSH irá processá-la juntamente com o resto da informação para cumprir as finalidades indicadas abaixo. Da mesma forma, esta informação pode ser compartilhada com o restante dos usuários (pode ser tornada pública), de modo que o uso não autorizado do App por outros usuários pode causar o vazamento desses dados. O CRUSH não se responsabiliza pelo uso indevido de dados por outros usuários.",
  "privacy-app-use-25": "Informamos também que a CRUSH utiliza os dados acima com o objetivo de criar perfis para recomendar conteúdo que possa ser do seu interesse e com as características pessoais que você inclui sobre si mesmo, conforme explicado abaixo.",
  "privacy-app-use-26": "Além disso, se você autorizar, teremos acesso a:",
  "privacy-app-use-27": "Câmera (imagens): conceder permissão para câmera permite que o usuário carregue qualquer imagem diretamente no App.",
  "privacy-app-use-28": "Galeria de fotos (imagens): conceder acesso à galeria de fotos permite que o usuário carregue qualquer imagem de sua galeria de fotos.",
  "privacy-app-use-29": "Microfone (áudio): A concessão de acesso ao microfone permite que o usuário use o gravador de voz do dispositivo diretamente do App.",
  "privacy-app-use-30": "Conteúdo postado por você:",
  "privacy-app-use-31": "Ao utilizar a nossa App, tem a possibilidade de interagir com outros utilizadores através da nossa comunidade, podendo carregar publicações com conteúdos audiovisuais (fotos, áudios, etc., caso tenha concedido as respetivas permissões) e adicionar comentários com as suas opiniões, levantar dúvidas e resolver preocupações de outros usuários.",
  "privacy-app-use-32": "Também oferecemos um empolgante modo 'Stalker', onde seus amigos podem capturar GIFs de você com o seu consentimento. No entanto, a responsabilidade de capturar e solicitar a publicação desses GIFs em seu perfil recai sobre o usuário que inicia a solicitação. O GIF não será publicado em seu perfil até que você aceite a solicitação. Além disso, todos os colaboradores podem remover permanentemente a contribuição, seja excluindo-a diretamente ou recusando a solicitação.",
  "privacy-app-use-33": "O CRUSH usa a tecnologia de reconhecimento facial da Amazon Web Services para processar os dados biométricos dos usuários do CRUSH como seu provedor de serviços ('Processador'). A Amazon pode processar e armazenar modelos de rosto com a finalidade de fornecer serviços de verificação e/ou identificação de rosto em nome do CRUSH e somente sob as instruções do CRUSH. A Amazon armazenará esses dados pelo tempo que o CRUSH solicitar, o que não excederá um período de carência limitado após a data em que (i) o CRUSH deixar de ter um relacionamento com a Amazon ou (ii) quando o CRUSH solicitar a exclusão.",
  "privacy-app-use-34": "Caso você publique postagens no App (o que é meramente opcional, não o obrigamos), será utilizada a tecnologia de reconhecimento facial da Amazon, que nos permite detectar rostos e encontrar usuários semelhantes a você (gêmeos) dentro nosso sistema. Este é o único uso que fazemos desta tecnologia de reconhecimento.",
  "privacy-app-use-35": "Nosso processador, a Amazon, está comprometido com sua própria conformidade com o GDPR, bem como com o fornecimento de uma variedade de produtos, recursos, documentação e recursos para ajudar seus clientes a cumprir suas obrigações de conformidade com o GDPR.",
  "privacy-app-use-36": "Dados de uso do App:",
  "privacy-app-use-37": "Também coletamos automaticamente dados de uso do App, especificamente dados técnicos e informações relacionadas ao seu uso do App, incluindo o tipo de dispositivo móvel, o ID exclusivo do seu dispositivo móvel, o endereço IP do seu dispositivo móvel, o sistema operacional móvel, se qualquer, o tipo de navegador de Internet que utiliza e informações sobre a forma como utiliza a App, como a frequência e o tempo de utilização, os anúncios em que clicou, etc.",
  "privacy-app-use-38": "Dados sobre você fornecidos por terceiros:",
  "privacy-app-use-39": "Outros usuários do App podem nos fornecer informações sobre você quando usam nossos serviços; por exemplo, quando interagem com você ou se nos enviam uma denúncia envolvendo você.",
  "privacy-app-use-40": "Quais são as finalidades do tratamento dos seus dados pessoais?",
  "privacy-app-use-41": "Cadastre-se e gerencie sua conta",
  "privacy-app-use-42": "para que possamos verificar seu e-mail e idade para registrá-lo no App, interagir com você e dar-lhe acesso aos nossos serviços.",
  "privacy-app-use-43": "Gerir e executar a relação contratual que nos une:",
  "privacy-app-use-44": "realizamos diversas etapas necessárias para a execução do contrato firmado com você, para que, por exemplo, possamos lhe prestar o serviço, entrar em contato para ajudá-lo a tirar dúvidas, para verificar as informações que você nos forneceu. Da mesma forma, podemos enviar-lhe comunicações de natureza administrativa, sobre atualizações, alterações nas nossas condições de serviço, alertas de segurança, bem como responder a qualquer pedido ou exigência que nos envie.",
  "privacy-app-use-45": "Publique seu conteúdo.",
  "privacy-app-use-46": "Quando você compartilha conteúdos ou informações através do App, eles aparecem publicados para o público que você selecionou que pode ver o que você compartilha. Para adicionar determinados conteúdos, como fotos e vídeos, você precisará nos dar acesso à sua câmera ou galeria de fotos.",
  "privacy-app-use-47": "Qualquer usuário de nosso App pode visualizar seu nome de usuário e perfil público no App, que inclui conteúdo que você compartilhou com o público em geral. Além disso, lembre-se de que seu conteúdo publicado no App pode ser compartilhado por outros usuários dentro ou fora do App, faça uma captura de tela ou faça o download.",
  "privacy-app-use-48": "Publique conteúdo sobre você.",
  "privacy-app-use-49": "Outros usuários do App podem criar e compartilhar conteúdo com sua imagem ou dados com o público de sua escolha. Se você não se sentir confortável com o conteúdo que outras pessoas compartilharam sobre você, denuncie usando os formulários do App ou entre em contato conosco por e-mail.",
  "privacy-app-use-50": "Oferecer-lhe conteúdos (notícias, artigos, experiências, entre outros)",
  "privacy-app-use-51": "criado diretamente pelo CRUSH ou em colaboração com pessoas externas que possam ser do seu interesse.",
  "privacy-app-use-52": "Envio de notificações push e alertas dentro do App.",
  "privacy-app-use-53": "Se você autorizou, receberá nossas notificações push em seu navegador da web ou em seu dispositivo móvel. As notificações push podem incluir lembretes, comunicações promocionais, alertas e atualizações sobre o App e os serviços.",
  "privacy-app-use-54": "Se ativar estas notificações, receberá avisos personalizados, com base num perfil que desenvolvemos sobre si e na utilização que faz da App. Para a personalização dos avisos, a informação obtida através dos dados por si introduzidos na App e o dados coletados sobre o uso do App (conteúdo visitado) para criar um perfil em relação aos seus interesses e oferecer a você principalmente o conteúdo e a informação que mais lhe convém.",
  "privacy-app-use-55": "Você pode desativar as notificações push a qualquer momento ajustando as configurações em seu dispositivo móvel, embora a desativação não afete outras comunicações que você possa receber do CRUSH, como comunicações por e-mail.",
  "privacy-app-use-56": "Prevenção, deteção e repressão de atividades ilícitas ou contrárias às condições do serviço ou que ponham em perigo a segurança da informação ou da App:",
  "privacy-app-use-57": "O CRUSH poderá processar os dados para controlar e prevenir qualquer forma de abuso de nossos serviços, como atividades fraudulentas, ataques de negação de serviço, envio de spam, acesso não autorizado às contas de nossos usuários, bem como qualquer outra prática contrária à as Condições Gerais e de Utilização da App ou ponha em perigo a segurança da informação ou a integridade da própria App, podendo eliminar o conteúdo ou a conta que seja contrário à lei, às nossas Condições Gerais ou às Regras da comunidade.",
  "privacy-app-use-58": "Neste âmbito, inclui-se também o tratamento de reclamações que apresente contra outros utilizadores ou que sejam apresentadas contra si, podendo contactar o CRUSH para análise do caso concreto e garantindo que haverá intervenção humana para corrigir ou rectificar o problema que nos surja.",
  "privacy-app-use-59": "Também podemos usar ferramentas para detectar conteúdo impróprio (por exemplo, conteúdo adulto ou violento) no conteúdo visual que você publica.",
  "privacy-app-use-60": "Para lhe oferecer um melhor serviço, os seus dados serão também tratados com a finalidade de:",
  "privacy-app-use-61": "Ajudá-lo a se conectar com outros usuários, permitindo que você interaja com eles por meio de telas de bate-papo e outros mecanismos.",
  "privacy-app-use-62": "Recomende você a outros usuários semelhantes a você.",
  "privacy-app-use-63": "Para fazer recomendações, usamos as informações que você nos fornece, incluindo informações sobre sua localização, se você der sua permissão, bem como informações que obtemos de seu uso deste espaço (por exemplo, características das pessoas com quem você realmente interage) para desenvolva um perfil automatizado sobre você e, de preferência, mostre os usuários que correspondem ao seu perfil e o que você está procurando.",
  "privacy-app-use-64": "Neste sentido, serão preferencialmente apresentados utilizadores ativos na App, que estão próximos de si, que correspondem às preferências indicadas e ao seu perfil criado a partir das suas interações, garantindo também que as suas características e perfil correspondem às preferências dos utilizadores recomendados.",
  "privacy-app-use-65": "Fins estatísticos e outros realizados com dados anônimos.",
  "privacy-app-use-66": "Podemos anonimizar todas as informações pessoais coletadas dos usuários que usam o App para fins estatísticos e outros, como treinamento de algoritmos, para medir a eficácia das campanhas publicitárias disponíveis no App, a navegabilidade e usabilidade do App para poder melhorar o design, apresentação e funcionalidades disponíveis ou outros.",
  "privacy-app-use-67": "Para mostrar publicidade:",
  "privacy-app-use-68": "A nossa App poderá incluir publicidade que, mediante o seu consentimento, será personalizada de acordo com os seus gostos e preferências.",
  "privacy-app-use-69": "Qual é a base legal que nos permite tratar os seus dados? O fornecimento destes dados é obrigatório?",
  "privacy-app-use-70": "Executar e manter a relação contratual entre as Partes.",
  "privacy-app-use-71": "A razão pela qual processamos suas informações para os fins mencionados acima é para cumprir o contrato que temos com você.",
  "privacy-app-use-72": "Consentimento.",
  "privacy-app-use-73": "Algumas funcionalidades são baseadas no seu consentimento expresso: (i) publicação de seu próprio conteúdo, (ii) acesso à câmera ou galeria de fotos para publicar determinado conteúdo no App, (iii) acesso à geolocalização do dispositivo para recomendação do usuário por proximidade (iv) recepção de push notifications personalizados (v) apresentação de conteúdos publicitários personalizados. Além disso, se você decidir nos fornecer informações voluntárias adicionais para completar seu perfil, essas informações serão usadas com base em seu consentimento.",
  "privacy-app-use-74": "Lembre-se que pode revogar o seu consentimento a qualquer momento através das configurações do seu dispositivo, apagando o seu conteúdo ou através das opções disponíveis na App.",
  "privacy-app-use-75": "Interesse legítimo.",
  "privacy-app-use-76": "Os dados serão tratados com base no nosso interesse legítimo em relação à prevenção de atividades ilícitas e outras relacionadas com a segurança da App, para garantir a segurança da rede e da informação para prevenir eventos que possam comprometer a disponibilidade, autenticidade, integridade e confidencialidade dos dados pessoais ou da própria plataforma, bem como o cumprimento das normas da comunidade.",
  "privacy-app-use-77": "Também podemos mostrar publicidade não personalizada por meio do App com base em nosso interesse legítimo.",
  "privacy-app-use-78": "Caso pretenda obter mais informações sobre a ponderação que efetuámos sobre os interesses legítimos do CRUSH relativamente às finalidades acima referidas, pode solicitá-la escrevendo-nos para",
  "privacy-app-use-79": "Tratamento de dados especialmente protegidos:",
  "privacy-app-use-80": "Os dados relacionados com os seus interesses ou aspetos relacionados com a orientação sexual serão tratados com base no seu consentimento explícito.",
  "privacy-app-use-81": "Por quanto tempo mantemos suas informações?",
  "privacy-app-use-82": "Manteremos todas as suas informações pessoais enquanto nossa relação contratual estiver em vigor, ou seja, até que você exclua sua conta. Terminada a relação contratual ou cancelada a sua conta, a CRUSH manterá, bloqueada, toda a informação durante os prazos previstos na lei para apurar condutas ilícitas ou lesivas, atender a eventuais responsabilidades e demonstrar o cumprimento das nossas obrigações.",
  "privacy-app-use-83": "Em relação ao conteúdo publicado por você através do nosso App, você deve levar em consideração que qualquer publicação ou comentário que você tenha publicado em nossa comunidade permanecerá no App até que você o exclua ou até que você exclua sua conta. No entanto, qualquer outro conteúdo em que você apareça e que tenha sido publicado por outros usuários (por exemplo, fotos ou vídeos) pode permanecer visível até que o referido usuário o exclua ou feche sua conta.",
  "privacy-app-use-84": "No entanto, reservamo-nos o direito de usar suas informações de forma agregada após encerrar sua conta para fins estatísticos e para melhorar nossos serviços. O uso de tais informações não permitirá que você seja identificado pessoalmente.",
  "privacy-app-use-85": "A quem damos suas informações pessoais?",
  "privacy-app-use-86": "Não existem transferências específicas dos seus dados pessoais para cumprir a finalidade indicada nesta secção. No entanto, podemos recorrer aos serviços de prestadores de serviços, que terão um acesso limitado aos dados e estarão sujeitos a um dever de confidencialidade (para mais informações sobre a atuação dos nossos prestadores de serviços, consulte o ponto",
  "privacy-app-use-87": "4. A quem damos suas informações pessoais? > Prestadores de Serviços",
  "privacy-app-use-88": "No entanto, lembre-se de que, ao postar conteúdo em nosso App, você pode estar compartilhando informações pessoais com outros usuários (incluindo seu perfil público).",
  "privacy-app-use-89": "Além disso, se alguém fizer uma denúncia que implique você, podemos notificar o reclamante sobre as ações que tomamos como resultado da denúncia, se houver.",
  "privacy-cookies": "3.3. Navegação no Website (cookies)",
  "privacy-cookies-1": "Em nosso Website, bem como em nosso App, usamos cookies ou outras ferramentas de rastreamento para coletar informações sobre o uso que os usuários fazem do Website e do App.",
  "privacy-cookies-2": "Para obter mais informações sobre o tratamento que realizamos através dessas ferramentas de rastreamento, visite nossa",
  "privacy-cookies-3": "Política de Cookies",
  "privacy-social-profiles": "3.4. Perfis CRUSH nas redes sociais",
  "privacy-social-profiles-1": "O CRUSH possui perfil nas principais redes sociais, como Twitter, Instagram, LinkedIn ou TikTok.",
  "privacy-social-profiles-2": "Quando se torna seguidor de uma das nossas páginas nas redes sociais, o tratamento dos dados reger-se-á pelas condições de utilização, políticas de privacidade e regulamentos de acesso pertencentes à respetiva rede social e previamente aceites pelo utilizador.",
  "privacy-social-profiles-3": "A CRUSH, neste sentido, tratará os seus dados com a finalidade de gerir corretamente a sua presença na rede social, informando-o sobre atividades, produtos ou serviços, bem como para qualquer outra finalidade que o regulamento das redes sociais o permita.",
  "privacy-social-profiles-4": "Tenha em conta que não temos influência sobre a informação que a rede social recolhe ou como a processa, pelo que recomendamos que se mantenha informado sobre a finalidade e âmbito da recolha de informação que se realiza através das referidas redes sociais.",
  "privacy-transfer": "4. A quem damos suas informações pessoais?",
  "privacy-transfer-1": "Em geral, do CRUSH não comunicaremos seus dados a terceiros. No entanto, para além das transferências que expressamente indicamos na secção em que explicamos as características das diferentes operações (ponto 3), informamos sobre as comunicações que, em geral, podemos efetuar, bem como o seu fundamento legitimador, que afetam todos os tratamentos anteriores.",
  "privacy-transfer-2": "Prestadores de serviços essenciais",
  "privacy-transfer-3": "indispensáveis ​​à execução do serviço que lhe oferecemos (por exemplo, empresas de alojamento informático ou plataformas de envio de comunicações comerciais). Sem prejuízo do anterior, estas entidades subscreveram os respetivos acordos de confidencialidade e apenas tratarão os seus dados de acordo com as nossas instruções, não podendo utilizá-los para fins próprios ou alheios ao serviço que nos prestam.",
  "privacy-transfer-4": "Organismos públicos.",
  "privacy-transfer-5": "Podemos divulgar às autoridades públicas competentes os dados e qualquer outra informação que esteja na nossa posse ou que seja acessível através dos nossos sistemas quando exista uma obrigação legal para o fazer, bem como quando exigido, por exemplo, quando o objetivo é prevenir ou processar o abuso de serviços ou atividades fraudulentas por meio de nosso Website ou App. Nestes casos, os dados pessoais que nos fornecer serão conservados e disponibilizados às autoridades administrativas ou judiciais.",
  "privacy-transfer-6": "No caso de operação societária:",
  "privacy-transfer-7": "No caso de fusão, aquisição, venda total ou parcial de seus ativos, ou qualquer outro tipo de transação societária envolvendo terceiros, podemos compartilhar, divulgar ou transferir dados do usuário para a entidade sucessora (inclusive durante o pré- fase operatória).",
  "privacy-transfer-8": "Para terceiros após agregação ou anonimização:",
  "privacy-transfer-9": "podemos divulgar ou usar dados agregados ou anônimos (ou seja, dados que não podem ser vinculados a uma pessoa física identificada ou identificável) para qualquer finalidade.",
  "privacy-transfer-10": "A terceiros com o consentimento do usuário ou outra base legítima:",
  "privacy-transfer-11": "No caso de querermos partilhar dados com terceiros fora do âmbito desta Política de Privacidade, será solicitado o seu consentimento, em qualquer caso, ou será informado sobre o mesmo e os seus fundamentos legítimos.",
  "privacy-transfer-12": "Da mesma forma, informamos que esta Política de Privacidade se refere apenas à coleta, tratamento e uso de informações (relativas a dados pessoais) por nós por meio da interação que você realiza com nosso Website ou App. O acesso a páginas da web de terceiros que você pode acessar por meio de links do Website ou do App têm suas próprias políticas de privacidade sobre as quais não temos controle. Portanto, antes de fornecer qualquer informação pessoal a eles, recomendamos que você se informe sobre suas Políticas de Privacidade.",
  "privacy-eea": "5. Os seus dados pessoais são transferidos para países terceiros fora do Espaço Económico Europeu?",
  "privacy-eea-1": "Alguns de nossos prestadores de serviços estão localizados em países fora do Espaço Econômico Europeu (",
  "privacy-eea-2": "'EEE'",
  "privacy-eea-3": "A localização destas empresas fora do EEE implica a existência de uma transferência internacional dos seus dados pessoais, o que pode significar um grau de proteção inferior ao previsto na regulamentação europeia. No entanto, da CRUSH temos aplicado medidas para que as referidas transferências não originem um menor grau de proteção dos seus dados pessoais.",
  "privacy-eea-4": "Neste sentido, os prestadores de serviços que se encontram fora do EEE assinaram as respetivas cláusulas contratuais padrão aprovadas pela Comissão Europeia (",
  "privacy-eea-5": "'CCT'",
  "privacy-eea-6": ") Acordo assinado entre ambas as entidades pelo qual a empresa não pertencente à UE garante que aplica as normas europeias de proteção de dados.",
  "privacy-eea-7": "Portanto, o uso desses provedores não acarreta um menor grau de proteção de seus dados pessoais do que o uso de provedores localizados na União Europeia. Pode consultar o conteúdo do CCT no seguinte link:",
  "privacy-eea-8": "A seguir, indicamos quais são as transferências internacionais que fazemos a partir do CRUSH:",
  "privacy-eea-9": "Nome",
  "privacy-eea-10": "Atividades afetadas",
  "privacy-eea-11": "País",
  "privacy-eea-12": "Mecanismo de transferência",
  "privacy-eea-13": "Tudo",
  "privacy-eea-14": "Detecção de rosto e busca por rostos parecidos",
  "privacy-eea-15": "Notificações push",
  "privacy-eea-16": "Enviar e-mails",
  "privacy-eea-usa": "EUA",
  "privacy-eea-sccs": "Cláusulas Contratuais Padrão (SCCs)",
  "privacy-rights": "6. Quais são os direitos que pode exercer como interessado?",
  "privacy-rights-1": "Pode exercer os direitos que a lei lhe garante relativamente ao tratamento dos seus dados pessoais contactando o nosso Delegado de Proteção de Dados através do email",
  "privacy-rights-2": "Qualquer solicitação de direitos que recebermos será resolvida o mais rápido possível e, em qualquer caso, dentro do prazo máximo estabelecido por lei, a partir da data de recebimento. Em alguns casos, será necessário solicitar uma cópia do seu documento de identidade ou outro documento de identificação para verificar sua identidade.",
  "privacy-rights-3": "Os direitos que lhe correspondem como parte interessada são os seguintes:",
  "privacy-rights-4": "Direito de retirar o consentimento concedido",
  "privacy-rights-5": "Você pode revogar seu consentimento em relação a todo o processamento baseado nele a qualquer momento. No entanto, a retirada do consentimento não afetará a legalidade do tratamento com base no consentimento anterior à sua retirada.",
  "privacy-rights-6": "Direito de acesso",
  "privacy-rights-7": "Tem o direito de saber quais os dados que estão a ser tratados, se aplicável e, em caso afirmativo, de obter uma cópia dos mesmos, bem como de obter informação relativa a:",
  "privacy-rights-8": "a origem e destinatários dos dados;",
  "privacy-rights-9": "as finalidades para as quais são tratados;",
  "privacy-rights-10": "se existe um processo automatizado de tomada de decisão, incluindo a criação de perfis;",
  "privacy-rights-11": "o período de retenção de dados; e",
  "privacy-rights-12": "os direitos previstos nos regulamentos.",
  "privacy-rights-13": "Direito de retificação",
  "privacy-rights-14": "Tem o direito de obter a retificação dos seus dados pessoais ou de os completar quando estiverem incompletos.",
  "privacy-rights-15": "Direito de apagar",
  "privacy-rights-16": "Tem o direito de solicitar a eliminação dos seus dados pessoais se já não forem necessários para a finalidade para a qual foram recolhidos ou, se for caso disso, se já não estivermos autorizados a tratá-los.",
  "privacy-rights-17": "Direito à portabilidade de dados",
  "privacy-rights-18": "Tem o direito de solicitar a portabilidade dos seus dados no caso de tratamentos que se baseiem no seu consentimento ou na execução de um contrato, desde que o tratamento tenha sido efetuado por meios automatizados. Caso exerça este direito, receberá os seus dados pessoais num formato estruturado, de uso corrente e legível por qualquer dispositivo eletrónico. No entanto, pode também solicitar, sempre que possível, que os seus dados sejam transmitidos diretamente a outra empresa.",
  "privacy-rights-19": "Direito de limitar o tratamento dos seus dados pessoais",
  "privacy-rights-20": "Tem o direito de limitar o tratamento dos seus dados nos seguintes casos:",
  "privacy-rights-21": "Quando tiver solicitado a retificação dos seus dados pessoais, durante o período em que verificamos a sua exatidão.",
  "privacy-rights-22": "Quando considera que não estamos autorizados a tratar os seus dados. Nesse caso, você pode nos pedir para limitar seu uso em vez de solicitar sua exclusão.",
  "privacy-rights-23": "Quando considera que já não é necessário que continuemos a tratar os seus dados e pretende que os conservemos para efeitos de exercício ou defesa de reclamações.",
  "privacy-rights-24": "Nos casos em que haja tratamento com base no nosso interesse legítimo e tenha exercido o seu direito de oposição, pode solicitar-nos a limitação da utilização dos seus dados durante a verificação da prevalência desses interesses relativamente aos seus.",
  "privacy-rights-25": "Direita de oposição",
  "privacy-rights-26": "Você tem o direito de se opor a qualquer momento ao processamento de seus dados pessoais com base em nosso interesse legítimo, incluindo a criação de perfis.",
  "privacy-rights-27": "Direito de reclamar junto da Autoridade de Controlo",
  "privacy-rights-28": "Lembre-se de que, a qualquer momento, e no caso de considerar que violamos o seu direito à proteção de seus dados, você pode entrar em contato com a Autoridade de Controle correspondente em sua defesa, no caso da Espanha, a Agência Espanhola de Proteção de Dados (",
  "privacy-confidence": "7. Como garantimos a confidencialidade de suas informações?",
  "privacy-confidence-1": "A segurança dos seus dados pessoais é uma prioridade para nós. Por esta razão, a CRUSH implementou todas as medidas de segurança necessárias para garantir a utilização e tratamento eficaz dos dados pessoais fornecidos pelo utilizador, salvaguardando a privacidade, privacidade, confidencialidade e integridade dos mesmos, e faz uso das técnicas mediáticas necessárias para evitar a sua alteração, perda, acesso não autorizado ou tratamento dos seus dados, de acordo com o estado da tecnologia em cada momento.",
  "privacy-confidence-2": "Consequentemente, cumprimos os padrões de segurança recomendados para protegê-los. No entanto, é impossível garantir totalmente a sua segurança devido à própria natureza da Internet e porque pode haver ações maliciosas por parte de terceiros fora do nosso controle.",
  "privacy-confidence-3": "Prometemos agir com rapidez e diligência caso a segurança dos dados esteja em perigo ou comprometida, e informá-lo sobre isso caso seja relevante.",
  "privacy-modifications": "8. Alterações a esta política",
  "privacy-modifications-1": "Desde CRUSH podemos modificar o conteúdo desta Política de Privacidade a qualquer momento, especialmente quando houver modificações legislativas, jurisprudenciais ou interpretativas da Agência Espanhola de Proteção de Dados que afetem o tratamento de dados realizado por CRUSH através do Website ou App. Qualquer novo versão desta Política de Privacidade entrará em vigor na data efetiva publicada.",
  "privacy-modifications-2": "Se a versão revisada incluir uma alteração substancial que afete o processamento de seus dados, notificaremos você com pelo menos 30 dias de antecedência, publicando um aviso em nosso Website ou App ou notificando você por e-mail. Em todo o caso, recomendamos que reveja periodicamente esta Política de Privacidade para estar informado sobre a forma como os seus dados pessoais são tratados e protegidos, bem como sobre os seus direitos.",
  "cookies-title": "Crush - Cookies",
  "cookies-date": "Última modificação: 8 dezembro, 2023",
  "cookies-header": "Política de cookies",
  "cookies-introduction-1": "Tanto no website www.crushsocialnetwork.com (doravante, o",
  "cookies-introduction-2": "'Website'",
  "cookies-introduction-3": ") bem como através da aplicação móvel para Smartphones com o nome 'Crush' (doravante, o",
  "cookies-introduction-4": "'App'",
  "cookies-introduction-5": ") usamos cookies ou outras ferramentas de rastreamento e rastreamento para coletar informações sobre o uso que os usuários fazem do Website e do App.",
  "cookies-introduction-6": "Neste documento, você descobrirá quais cookies damos ao Cookie Monster, bem como qualquer outro tipo de informação relacionada a esta maravilhosa mordida.",
  "cookies-definition": "1. O que é um cookie?",
  "cookies-definition-1": "Um cookie é um arquivo que é instalado no navegador do seu dispositivo (computador, celular, etc.) para armazenar e recuperar dados. Os cookies podem ter uma multiplicidade de finalidades, consoante para que foram configurados: por exemplo, guardar preferências, recolher estatísticas de utilização, habilitar funcionalidades técnicas, entre outras.",
  "cookies-responsible": "2. Quem é o responsável?",
  "cookies-responsible-1": "O responsável pelo tratamento da informação recolhida através de cookies é a CRUSH SOCIAL NETWORK, Lda. (CRUSH), com NIF B-72599830 e morada em Calle Pintor Velázquez, 3, 1, 30530 Cieza, Murcia (Espanha).",
  "cookies-responsible-2": "Se você tiver alguma dúvida relacionada ao uso de cookies, escreva um e-mail para",
  "cookies-responsible-3": "e nós responderemos a você o mais breve possível.",
  "cookies-data": "3. Que dados são recolhidos através de cookies?",
  "cookies-data-1": "Quando acede ao Website ou instala e utiliza a App, mesmo que não se tenha registado, são recolhidas através de cookies as seguintes informações:",
  "cookies-data-2": "Informações do dispositivo: Durante o uso do Website ou do App, coletamos informações sobre o dispositivo móvel a partir do qual você acessa a plataforma. As informações que coletamos são o modelo do dispositivo, sistema operacional e versão, identificador exclusivo do dispositivo e rede.",
  "cookies-data-3": "Informação sobre a sua localização: Obtemos também o endereço IP, fuso horário e qual é o fornecedor do serviço móvel, se for o caso, o que nos permite obter uma localização geral.",
  "cookies-data-4": "Informações de navegação do usuário: coletamos informações sobre o uso que você faz do Website e do App. Especificamente, a frequência de uso, as seções que você visita, uso de funções específicas, etc.",
  "cookies-types": "4. Que tipos de cookies existem?",
  "cookies-types-1": "De acordo com seu propósito,",
  "cookies-types-2": "podemos classificar os cookies entre os seguintes:",
  "cookies-types-3": "Cookies técnicos:",
  "cookies-types-4": "Esses cookies são estritamente necessários para navegar no Website ou no App e permitem funções básicas, como navegar de uma seção para outra. Eles são responsáveis por aspectos técnicos como a identificação de perfis ou sessões e gerenciamento de preferências de ferramentas no uso, interação e localização de acesso ao conteúdo. Pertencem também a esta categoria, pela sua natureza técnica, os cookies que permitem gerir, da forma mais eficiente possível, os espaços publicitários incluídos no Website e na App, sem recolher informação dos utilizadores para diversas finalidades, tais como personalizar anúncios ou outros tipos de conteúdo.",
  "cookies-types-5": "Cookies estatísticos:",
  "cookies-types-6": "Estes cookies medem e recolhem informação estatística relativa à utilização que o utilizador faz do Website ou da App, como o tempo de permanência na plataforma, o número de visitas ou os conteúdos visitados. A referida informação permite-nos compreender a forma como os utilizadores interagem com o Website e a App, avaliar o desempenho, quais as secções mais ou menos visitadas, incluindo a quantificação do impacto dos anúncios introduzidos no Website e na App (se aplicável). Ter essas informações pode solucionar erros e melhorar o design do sistema e o conteúdo oferecido por ele.",
  "cookies-types-7": "Cookies de preferência ou personalização:",
  "cookies-types-8": "Os cookies de preferência permitem que o Website ou o App lembrem informações específicas do usuário para que possam acessar o serviço com determinadas características que podem diferenciar sua experiência da de outros usuários. Por exemplo, o seu idioma preferido ou a região a partir da qual acede, o número de resultados a apresentar quando o utilizador realiza uma pesquisa, o aspeto ou conteúdo do serviço em função do tipo de dispositivo a partir do qual o utilizador acede, etc.",
  "cookies-types-9": "Cookies de publicidade comportamental:",
  "cookies-types-10": "Estes cookies permitem a gestão dos espaços publicitários que, conforme o caso, foram incluídos no Website ou na App. No entanto, a gestão dos espaços publicitários é feita com base no perfil de navegação do utilizador. Esses cookies armazenam informações de navegação do usuário obtidas através da observação contínua de seus hábitos de navegação, desenvolvendo um perfil de usuário específico, de modo que os anúncios que aparecem nesses espaços serão personalizados para o usuário de acordo com esses hábitos.",
  "cookies-types-11": "Consoante a entidade que os gere:",
  "cookies-types-12": "Cookies próprios:",
  "cookies-types-13": "são aquelas que são enviadas ao dispositivo do usuário a partir de um computador ou domínio administrado pelo responsável pelo Website ou App, neste caso, CRUSH, e a partir do qual é prestado o serviço solicitado pelo usuário.",
  "cookies-types-14": "Cookies de terceiros:",
  "cookies-types-15": "são aqueles que são enviados para o dispositivo do utilizador a partir de um computador ou domínio que não é gerido pelo responsável do Website ou da App, mas por outra entidade que trata os dados obtidos através dos cookies.",
  "cookies-types-16": "Dependendo do tempo que permanecem ativos no dispositivo:",
  "cookies-types-17": "Cookies de sessão:",
  "cookies-types-18": "Eles são projetados para coletar e armazenar dados durante o tempo em que o usuário está navegando no Website ou no App, e são desativados no final da navegação.",
  "cookies-types-19": "Cookies persistentes:",
  "cookies-types-20": "Estes armazenam dados que podem ser acedidos e tratados durante um período definido pelo responsável pelo cookie. Suas vidas podem variar de minutos a anos.",
  "cookies-used": "5. Quais cookies usamos especificamente em nossa plataforma?",
  "cookies-used-1": "Na nossa plataforma, utilizamos os seguintes cookies:",
  "cookies-used-2": "Nome",
  "cookies-used-3": "Propósito",
  "cookies-used-4": "Cookies Próprios/de Terceiros",
  "cookies-used-5": "Expiração",
  "cookies-used-6": "14 meses",
  "cookies-used-7": "Indeterminada",
  "cookies-used-stats": "Estatisticas",
  "cookies-used-technical": "Técnico",
  "cookies-used-third-party-google": "Terceiros (Google LLC.)",
  "cookies-legitimation": "6. Qual é a base de legitimação que nos permite tratar os seus dados através de cookies? Se sim, são obrigatórios?",
  "cookies-legitimation-1": "As informações que coletamos por meio de cookies e outros dispositivos de rastreamento são baseadas no seu consentimento, que solicitamos quando você acessa o Website ou quando instala o App por meio de um pop-up informativo ou da maneira estabelecida pelo Google (quando se trata de Android) ou Apple (quando se trata de iOS).",
  "cookies-legitimation-2": "Nesse sentido, a qualquer momento você pode retirar seu consentimento conforme explicado na seção relacionada à revogação do consentimento.",
  "cookies-legitimation-3": "Os cookies técnicos não podem ser desativados, caso contrário, o bom funcionamento do Website ou App seria comprometido. Em geral, eles são definidos apenas em resposta a ações realizadas ao solicitar nossos serviços, como definir suas preferências em relação aos próprios cookies, fazer login ou preencher formulários. Caso ocorra a desativação, isso pode fazer com que o uso de alguns dos serviços do Website ou do App seja limitado por não poder acessar determinadas funcionalidades e, portanto, sua experiência seria menos satisfatória.",
  "cookies-transfer": "7. A quem damos suas informações pessoais? Existem transferências internacionais de dados?",
  "cookies-transfer-1": "Utilizamos alguns cookies de terceiros, que são aqueles cookies recolhidos e geridos por terceiros, pelo que as referidas entidades receberão os seus dados pessoais recolhidos através de cookies para as finalidades indicadas para cada cookie. Esses tipos de cookies são indicados na tabela da seção 5, como 'Cookies de terceiros'. Esta tabela identifica o terceiro que gerencia cada cookie.",
  "cookies-transfer-2": "Poderá informar-se sobre as transferências para países terceiros que, no seu caso, são efetuadas pelos terceiros identificados nesta Política de Cookies nas respetivas políticas.",
  "cookies-consent": "8. Qual é a forma de aceitar, negar ou revogar o consentimento para a utilização de cookies neste website?",
  "cookies-consent-1": "Ao aceder pela primeira vez ao nosso Website ou à nossa App, ser-lhe-á apresentada uma janela na qual poderá indicar se aceita cookies ou se deseja configurá-los ou rejeitá-los, exceto os estritamente necessários. Da mesma forma, você pode retirar o consentimento anteriormente concedido a qualquer momento.",
  "cookies-consent-2": "Caso pretenda retirar o seu consentimento relacionado com a Política de Cookies a qualquer momento, tem duas opções: (i) retirar o seu consentimento através dos botões habilitados para o efeito nesta Política de Cookies; ou (ii) excluir os cookies armazenados no dispositivo por meio das definições e configurações do seu navegador de Internet.",
  "cookies-consent-3": "Tenha em conta que, se aceitar cookies de terceiros, deverá eliminá-los das opções do navegador ou do sistema oferecido pelo próprio terceiro.",
  "cookies-consent-4": "Você pode expandir as informações sobre como desativar os cookies, dependendo do seu navegador, nos seguintes links:",
  "cookies-consent-5": "; para iOS:",
  "cookies-rights": "9. Quais são os direitos que pode exercer como interessado?",
  "cookies-rights-1": "Os direitos que você tem como interessado em relação à proteção de seus dados pessoais são indicados na seção 'Quais são os direitos que você pode exercer como interessado?' dentro do",
  "cookies-rights-2": "Política de Privacidade",
  "cookies-modifications": "10. Modificações a esta política",
  "cookies-modifications-1": "Podemos atualizar a Política de Cookies de nossa plataforma de tempos em tempos. Por este motivo, recomendamos que reveja esta política sempre que aceda ao nosso Website ou App de forma a estar devidamente informado sobre como e para que utilizamos os cookies."
}
