/**
 * @file i18n utility functions.
 * @author Victorio Molina <https://github.com/VictorioMolina>
 * @copyright Crush Social Network 2022.
 * @license MIT
 * @module i18n/Functions
 */

// eslint-disable-next-line import/prefer-default-export
export { default as getNavigatorLanguage } from "./getNavigatorLanguage";
