{
  "404-title": "Crush - Non trouvé",
  "404-not-found-1": "Non trouvé",
  "404-not-found-2": "Désolé, la page que vous recherchez n'est pas disponible.",
  "navbar-mission": "Mission",
  "navbar-download": "Télécharger",
  "footer-follow": "Suivez-nous",
  "footer-follow-1": "Instagram",
  "footer-follow-2": "TikTok",
  "footer-follow-3": "Twitter",
  "footer-follow-4": "LinkdIn",
  "footer-docs": "Documents",
  "footer-docs-1": "Mission",
  "footer-docs-2": "Mentions légales",
  "footer-docs-3": "Confidentialité",
  "footer-docs-4": "Cookies",
  "footer-docs-5": "Directives",
  "footer-contact": "Contactez-nous",
  "footer-contact-1": "Support",
  "footer-contact-2": "Emplois",
  "footer-contact-3": "Investisseurs",
  "home-title": "Crush - Où les médias rencontrent le platonique",
  "home-hero-slogan-1": "Where media",
  "home-hero-slogan-2": "meets platonic ™",
  "home-concept-1": "Trouve tes personnes",
  "home-concept-2": "Connecte-toi avec des personnes comme toi à différentes étapes de ta vie.",
  "home-concept-3": "Vibes cool",
  "home-concept-4": "Partage de bonnes vibes et de vrais moments. Pas de toxicité ni de prétention.",
  "home-attributes-1": "Aesthetic",
  "home-attributes-2": "Nous encourageons la publication de contenu aesthetic et de haute qualité; le web est déjà inondé de photos de chats.",
  "home-attributes-3": "Stalker",
  "home-attributes-4": "Tes amis sont les stars. Prends quelques rafales de photos quand ils ne s'y attendent pas et poste des GIFs sur leurs comptes.",
  "home-attributes-5": "Fils",
  "home-attributes-6": "Participe à des discussions vibrantes couvrant les sujets les plus actuels aux tendances qui façonneront demain.",
  "home-attributes-7": "Jumeaux",
  "home-attributes-8": "Connecte-toi avec des individus qui ont des traits similaires aux tiens, amplifiant ton réseau social d'une manière complètement nouvelle et amusante.",
  "home-attributes-9": "Segmentation par âge",
  "home-attributes-10": "En regroupant les utilisateurs par âge, similaire aux classes de lycée, les connexions sont limitées aux individus de la même génération pour une interaction plus sûre et efficace.",
  "home-attributes-11": "Crush",
  "home-attributes-12": "Pas une application de rencontres — juste des amis et des relations détendues. Revivez la nostalgie des liens du lycée dans un environnement numérique.",
  "home-partners": "Powered By",
  "mission-title": "Crush - Mission",
  "mission-date": "Dernière modification: 8 décembre, 2023",
  "mission-header": "Mission et valeurs",
  "mission-introduction": "Au cœur de Crush bat quelque chose de plus qu'un simple réseau social; c'est un univers tissé de valeurs éthiques, d'une philosophie et d'un objectif. Ce document est le voyage à travers les fondements de notre vision, depuis la naissance de l'idée jusqu'à l'esquisse minutieuse de la création que nous forgeons.",
  "mission-origin": "L'origine",
  "mission-origin-1": "L'origine de Crush remonte à une graine semée bien avant son développement concret en 2020 et son lancement en 2023. Notre vision de fusionner les réseaux sociaux avec la touche distinctive de Crush a été conçue en 2016, inspirée par 'The Social Network', l'œuvre cinématographique notable reconnue par Quentin Tarantino comme un bijou de la décennie précédente. Ce film, qui raconte l'histoire des jeunes fondateurs de Facebook, en particulier sa figure la plus reconnue, Mark Zuckerberg — le père des réseaux sociaux, nous a offert une perspective éclairante.",
  "mission-origin-2": "Chez Crush, nous croyons fermement que le cœur des réseaux sociaux d'aujourd'hui réside dans l'amour platonique. Si vous avez eu la chance de regarder ce film, vous avez peut-être remarqué les critiques de Zuckerberg et d'Eduardo Saverin à l'égard des applications de rencontres. Dans une scène clé, vers la 30e minute, pendant le procès des frères Winklevoss pour prétendu vol d'idée, Saverin mentionne:",
  "mission-origin-3": "\"It really hadn't much to do with the Winklevoss' dating site\".",
  "mission-origin-4": "C'est curieux, car quelques minutes plus tard, vers la minute 36:05, nous voyons Mark implémenter des éléments tels que le statut relationnel et les centres d'intérêt dans son projet:",
  "mission-origin-5": "\"Relationship status, interests... This is what drives life in college. Are you having sex or aren't you? That's why people take classes, sit where they sit, and do what they do. It's the center, you know? That's what The Facebook is going to be.\".",
  "mission-origin-6": "Enfin, dans la scène finale, nous voyons un Zuckerberg épris d'amour envoyer une demande d'ami à son crush.",
  "mission-origin-7": "Ce film pose une question intrigante:",
  "mission-origin-8": "Les réseaux sociaux sont-ils réellement des applications de rencontre déguisées?",
  "mission-purpose": "Notre but",
  "mission-purpose-1": "Il n'y a pas de réponse définitive à la question posée dans la section précédente. Peut-être que oui, peut-être que non... en fin de compte, cela dépend des valeurs éthiques et culturelles, des intentions et de l'éducation de l'utilisateur final. Ce que nous savons avec certitude, c'est que cela ouvre la porte à des problèmes réels et très sérieux qui, contrairement au monde réel, peuvent en effet être résolus.",
  "mission-purpose-2": "Que ce soit par les normes préétablies par la société ou l'anonymat qui conduit à des comportements inappropriés en ligne, l'amour dans le monde numérique, qu'il soit platonique et authentique sur les réseaux sociaux ou plus romantique sur les applications de rencontres, a malheureusement été sexualisé ces dernières années. Pourquoi quelque chose d'aussi beau et fondamental dans nos vies est-il déformé dans cet univers parallèle?",
  "mission-purpose-3": "Soyons clairs et complètement transparents. En Espagne, notre pays d'origine, selon des données et des statistiques vérifiables,",
  "mission-purpose-4": "7 enfants sur 10 âgés de moins de 15 ans possèdent un appareil mobile.",
  "mission-purpose-5": "Cela signifie qu'environ 70% des mineurs ont un accès non restreint à un monde virtuel où l'interaction avec les adultes n'a pas été réglementée ni restreinte, les exposant à des risques tels que le harcèlement, la cyberintimidation ou la pédophilie, entre autres.",
  "mission-purpose-6": "Actuellement, peu importe le réseau social sur lequel vous vous inscrivez, il est courant de trouver des personnes vendant du contenu inapproprié ou partageant tout type de contenu inadéquat. La situation devient de plus en plus déplorable à mesure que le temps passe.",
  "mission-purpose-7": "Nous visons à éliminer la sexualisation et la toxicité du réseau en abordant directement leur origine.",
  "mission-purpose-8": "Crush est proposé comme une redéfinition des réseaux sociaux d'un point de vue différent, mettant l'accent sur les relations platoniques.",
  "mission-purpose-9": "Nous ne proposons pas de système de rencontres ni la création de relations romantiques/sérieuses, car cela contredit nos",
  "mission-purpose-10": "directives communautaires.",
  "mission-purpose-11": "Au lieu de cela, nous offrons ce qui était imaginé dans le film 'The Social Network', mais avec une solution au problème et une approche plus cool.",
  "mission-purpose-12": "Notre mission va au-delà du typique \"partagez des moments réels\" ou \"nous vous aidons à vous connecter\". En plus d'accompagner l'utilisateur à travers les différentes étapes de sa vie, nous nous engageons à résoudre les problèmes mentionnés précédemment. Sur notre plateforme, au moment de la rédaction de ce document, il est déjà impossible pour un mineur d'interagir avec un adulte (et vice versa), non seulement pour fermer la porte à ces problèmes éthiques, mais aussi pour encourager la création de liens entre des personnes de la même génération, avec une mentalité similaire. Nous prévoyons d'introduire des solutions plus efficaces dans la prochaine décennie de développement; c'est notre promesse.",
  "guidelines-title": "Crush - Directives",
  "guidelines-date": "Dernière modification: 4 avril, 2024",
  "guidelines-header": "Directives communautaires",
  "guidelines-introduction-1": "Chez Crush, il s'agit d'établir des connexions platoniques, de partager des moments authentiques de la vie avec des amis, de socialiser et de créer de nouvelles amitiés qui correspondent à votre état d'esprit. Nous croyons fermement en la liberté d'expression, mais nous apprécions également la capacité de distinguer le bien du mal. Ce document détaille les normes de notre réseau social, essentielles pour remplir",
  "guidelines-introduction-2": "notre objectif principal.",
  "guidelines-introduction-3": "Nous attendons un comportement approprié, un contenu soigneux, de l'authenticité, de l'humilité et surtout, de la responsabilité. Toute violation de nos principes fondamentaux pourrait entraîner la suspension permanente de votre compte, sans possibilité de vous réinscrire dans notre système.",
  "guidelines-introduction-4": "Tout comme dans la vie réelle, il y a des conséquences pour les actions. Veuillez respecter nos règles et ne pas utiliser notre système de manière contraire à la loi. Nous tenons à souligner notre sérieux et notre rigueur; un ton ludique n'est pas le bienvenu dans ce contexte.",
  "guidelines-dating": "Anti-rencontres",
  "guidelines-dating-1": "Crush est une combinaison de médias sociaux adaptée à toutes les personnes",
  "guidelines-dating-2": "âgées de 15 ans et plus,",
  "guidelines-dating-3": "qu'elles soient en couple ou non. Ne confondez pas les amis et les relations platoniques (optionnelles) avec les relations romantiques ou les véritables engagements.",
  "guidelines-dating-4": "Ensuite, nous détaillons une série de comportements que tu dois adopter et respecter pour rester sur notre plateforme:",
  "guidelines-dating-5": "Ne pas utiliser notre service pour chercher ou demander des rencontres romantiques.",
  "guidelines-dating-6": "Si l'amour doit entrer dans ta vie, qu'il ne vienne pas à travers un écran.",
  "guidelines-dating-7": "Ne publie pas de choses sales.",
  "guidelines-dating-8": "Ne harcèle ou ne dérange aucun utilisateur.",
  "guidelines-dating-9": "Ne mens pas sur ton âge ou tes informations personnelles, et encore moins n'usurpe l'identité d'une autre personne.",
  "guidelines-dating-10": "C'est un crime grave qui pourrait entraîner des poursuites judiciaires contre toi.",
  "guidelines-content": "Aesthetic",
  "guidelines-content-1": "Pour nous, toute forme de logiciel est de l'art et l'art doit être respecté. Sur un réseau social, la qualité de la plateforme se reflète dans le contenu que les utilisateurs génèrent. Dans notre cas, nous encourageons la créativité et l'inspiration. Nous te demandons de publier en maintenant un standard minimum de qualité.",
  "guidelines-content-2": "Ne publie pas de contenu visuellement médiocre.",
  "guidelines-content-3": "Fais un effort.",
  "guidelines-content-4": "Le spam, c'est suppression directe.",
  "guidelines-content-5": "Si tu veux promouvoir ton Instagram, utilise les outils qu'on te fournit pour ça.",
  "guidelines-content-6": "Ne partage pas de contenu qui viole la propriété intellectuelle d'autrui.",
  "guidelines-content-7": "Merci d'éviter de publier des photos de chatons;",
  "guidelines-content-8": "Internet en est déjà plein.",
  "guidelines-content-9": "Ne te ridiculise pas en publiant des nudités inutiles.",
  "guidelines-content-10": "Nous en avons assez des photos torse nu.",
  "guidelines-vibes": "Que des bonnes vibes",
  "guidelines-vibes-1": "Chez Crush, nous ne tolérons pas les comportements inappropriés. Tout comme vous soignez votre image dans la vie réelle, nous vous demandons d'être responsable et de maintenir cette intégrité en ligne. Faites preuve de courtoisie et de respect envers les utilisateurs de notre communauté ainsi que les créateurs de la plateforme.",
  "guidelines-vibes-2": "L'exploitation des enfants, la sextorsion ou tout autre type d'abus, sous quelque forme que ce soit, sont strictement interdits.",
  "guidelines-vibes-3": "Notre engagement contre le suicide et l'automutilation est absolu.",
  "guidelines-vibes-4": "Tout contenu encourageant, promouvant ou glorifiant ces actions entraînera une suspension permanente.",
  "guidelines-vibes-5": "Nous prenons la légalité au sérieux.",
  "guidelines-vibes-6": "Évite de partager du contenu qui encourage des activités illégales, ainsi que tout contenu lié aux armes ou aux drogues.",
  "guidelines-vibes-7": "Le contenu offensant ou violent n'a pas sa place sur notre plateforme et n'est pas autorisé.",
  "guidelines-vibes-8": "Le mode Stalker est destiné à s'amuser entre amis.",
  "guidelines-vibes-9": "Cependant, même si le contenu nécessite finalement l'approbation de l'utilisateur (ou des utilisateurs) dans le GIF, évite de partager des photos de tes amis sans leur consentement préalable.",
  "legal-title": "Crush - Mentions légales",
  "legal-date": "Dernière modification: 4 avril, 2024",
  "legal-header": "Mentions légales",
  "legal-introduction-1": "Chaque application est un univers et tous les univers sont soumis à des règles. Dans ce document, vous trouverez l'avis légal et les conditions générales d'utilisation de notre plateforme, complétés par ceux définis dans nos",
  "legal-introduction-2": "directives communautaires.",
  "legal-owner": "1. Identification du titulaire",
  "legal-owner-1": "Le site web www.crushsocialnetwork.com (ci après, le",
  "legal-owner-2": "'Site Web'",
  "legal-owner-3": ") et l'application mobile pour Smartphones avec le nom 'Crush' (ci après, la",
  "legal-owner-4": "'App'",
  "legal-owner-5": ") sont la propriété de CRUSH SOCIAL NETWORK, SARL., avec NIF: B-72599830 et inscrit au registre du commerce de Valence avec les données de registre suivantes: T. 3606, F.75, I.1, H. MU-108656, dont les coordonnées sont:",
  "legal-owner-6": "Domicile Social:",
  "legal-owner-7": "Calle Pintor Velázquez, 3, 1, 30530 Cieza, Murcia (Espagne)",
  "legal-owner-8": "É-mail de contact:",
  "legal-area": "2. Champ d'application",
  "legal-area-1": "Les présentes Conditions Générales d'Utilisation du Site (ci après, les",
  "legal-area-2": "'Conditions d'utilisation'",
  "legal-area-3": ") recueillir les termes et conditions régissant l'accès, la navigation et l'utilisation du Site Web et de l'App.",
  "legal-area-4": "En ce sens, l'accès, l'utilisation et la navigation sur le Site Web et l'App confèrent la qualité d'utilisateur(s) (ci après, le",
  "legal-area-5": "'Utilisateur'",
  "legal-area-6": "ou les",
  "legal-area-7": "'Utilisateurs'",
  "legal-area-8": "), et implique l'acceptation de chacune de ces conditions d'utilisation. L'Utilisateur est pleinement conscient que la simple navigation sur le Site Web ou l'App implique l'acceptation des présentes conditions d'utilisation et, par conséquent, si l'Utilisateur n'est pas d'accord avec celles-ci, il ne doit pas utiliser le Site Web ou cette App.",
  "legal-area-9": "Le Site Web et l'App sont principalement destinés aux utilisateurs résidant en Espagne. Crush ne fait aucune déclaration selon laquelle le Site Web ou l'App est conforme aux lois d'autres pays, en tout ou en partie. Si vous résidez ou êtes domicilié ailleurs et choisissez d'accéder et/ou de naviguer sur le Site Web ou d'accéder ou d'utiliser l'App, vous le faites à vos propres risques et vous devez vous assurer que cet accès, cette navigation ou cette utilisation est conforme aux lois locales applicables, et Crush n'assume aucune responsabilité quelle qu'elle soit découlant de cet accès ou de cette utilisation.",
  "legal-area-10": "Crush peut, à tout moment et sans préavis, modifier les présentes conditions d'utilisation, qui seront affichées au fur et à mesure de ces modifications.",
  "legal-conditions": "3. Conditions d'accès et d'utilisation",
  "legal-conditions-1": "L'accès au Site Web ou à l'App par l'Utilisateur, en règle générale, est gratuit. Dans le cas où il existe des fonctionnalités ou des services réservés à certains Utilisateurs ou qu'un prix doit être payé, ceux-ci seront dûment identifiés sur le Site Web ou l'App.",
  "legal-conditions-2": "L'accès au Site Web ou à l'App s'effectue sous la seule responsabilité de l'Utilisateur et n'implique aucune relation commerciale entre Crush et l'Utilisateur. L'utilisateur est tenu de s'assurer que les informations et le contenu inclus sur le Site Web ou dans l'App répondent à ses besoins spécifiques.",
  "legal-conditions-3": "L'Utilisateur s'engage à:",
  "legal-conditions-4": "Accéder et utiliser le Site Web et l'App de bonne foi et conformément aux présentes conditions d'utilisation, à la loi, à la morale et à l'ordre public, avec la condition supplémentaire d'atteindre l'âge minimum de 15 ans.",
  "legal-conditions-5": "Chez Crush, nous certifions que nous respectons la législation applicable en matière de sécurité des enfants et prenons au sérieux le matériel d'abus sexuel sur les enfants. Nous prenons des mesures appropriées, telles que la suppression de ce matériel dès que nous avons connaissance réelle de son existence, conformément à nos normes et aux lois pertinentes.",
  "legal-conditions-6": "Fournir des informations véridiques, actuelles et légales dans les formulaires proposés tant sur le Site Web que dans l'App. En tout état de cause, l'utilisateur notifiera immédiatement à Crush tout événement permettant l'utilisation abusive des informations enregistrées dans lesdits formulaires, tel que, mais sans s'y limiter, le vol, la perte ou l'accès non autorisé aux identifiants et/ou mots de passe, afin de procéder à leur annulation immédiate.",
  "legal-conditions-7": "N'effectuer aucune action sur le Site Web ou l'App susceptible de causer des dommages ou des altérations aux contenus, aux programmes ou aux systèmes du Site Web ou de l'App, y compris l'introduction de virus informatiques, l'installation de robots ou de tout logiciel ou fichier nuisible, défectueux ou susceptible de causer des dommages à nos systèmes informatiques.",
  "legal-conditions-8": "L'utilisateur sera responsable dans tous les cas des dommages qu'il pourrait causer à Crush ou à des tiers, et Crush pourra adopter toutes les mesures techniques, juridiques ou autres qu'elle jugera appropriées pour prévenir, atténuer ou arrêter les conséquences de la conduite interdite ci-dessus et exiger les responsabilités qu'elle jugera appropriées.",
  "legal-property": "4. Propriété intellectuelle et industrielle",
  "legal-property-1": "Tout le contenu du Site Web et de l'App, y compris, mais sans s'y limiter, les textes, les images, les photographies, les vidéos, les graphiques, les signes distinctifs de toute nature, les icônes, les interfaces, ainsi que les logiciels, le code source, les designs, l'architecture, la présentation, la mise en page et la classification du contenu et tout autre élément présent sur le Site Web ou l'App pouvant faire l'objet de droits de propriété intellectuelle sont la propriété exclusive de Crush ou de tiers qui ont concédé une licence, autorisé ou consenti à leur utilisation sur le Site Web ou l'App.",
  "legal-property-2": "En ce sens, lorsque l'Utilisateur accède, navigue et utilise le Site Web ou accède ou utilise l'App, aucun droit d'exploitation existant ou pouvant exister sur tout ou partie de ceux-ci ne lui est conféré, et Crush se réserve tous ces droits. L'Utilisateur ne peut visualiser les éléments du Site Web et de l'App et les utiliser que dans la mesure strictement nécessaire à leur bon usage. En particulier, vous ne pouvez pas utiliser ou revendre à des fins commerciales tout matériel ou contenu du Site Web ou de l'App sans l'autorisation préalable de Crush.",
  "legal-property-3": "Crush se réserve le droit de modifier, à tout moment et sans préavis, la présentation et la configuration du Site Web ou de l'App ainsi que les contenus et services qui peuvent y être intégrés. Vous reconnaissez et acceptez que Crush puisse à tout moment interrompre, désactiver et/ou mettre fin à l'une de ces fonctionnalités intégrées au Site Web et à l'App ou à l'accès à ceux-ci.",
  "legal-property-4": "Si l'utilisateur considère que l'un des contenus du Site Web ou de l'App constitue une violation des droits de protection de la propriété intellectuelle, il doit immédiatement en informer Crush via les coordonnées de la section 1 (Identification du titulaire) des présentes Conditions d'Utilisation.",
  "legal-disclaimer": "5. Exclusion des garanties et de la responsabilité",
  "legal-disclaimer-1": "Les informations publiées sur le Site Web ou l'App peuvent ne pas être exhaustives ou entièrement mises à jour, et Crush n'assume aucune responsabilité quant au manque d'exhaustivité, de mise à jour ou d'exactitude des données et informations contenues dans les différentes pages faisant partie du Site Web ou de l'App.",
  "legal-disclaimer-2": "Crush ne garantit pas que l'accès à l'App ou au Site Web sera ininterrompu ou sans erreur. En outre, Crush ne garantit pas que le contenu ou le logiciel du Site Web ou de l'App ne causera pas de dommages au système informatique (logiciel et matériel) de l'utilisateur. Crush ne sera pas responsable des pertes, dommages ou préjudices de toute nature résultant de l'accès, de la navigation et de l'utilisation du Site Web ou de l'App, y compris, mais sans s'y limiter, les pertes, dommages ou préjudices causés aux systèmes informatiques ou causés par l'introduction de virus.",
  "legal-disclaimer-3": "Crush est également exonéré de toute responsabilité découlant de toute utilisation abusive du Site Web ou de l'App par l'utilisateur.",
  "legal-links": "6. Liens",
  "legal-links-1": "Vous êtes informé que tant le Site Web que l'App peuvent contenir, entre autres, des liens, des bannières, des boutons, des répertoires et des moteurs de recherche qui permettent aux Utilisateurs d'accéder à d'autres sites web ou apps appartenant et/ou gérés par des tiers et qui, par conséquent, échappent au contrôle de Crush, qui ne peut assumer la responsabilité du contenu qui apparaît sur ces pages ou apps.",
  "legal-links-2": "Si vous estimez qu'un tel contenu est inapproprié ou contraire aux objectifs de Crush, veuillez nous le faire savoir via les coordonnées ci-dessus afin que nous puissions prendre les mesures appropriées.",
  "legal-jurisdiction": "7. Droit applicable et juridiction",
  "legal-jurisdiction-1": "Les présentes conditions d'utilisation et leur exécution sont soumises au droit espagnol.",
  "legal-jurisdiction-2": "En cas de litige sur l'interprétation et l'application des présentes conditions d'utilisation, les Parties négocieront de bonne foi pour tenter de résoudre ce litige ou cette réclamation. Toutefois, si le différend ou la réclamation n'est pas résolu, les Parties se soumettent aux cours ou tribunaux appropriés conformément à la loi.",
  "privacy-title": "Crush - Confidentialité",
  "privacy-date": "Dernière modification: 8 décembre, 2023",
  "privacy-header": "Politique de confidentialité",
  "privacy-introduction-1": "Chez Crush, nous nous engageons à faire en sorte que vos données personnelles soient protégées et ne soient pas utilisées à d'autres fins que celles indiquées dans la présente politique de confidentialité. Pour cette raison, dans cette section, nous informons les utilisateurs et les parties intéressées de tout ce qui concerne le traitement de leurs données personnelles, respectant ainsi la réglementation sur la protection des données applicable dans notre pays: Règlement Général sur la Protection des Données (UE) 2016/679, du 27 avril relatives à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données (ci-après,",
  "privacy-introduction-2": "'GDPR'",
  "privacy-introduction-3": ") et la Loi Organique 3/2018, du 5 décembre, sur la Protection des Données Personnelles et la garantie des droits numériques (ci-après",
  "privacy-introduction-4": "'LOPDGDD'",
  "privacy-introduction-5": "Cette Politique de Confidentialité s'applique au traitement des données que CRUSH SOCIAL NETWORK, SARL., effectués à la fois via le site web: www.crushsocialnetwork.com (ci-après, le 'Site Web') et via l'application mobile pour Smartphones portant le nom de 'Crush' (ci-après, la 'App') et/ou ceux qui sont indiqués. Nous vous recommandons de le lire attentivement avant d'utiliser le Site Web ou l'App et de fournir vos données par leur intermédiaire. N'hésitez pas à nous poser des questions à ce sujet par e-mail:",
  "privacy-index": "Index du contenu",
  "privacy-index-1": "Dans cette Politique, vous trouverez toutes les informations relatives au traitement de vos données personnelles et les droits que vous pouvez exercer pour en garder le contrôle. En ce sens, vous trouverez des informations sur:",
  "privacy-index-2": "Qui est responsable du traitement de vos données.",
  "privacy-index-3": "Quelles exigences vous devez remplir pour nous fournir vos données personnelles.",
  "privacy-index-4": "Quels traitements de données nous effectuons via l'App et quelles sont ses principales caractéristiques, en expliquant:",
  "privacy-index-5": "Quelles sont les données que nous collectons et quelles sont les manières de les collecter.",
  "privacy-index-6": "À quelles fins nous recueillons les données que nous demandons.",
  "privacy-index-7": "Quelle est la légitimité de son traitement.",
  "privacy-index-8": "Combien de temps les gardons-nous?",
  "privacy-index-9": "A quels destinataires vos données sont communiquées.",
  "privacy-index-10": "Existence de transferts internationaux de vos données.",
  "privacy-index-11": "Quels sont vos droits et comment pouvez-vous les exercer?",
  "privacy-index-12": "Comment nous protégeons vos informations personnelles.",
  "privacy-index-13": "Modifications de cette politique.",
  "privacy-controller": "1. Qui est responsable du traitement de vos données personnelles?",
  "privacy-controller-1": "Vos données personnelles seront traitées par la société CRUSH SOCIAL NETWORK, SARL. (",
  "privacy-controller-2": "'CRUSH'",
  "privacy-controller-3": "), avec NIF B-72599830 et dont les coordonnées sont les suivantes:",
  "privacy-controller-4": "Adresse:",
  "privacy-controller-5": "Calle Pintor Velázquez, 3, 1, 30530 Cieza, Murcia (Espagne)",
  "privacy-controller-6": "Email du contact:",
  "privacy-dpo": "1.1. Notre délégué à la protection des données",
  "privacy-dpo-1": "De CRUSH, nous mettons à votre disposition les coordonnées de notre délégué à la protection des données, à qui vous pouvez adresser toutes les questions que vous pourriez avoir concernant la présente politique de confidentialité ou le traitement de vos données personnelles.",
  "privacy-dpo-2": "E-mail:",
  "privacy-requirements": "2. Quelles conditions devez-vous remplir pour nous fournir vos données personnelles?",
  "privacy-requirements-1": "2.1. Âge minimum. Pour nous fournir vos données personnelles et utiliser notre Site Web et notre App, vous devez avoir plus de 15 ans.",
  "privacy-requirements-2": "2.2. Véracité. Lorsque vous nous fournissez vos données pour utiliser nos services, vous garantissez que les données et informations fournies sont réelles, véridiques, mises à jour et vous appartiennent également et non à des tiers.",
  "privacy-requirements-3": "De plus, vous devez nous informer de tout changement qui se produit dans les données fournies, en répondant en tout cas à la véracité et à l'exactitude des données fournies à tout moment.",
  "privacy-requirements-4": "2.3. Contrôle de l'âge et de la véracité. De CRUSH, nous nous réservons le droit de vérifier votre âge et vos informations d'identification, si nécessaire, même en exigeant un document officiel ou une procédure équivalente et, en cas de détection de fraude vérifiée ou suspectée que vous avez moins de l'âge indiqué, de supprimer, temporairement désactiver et/ou annuler votre compte.",
  "privacy-processing": "3. Quel traitement de données effectuons-nous via le Site Web et l'App et quelles en sont les principales caractéristiques?",
  "privacy-processing-1": "Ci-dessous, nous expliquons comment nous traitons vos informations personnelles et vous fournissons, en détail, toutes les informations pertinentes concernant votre vie privée:",
  "privacy-contact": "3.1 Lorsque vous nous contactez via nos canaux (formulaire de contact, chat, e-mail)",
  "privacy-contact-1": "Quels sont les modes de collecte des données?",
  "privacy-contact-2": "Formulaire de contact.",
  "privacy-contact-3": "Chat.",
  "privacy-contact-4": "Envoi d'e-mails à",
  "privacy-contact-5": "ou d'autres adresses e-mail CRUSH.",
  "privacy-contact-6": "Quelles données collectons-nous?",
  "privacy-contact-7": "Identification et coordonnées.",
  "privacy-contact-8": "Nous collectons vos données d'identification (nom et prénom) et votre adresse e-mail, ainsi que toute autre information que vous incluez volontairement dans les communications que vous nous envoyez.",
  "privacy-contact-9": "Nous pouvons vous demander des informations supplémentaires si nécessaire pour répondre à votre demande ou exigence.",
  "privacy-contact-10": "Quelles sont les finalités du traitement de vos données personnelles?",
  "privacy-contact-11": "Répondez à vos demandes.",
  "privacy-contact-12": "Le traitement de ces données aura pour finalité principale de répondre à vos demandes, de répondre à vos questions et/ou de vous fournir les informations requises, ainsi que, le cas échéant, de donner suite à vos demandes.",
  "privacy-contact-13": "Améliorer le service client.",
  "privacy-contact-14": "Toutes les informations dérivées des doutes, des requêtes et des conseils offerts aux parties intéressées, ainsi que la manière dont les demandes sont résolues, nous permettent de savoir comment nous fournissons notre propre service client, ce qui nous permet d'améliorer sa qualité.",
  "privacy-contact-15": "De même, toutes les informations collectées, une fois la période de conservation indiquée ci-dessous terminée, sont anonymisées et utilisées dans le but de pouvoir analyser les questions les plus fréquemment posées via le chat et de pouvoir automatiser les plus fréquentes, préparer des FAQ ou les conserver à des fins statistiques pour développer des stratégies commerciales.",
  "privacy-contact-16": "Quelle est la base légale qui nous permet de traiter vos données? La fourniture de ces données est-elle obligatoire?",
  "privacy-contact-17": "Consentement.",
  "privacy-contact-18": "Les données fournies aux fins ci-dessus seront traitées sur la base de votre consentement, accordé lorsque vous nous contactez volontairement par les moyens mis à votre disposition pour demander des informations ou faire une demande.",
  "privacy-contact-19": "Toutes les informations recueillies par le service client seront traitées à des fins statistiques qui nous aideront à améliorer la qualité du service client fourni. Cette finalité est considérée comme compatible avec la finalité initiale.",
  "privacy-contact-20": "Les informations que vous devez fournir seront indiquées par un astérisque ou similaire. Sans ces informations, il ne serait pas possible de répondre à vos questions ou demandes.",
  "privacy-contact-21": "Combien de temps conservons-nous vos informations?",
  "privacy-contact-22": "Nous traiterons toutes vos informations personnelles pendant le temps de traitement de vos demandes et, si nécessaire, pour y donner suite. Une fois cette période terminée, CRUSH conservera, bloquées, lesdites informations pendant les périodes prévues par la législation pour faire face à d'éventuelles responsabilités et pour démontrer le respect de nos obligations. À partir de ce moment, CRUSH ne traitera les informations que de manière anonyme, il ne sera donc pas possible de lier les informations statistiques aux utilisateurs spécifiques auxquels elles se réfèrent.",
  "privacy-contact-23": "À qui transmettons-nous vos informations personnelles?",
  "privacy-contact-24": "Nous ne faisons aucune mission supplémentaire pour effectuer ce traitement que celles indiquées, en général, au point",
  "privacy-contact-25": "4. A qui donnons-nous vos informations personnelles?",
  "privacy-contact-26": "En ce sens, certains canaux par lesquels vous pouvez nous contacter sont gérés par des prestataires de services, qui agissent en tant que Responsables de Traitement. Vous trouverez plus d'informations sur la façon dont ces prestataires de services agissent au point 4, mentionné ci-dessus.",
  "privacy-app-use": "3.2 Lorsque vous vous inscrivez et utilisez notre App 'Crush'",
  "privacy-app-use-1": "Quels sont les modes de collecte des données?",
  "privacy-app-use-2": "Formulaire d'inscription dans notre App et utilisation de ses différents espaces.",
  "privacy-app-use-3": "Quelles données collectons-nous?",
  "privacy-app-use-4": "Données de base pour l'inscription:",
  "privacy-app-use-5": "Afin de vous inscrire dans notre App et d'utiliser les services, vous devrez vous inscrire dans notre App et créer un",
  "privacy-app-use-6": "'profil'.",
  "privacy-app-use-7": "Au cours du processus d'inscription, nous vous demandons, en tant qu'utilisateur, les données suivantes:",
  "privacy-app-use-8": "Nom d'utilisateur.",
  "privacy-app-use-9": "Nom et prénom.",
  "privacy-app-use-10": "E-mail.",
  "privacy-app-use-11": "Date de naissance.",
  "privacy-app-use-12": "Donnée supplémentaire:",
  "privacy-app-use-13": "En plus de ce qui précède, notre App vous permet de partager volontairement des informations relatives à:",
  "privacy-app-use-14": "Genre.",
  "privacy-app-use-15": "Orientation sexuelle.",
  "privacy-app-use-16": "Intérêts.",
  "privacy-app-use-17": "Pays, ville ou région.",
  "privacy-app-use-18": "Description de vous.",
  "privacy-app-use-19": "Métier et études.",
  "privacy-app-use-20": "Goûts musicaux.",
  "privacy-app-use-21": "Meilleur ami.",
  "privacy-app-use-22": "Si vous l'autorisez, votre géolocalisation précise, qui, si vous l'avez autorisé, s'effectue en arrière-plan.",
  "privacy-app-use-23": "Données d'utilisation: des informations peuvent être collectées sur vos interactions avec d'autres utilisateurs (chats avec d'autres utilisateurs, utilisateurs avec lesquels vous vous connectez et interagissez, heure, fréquence d'interaction ou autres caractéristiques de vos interactions).",
  "privacy-app-use-24": "Certaines des données que vous pouvez fournir volontairement sont considérées comme sensibles, car elles sont liées à votre orientation sexuelle. Vous devez tenir compte du fait que, si vous décidez d'inclure de telles informations, CRUSH les traitera avec le reste des informations pour remplir les objectifs indiqués ci-dessous. De même, ces informations peuvent être partagées avec le reste des utilisateurs (elles peuvent être rendues publiques), de sorte qu'une utilisation non autorisée de l'App par d'autres utilisateurs pourrait entraîner la fuite de ces données. CRUSH n'est pas responsable de l'utilisation inappropriée des données par d'autres utilisateurs.",
  "privacy-app-use-25": "Nous vous informons également que CRUSH utilise les données ci-dessus dans le but de créer des profils afin de vous recommander du contenu qui pourrait vous intéresser, en tenant compte des caractéristiques personnelles que vous mentionnez à votre sujet, comme expliqué ci-dessous.",
  "privacy-app-use-26": "De plus, si vous l'autorisez, nous aurons accès à:",
  "privacy-app-use-27": "Appareil photo (images): l'autorisation de l'appareil photo permet à l'utilisateur de télécharger n'importe quelle image directement sur l'App.",
  "privacy-app-use-28": "Galerie de photos (images): accorder l'accès à la galerie de photos permet à l'utilisateur de télécharger n'importe quelle image de sa galerie de photos.",
  "privacy-app-use-29": "Microphone (audio): accorder l'accès au microphone permet à l'utilisateur d'utiliser l'enregistreur vocal de l'appareil directement depuis l'App.",
  "privacy-app-use-30": "Contenu publié par vous:",
  "privacy-app-use-31": "En utilisant notre App, vous avez la possibilité d'interagir avec d'autres utilisateurs via notre communauté, de pouvoir télécharger des publications avec du contenu audiovisuel (photos, audios, etc., si vous avez accordé les autorisations respectives) et d'ajouter des commentaires avec vos opinions, soulever des doutes et résoudre les préoccupations des autres utilisateurs.",
  "privacy-app-use-32": "Nous proposons également un excitant mode 'Stalker', où vos amis peuvent capturer des GIFs de vous avec votre consentement. Cependant, la responsabilité de la capture et de la demande de publication de ces GIFs sur votre profil incombe à l'utilisateur qui initie la demande. Le GIF ne sera pas publié sur votre profil tant que vous n'aurez pas accepté la demande. De plus, tous les collaborateurs peuvent supprimer définitivement la publication, soit en la supprimant directement, soit en refusant la demande.",
  "privacy-app-use-33": "CRUSH utilise la technologie de reconnaissance faciale de Amazon Web Services pour traiter les données biométriques des utilisateurs de CRUSH en tant que fournisseur de services ('Processeur'). Amazon peut traiter et stocker des modèles de visage dans le but de fournir des services de vérification faciale et/ou d'identification au nom de CRUSH, et uniquement sur les instructions de CRUSH. Amazon conservera ces données aussi longtemps que CRUSH en fera la demande, qui ne dépassera pas une période de grâce limitée après la date à laquelle (i) CRUSH cesse d'avoir une relation avec Amazon ou (ii) lorsque CRUSH demande la suppression.",
  "privacy-app-use-34": "Dans le cas où vous publiez des messages dans l'App (ce qui est simplement facultatif, nous ne vous obligeons pas à le faire), la technologie de reconnaissance faciale de Amazon sera utilisée, ce qui nous permet de détecter les visages et de trouver des utilisateurs similaires à vous (jumeaux) dans notre système. C'est la seule utilisation que nous faisons de cette technologie de reconnaissance.",
  "privacy-app-use-35": "Notre sous-traitant, Amazon, s'engage à respecter sa propre conformité au GDPR, ainsi qu'à fournir une variété de produits, de fonctionnalités, de documentation et de ressources pour aider ses clients à respecter leurs obligations de conformité en vertu du GDPR.",
  "privacy-app-use-36": "Données d'utilisation de l'App:",
  "privacy-app-use-37": "Nous recueillons également automatiquement des données d'utilisation de l'App, en particulier des données techniques et des informations liées à votre utilisation de l'App, y compris le type d'appareil mobile, l'identifiant unique de votre appareil mobile, l'adresse IP de votre appareil mobile, le système d'exploitation mobile, si tout, le type de navigateurs Internet que vous utilisez et des informations sur la façon dont vous utilisez l'App, telles que la fréquence et l'heure d'utilisation, les publicités sur lesquelles vous avez cliqué, etc.",
  "privacy-app-use-38": "Données vous concernant fournies par des tiers:",
  "privacy-app-use-39": "D'autres utilisateurs de l'App peuvent nous fournir des informations vous concernant lorsqu'ils utilisent nos services; par exemple, lorsqu'ils interagissent avec vous ou s'ils nous envoient un rapport vous concernant.",
  "privacy-app-use-40": "Quelles sont les finalités du traitement de vos données personnelles?",
  "privacy-app-use-41": "Inscrivez-vous et gérez votre compte",
  "privacy-app-use-42": "afin que nous puissions vérifier votre adresse e-mail et votre âge pour vous inscrire à l'App, interagir avec vous et vous donner accès à nos services.",
  "privacy-app-use-43": "Gérer et exécuter la relation contractuelle qui nous unit:",
  "privacy-app-use-44": "nous effectuons différentes étapes nécessaires à l'exécution du contrat signé avec vous, afin que, par exemple, nous puissions vous fournir le service, vous contacter pour vous aider à résoudre les doutes, vérifier les informations que vous nous avez fournies. De même, nous pouvons vous envoyer des communications de nature administrative, concernant des mises à jour, des modifications de nos conditions de service, des alertes de sécurité, ainsi que répondre à toute demande ou exigence que vous nous envoyez.",
  "privacy-app-use-45": "Publiez votre contenu.",
  "privacy-app-use-46": "Lorsque vous partagez du contenu ou des informations via l'App, il apparaîtra publié pour le public que vous avez sélectionné et qui peut voir ce que vous partagez. Pour ajouter certains contenus, tels que des photos et des vidéos, vous devrez nous donner accès à votre appareil photo ou à votre galerie de photos.",
  "privacy-app-use-47": "Tout utilisateur de notre App peut voir votre nom d'utilisateur et votre profil public sur l'App, qui comprend le contenu que vous avez partagé avec le grand public. Gardez également à l'esprit que votre contenu publié dans l'App peut être partagé par d'autres utilisateurs à l'intérieur ou à l'extérieur de l'App, prenez-en une capture d'écran ou téléchargez-le.",
  "privacy-app-use-48": "Publiez du contenu sur vous.",
  "privacy-app-use-49": "Les autres utilisateurs de l'App peuvent créer et partager du contenu présentant votre image ou vos données avec un public de leur choix. Si vous ne vous sentez pas à l'aise avec le contenu que d'autres personnes ont partagé à votre sujet, veuillez le signaler en utilisant les formulaires de l'App ou contactez-nous par courrier.",
  "privacy-app-use-50": "Vous proposer du contenu (actualités, articles, expériences, entre autres)",
  "privacy-app-use-51": "créer directement par CRUSH ou en collaboration avec des personnes externes susceptibles de vous intéresser.",
  "privacy-app-use-52": "Envoi de notifications push et d'alertes dans l'App.",
  "privacy-app-use-53": "Si vous l'avez autorisé, vous recevrez nos notifications push dans votre navigateur Web ou sur votre appareil mobile. Les notifications push peuvent inclure des rappels, des communications promotionnelles, des alertes et des mises à jour concernant l'App et les services.",
  "privacy-app-use-54": "Si vous activez ces notifications, vous recevrez des notifications personnalisées, basées sur un profil que nous développons à votre sujet et sur l'utilisation que vous faites de l'App. Pour la personnalisation des notifications, les informations obtenues grâce aux données que vous avez saisies dans l'App et le les données collectées concernant l'utilisation de l'App (contenus visités) pour créer un profil en rapport avec vos centres d'intérêt et vous proposer principalement les contenus et informations qui vous conviennent.",
  "privacy-app-use-55": "Vous pouvez désactiver les notifications push à tout moment en ajustant les paramètres de votre appareil mobile, bien que la désactivation n'affecte pas les autres communications que vous pouvez recevoir de CRUSH, telles que les communications par e-mail.",
  "privacy-app-use-56": "Prévention, détection et poursuite des activités illégales ou contraires aux conditions de service ou qui mettent en danger la sécurité des informations ou de l'App:",
  "privacy-app-use-57": "CRUSH peut traiter les données pour contrôler et prévenir toute forme d'abus de nos services, tels que les activités frauduleuses, les attaques par déni de service, l'envoi de spam, l'accès non autorisé aux comptes de nos utilisateurs, ainsi que toute autre pratique contraire à les Conditions Générales et d'Utilisation de l'App ou met en danger la sécurité des informations ou l'intégrité de l'App elle-même, en pouvant supprimer le contenu ou le compte qui est contraire à la loi, à nos Conditions Générales ou aux Règles de la Communauté.",
  "privacy-app-use-58": "Dans ce but, le traitement des plaintes que vous présentez contre d'autres utilisateurs ou celles qui sont présentées contre vous est également inclus, avec la possibilité de contacter CRUSH pour analyser le cas spécifique et garantir qu'il y aura une intervention humaine pour corriger ou rectifier le problème qui se présente à nous.",
  "privacy-app-use-59": "Nous pouvons également utiliser des outils pour détecter le contenu inapproprié (par exemple, contenu pour adultes ou violent) dans le contenu visuel que vous publiez.",
  "privacy-app-use-60": "Afin de vous offrir un meilleur service, vos données seront également traitées dans le but de:",
  "privacy-app-use-61": "Vous aider à vous connecter avec d'autres utilisateurs, vous permettant d'interagir avec eux via des écrans de discussion et d'autres mécanismes.",
  "privacy-app-use-62": "Vous recommander à d'autres utilisateurs qui vous ressemblent.",
  "privacy-app-use-63": "Pour faire des recommandations, nous utilisons les informations que vous nous fournissez, y compris les informations sur votre emplacement si vous donnez votre permission, ainsi que les informations que nous obtenons de votre utilisation de cet espace (par exemple, les caractéristiques des personnes avec lesquelles vous interagissez réellement) pour développer un profil automatisé sur vous et vous montrer de préférence les utilisateurs qui correspondent à votre profil et ce que vous recherchez.",
  "privacy-app-use-64": "En ce sens, les utilisateurs actifs de l'App vous seront de préférence présentés, qui sont proches de vous, qui correspondent aux préférences indiquées et à votre profil créé à partir de vos interactions, en veillant également à ce que vos caractéristiques et votre profil correspondent aux préférences des utilisateurs recommandés.",
  "privacy-app-use-65": "À des fins statistiques et autres réalisées avec des données anonymes.",
  "privacy-app-use-66": "Nous pouvons anonymiser toutes les informations personnelles collectées auprès des utilisateurs qui utilisent l'App à des fins statistiques et autres, telles que la formation d'algorithmes, pour mesurer l'efficacité des campagnes publicitaires disponibles sur l'App, la navigabilité et la convivialité de l'App pour pouvoir améliorer le design, présentation et fonctionnalités disponibles ou autres.",
  "privacy-app-use-67": "Pour vous montrer de la publicité:",
  "privacy-app-use-68": "Notre App peut inclure des publicités qui, si vous y consentez, seront personnalisées en fonction de vos goûts et préférences.",
  "privacy-app-use-69": "Quelle est la base légale qui nous permet de traiter vos données? La fourniture de ces données est-elle obligatoire?",
  "privacy-app-use-70": "Exécuter et maintenir la relation contractuelle entre les Parties.",
  "privacy-app-use-71": "La raison pour laquelle nous traitons vos informations aux fins mentionnées ci-dessus est de remplir le contrat que nous avons avec vous.",
  "privacy-app-use-72": "Consentement.",
  "privacy-app-use-73": "Certaines fonctionnalités reposent sur votre consentement exprès: (i) publication de votre propre contenu, (ii) accès à l'appareil photo ou à la galerie photo pour publier certains contenus dans l'App, (iii) accès à la géolocalisation de l'appareil pour recommandation d'utilisateurs par la proximité (iv) la réception de notifications push personnalisées (v) l'affichage de contenus publicitaires personnalisés. De plus, si vous décidez de nous fournir volontairement des informations supplémentaires pour compléter votre profil, ces informations seront utilisées sur la base de votre consentement.",
  "privacy-app-use-74": "N'oubliez pas que vous pouvez révoquer votre consentement à tout moment via les paramètres de votre appareil, en supprimant votre contenu ou via les options disponibles dans l'App.",
  "privacy-app-use-75": "Intérêt légitime.",
  "privacy-app-use-76": "Les données seront traitées sur la base de notre intérêt légitime en ce qui concerne la prévention des activités illégales et autres liées à la sécurité de l'App, pour garantir la sécurité du réseau et des informations afin de prévenir les événements susceptibles de compromettre la disponibilité, l'authenticité, l'intégrité et la confidentialité des données personnelles ou de la plateforme elle-même, ainsi que le respect des normes communautaires.",
  "privacy-app-use-77": "Nous pouvons également vous montrer des publicités non personnalisées via l'App en fonction de notre intérêt légitime.",
  "privacy-app-use-78": "Si vous souhaitez obtenir plus d'informations sur la pondération que nous avons effectuée sur les intérêts légitimes de CRUSH par rapport aux finalités ci-dessus, vous pouvez en faire la demande en nous écrivant à",
  "privacy-app-use-79": "Traitement de données spécialement protégées.",
  "privacy-app-use-80": "Les données relatives à vos intérêts ou aspects liés à l'orientation sexuelle seront traitées sur la base de votre consentement explicite.",
  "privacy-app-use-81": "Combien de temps conservons-nous vos informations?",
  "privacy-app-use-82": "Nous conserverons toutes vos informations personnelles aussi longtemps que notre relation contractuelle est en vigueur, c'est-à-dire jusqu'à ce que vous supprimiez votre compte. Une fois la relation contractuelle terminée ou votre compte résilié, CRUSH conservera, bloqué, toutes les informations pendant les périodes prévues par la loi pour enquêter sur les comportements illégaux ou préjudiciables, faire face à d'éventuelles responsabilités et démontrer le respect de nos obligations.",
  "privacy-app-use-83": "En ce qui concerne le contenu que vous publiez via notre App, vous devez tenir compte du fait que toute publication ou commentaire que vous avez publié dans notre communauté restera dans l'App jusqu'à ce que vous le supprimiez ou jusqu'à ce que vous supprimiez votre compte. Cependant, tout autre contenu où vous apparaissez et qui a été publié par d'autres utilisateurs (par exemple, des photos ou des vidéos) pourrait rester visible jusqu'à ce que ledit utilisateur le supprime ou ferme son compte.",
  "privacy-app-use-84": "Cependant, nous nous réservons le droit d'utiliser vos informations sous forme agrégée après la fermeture de votre compte à des fins statistiques et pour améliorer nos services. L'utilisation de ces informations ne permettra pas de vous identifier personnellement.",
  "privacy-app-use-85": "À qui transmettons-nous vos informations personnelles?",
  "privacy-app-use-86": "Il n'y a pas de transferts spécifiques de vos données personnelles pour remplir l'objectif indiqué dans cette section. Toutefois, nous pouvons recourir aux services de prestataires, qui auront un accès limité aux données et seront tenus à une obligation de confidentialité (pour plus d'informations sur la manière dont agissent nos prestataires, voir point",
  "privacy-app-use-87": "4. A qui donnons-nous vos informations personnelles? > Fournisseurs de services",
  "privacy-app-use-88": "Cependant, gardez à l'esprit que lorsque vous publiez du contenu sur notre App, vous pouvez partager des informations personnelles avec d'autres utilisateurs (y compris votre profil public).",
  "privacy-app-use-89": "De plus, si quelqu'un dépose un rapport qui vous implique, nous pouvons informer le plaignant des mesures que nous avons prises à la suite de son rapport, le cas échéant.",
  "privacy-cookies": "3.3. Navigation sur le Site (cookies)",
  "privacy-cookies-1": "Sur notre Site Web, ainsi que dans notre App, nous utilisons des cookies ou d'autres outils de suivi et de traçage pour collecter des informations sur l'utilisation que les utilisateurs font du Site Web et de l'App.",
  "privacy-cookies-2": "Pour plus d'informations sur le traitement que nous effectuons via lesdits outils de suivi, visitez notre",
  "privacy-cookies-3": "Politique de Cookies",
  "privacy-social-profiles": "3.4. Profils CRUSH sur les réseaux sociaux",
  "privacy-social-profiles-1": "CRUSH a un profil sur les principaux réseaux sociaux, tels que Twitter, Instagram, LinkedIn ou TikTok.",
  "privacy-social-profiles-2": "Lorsque vous devenez un adepte de l'une de nos pages sur les réseaux sociaux, le traitement des données sera régi par les conditions d'utilisation, les politiques de confidentialité et les règles d'accès qui appartiennent au réseau social correspondant et préalablement acceptées par l'utilisateur.",
  "privacy-social-profiles-3": "CRUSH, en ce sens, traitera vos données dans le but de gérer correctement sa présence sur le réseau social, de vous informer des activités, produits ou services, ainsi que pour toute autre finalité que la réglementation des réseaux sociaux autorise.",
  "privacy-social-profiles-4": "Gardez à l'esprit que nous n'avons aucune influence sur les informations que le réseau social collecte ou sur la manière dont il les traite, nous vous recommandons donc de vous tenir informé de l'objet et de l'étendue de la collecte d'informations effectuée via lesdits réseaux sociaux.",
  "privacy-transfer": "4. A qui donnons-nous vos informations personnelles?",
  "privacy-transfer-1": "En général, de CRUSH nous ne communiquerons pas vos données à des tiers. Cependant, en plus des transferts que nous indiquons spécifiquement dans la section dans laquelle nous expliquons les caractéristiques des différentes opérations (point 3), nous vous informons des communications que, en général, nous pouvons faire, ainsi que de leur fondement légitimant, qui affectent tous les traitements antérieurs.",
  "privacy-transfer-2": "Fournisseurs de services essentiels",
  "privacy-transfer-3": "pour exécuter le service que nous vous proposons (par exemple, les hébergeurs informatiques ou les plateformes d'envoi de communications commerciales). Nonobstant ce qui précède, ces entités ont signé les accords de confidentialité correspondants et ne traiteront vos données que conformément à nos instructions, ne pouvant les utiliser à leurs propres fins ou en dehors du service qu'elles nous fournissent.",
  "privacy-transfer-4": "Organismes publics.",
  "privacy-transfer-5": "Nous pouvons divulguer aux autorités publiques compétentes les données et toute autre information en notre possession ou accessibles via nos systèmes lorsqu'il existe une obligation légale de le faire, ainsi que lorsque cela est requis, par exemple lorsque le but est de prévenir ou poursuivre les abus de services ou les activités frauduleuses via notre Site Web ou notre App. Dans ces cas, les données personnelles que vous nous fournissez seront conservées et mises à la disposition des autorités administratives ou judiciaires.",
  "privacy-transfer-6": "Dans le cas d'une transaction d'entreprise:",
  "privacy-transfer-7": "En cas de fusion, acquisition, vente de tout ou partie de ses actifs, ou de tout autre type de transaction d'entreprise impliquant un tiers, nous pouvons partager, divulguer ou transférer les données des utilisateurs à l'entité successeur (y compris pendant la période pré- phase opératoire).",
  "privacy-transfer-8": "Aux tiers après agrégation ou anonymisation:",
  "privacy-transfer-9": "nous pouvons divulguer ou utiliser des données agrégées ou anonymisées (c'est-à-dire des données qui ne peuvent pas être liées à une personne physique identifiée ou identifiable) à quelque fin que ce soit.",
  "privacy-transfer-10": "À des tiers avec le consentement de l'utilisateur ou une autre base légitime:",
  "privacy-transfer-11": "Dans le cas où nous souhaitons partager des données avec des tiers en dehors du champ d'App de la présente politique de confidentialité, votre consentement sera demandé, dans tous les cas, ou vous en serez informé et sa base légitime.",
  "privacy-transfer-12": "De même, nous vous informons que cette politique de confidentialité se réfère uniquement à la collecte, au traitement et à l'utilisation d'informations (relatives aux données personnelles) par nous à travers l'interaction que vous effectuez avec notre Site Web ou notre App. L'accès aux pages web de tiers auxquelles vous pouvez accéder via des liens depuis le Site Web ou l'App ont leurs propres politiques de confidentialité sur lesquelles nous n'avons aucun contrôle. Par conséquent, avant de leur fournir des informations personnelles, nous vous recommandons de vous renseigner sur leurs politiques de confidentialité.",
  "privacy-eea": "5. Vos données personnelles sont-elles transférées vers des pays tiers en dehors de l'Espace économique européen?",
  "privacy-eea-1": "Certains de nos prestataires de services sont situés dans des pays hors de l'Espace économique européen (",
  "privacy-eea-2": "'EEE'",
  "privacy-eea-3": "La localisation de ces sociétés en dehors de l'EEE implique l'existence d'un transfert international de vos données personnelles, ce qui pourrait impliquer un degré de protection inférieur à celui inclus dans la réglementation européenne. Cependant, depuis CRUSH, nous avons appliqué des mesures afin que lesdits transferts n'entraînent pas un degré de protection inférieur de vos données personnelles.",
  "privacy-eea-4": "En ce sens, les prestataires de services situés en dehors de l'EEE ont signé les clauses contractuelles types correspondantes approuvées par la Commission européenne (",
  "privacy-eea-5": "'CCT'",
  "privacy-eea-6": "), un accord signé entre les deux entités par lequel l'entreprise non communautaire garantit qu'elle applique les normes européennes de protection des données.",
  "privacy-eea-7": "Par conséquent, le recours à ces prestataires n'entraîne pas un degré de protection de vos données personnelles inférieur au recours à des prestataires situés dans l'Union européenne. Vous pouvez consulter le contenu du CCT dans le lien suivant:",
  "privacy-eea-8": "Ensuite, nous indiquons quels sont les transferts internationaux que nous effectuons depuis CRUSH:",
  "privacy-eea-9": "Nom",
  "privacy-eea-10": "Activités affectées",
  "privacy-eea-11": "Pays",
  "privacy-eea-12": "Mécanisme de transfert",
  "privacy-eea-13": "Toutes",
  "privacy-eea-14": "Détection des visages et recherche de visages similaires",
  "privacy-eea-15": "Notifications push",
  "privacy-eea-16": "Envoyer des emails",
  "privacy-eea-usa": "Etats-Unis",
  "privacy-eea-sccs": "Clauses Contractuelles Types (CCS)",
  "privacy-rights": "6. Quels sont les droits que vous pouvez exercer en tant qu'intéressé?",
  "privacy-rights-1": "Vous pouvez exercer les droits que la loi vous garantit concernant le traitement de vos données personnelles en contactant notre délégué à la protection des données par e-mail",
  "privacy-rights-2": "Toute demande de droits que nous recevons sera résolue dans les meilleurs délais et, en tout état de cause, dans le délai maximum établi par la loi, à compter de la date de réception. Dans certains cas, il sera nécessaire de demander une copie de votre pièce d'identité ou autre pièce d'identité pour vérifier votre identité.",
  "privacy-rights-3": "Les droits qui vous correspondent en tant qu'intéressé sont les suivants:",
  "privacy-rights-4": "Droit de retirer le consentement accordé",
  "privacy-rights-5": "Vous pouvez à tout moment révoquer votre consentement pour tout traitement fondé sur celui-ci. Cependant, le retrait du consentement n'affectera pas la légalité du traitement fondé sur le consentement avant son retrait.",
  "privacy-rights-6": "Droit d'accès",
  "privacy-rights-7": "Vous avez le droit de savoir quelles données sont traitées, le cas échéant et, le cas échéant, d'en obtenir une copie, ainsi que d'obtenir des informations concernant:",
  "privacy-rights-8": "l'origine et les destinataires des données;",
  "privacy-rights-9": "les finalités pour lesquelles elles sont traitées;",
  "privacy-rights-10": "s'il existe un processus décisionnel automatisé, y compris le profilage;",
  "privacy-rights-11": "la durée de conservation des données; et",
  "privacy-rights-12": "les droits prévus par la réglementation.",
  "privacy-rights-13": "Droit de rectification",
  "privacy-rights-14": "Vous avez le droit d'obtenir la rectification de vos données personnelles ou de les compléter lorsqu'elles sont incomplètes.",
  "privacy-rights-15": "Droit d'effacement",
  "privacy-rights-16": "Vous avez le droit de demander la suppression de vos données personnelles si elles ne sont plus nécessaires aux fins pour lesquelles elles ont été collectées ou, le cas échéant, si nous ne sommes plus autorisés à les traiter.",
  "privacy-rights-17": "Droit à la portabilité des données",
  "privacy-rights-18": "Vous avez le droit de demander la portabilité de vos données dans le cas de traitements fondés sur votre consentement ou sur l'exécution d'un contrat, à condition que le traitement ait été effectué par des moyens automatisés. En cas d'exercice de ce droit, vous recevrez vos données personnelles dans un format structuré, couramment utilisé et lisible par tout appareil électronique. Cependant, vous pouvez également demander, lorsque cela est possible, que vos données soient transmises directement à une autre société.",
  "privacy-rights-19": "Droit de limiter le traitement de vos données personnelles",
  "privacy-rights-20": "Vous avez le droit de limiter le traitement de vos données dans les cas suivants:",
  "privacy-rights-21": "Lorsque vous avez demandé la rectification de vos données personnelles, pendant la période pendant laquelle nous vérifions leur exactitude.",
  "privacy-rights-22": "Lorsque vous considérez que nous ne sommes pas autorisés à traiter vos données. Dans ce cas, vous pouvez nous demander de limiter son utilisation au lieu de demander sa suppression.",
  "privacy-rights-23": "Lorsque vous considérez qu'il n'est plus nécessaire pour nous de poursuivre le traitement de vos données et que vous souhaitez que nous les conservions à des fins d'exercice ou de défense de réclamations.",
  "privacy-rights-24": "Dans les cas où il existe un traitement fondé sur notre intérêt légitime et que vous avez exercé votre droit de vous y opposer, vous pouvez nous demander de limiter l'utilisation de vos données lors de la vérification de la prévalence desdits intérêts par rapport au vôtre.",
  "privacy-rights-25": "Droit d'opposition",
  "privacy-rights-26": "Vous avez le droit de vous opposer à tout moment au traitement de vos données personnelles sur la base de notre intérêt légitime, y compris le profilage.",
  "privacy-rights-27": "Droit de déposer une plainte auprès d'une Autorité de Surveillance",
  "privacy-rights-28": "N'oubliez pas qu'à tout moment et dans le cas où vous considérez que nous avons violé votre droit à la protection de vos données, vous pouvez adresser votre défense à l'autorité de contrôle correspondante, dans le cas de l'Espagne, l'Agence espagnole de Protection des Données (",
  "privacy-confidence": "7. Comment garantissons-nous la confidentialité de vos informations?",
  "privacy-confidence-1": "La sécurité de vos données personnelles est une priorité pour nous. Pour cette raison, CRUSH a mis en place toutes les mesures de sécurité nécessaires pour garantir une utilisation et un traitement efficaces des données personnelles fournies par l'utilisateur, en préservant la vie privée, la confidentialité et l'intégrité de celles-ci, et utilise les techniques médiatiques nécessaires pour éviter l'altération, perte, accès non autorisé ou traitement de vos données, selon l'état de la technologie à tout moment.",
  "privacy-confidence-2": "Par conséquent, nous respectons les normes de sécurité recommandées pour les protéger. Cependant, il est impossible de garantir pleinement votre sécurité en raison de la nature même d'Internet et parce qu'il peut y avoir des actions malveillantes de la part de tiers indépendants de notre volonté.",
  "privacy-confidence-3": "Nous nous engageons à agir rapidement et avec diligence si la sécurité des données est en danger ou compromise, et à vous en informer si cela est pertinent.",
  "privacy-modifications": "8. Modifications de cette politique",
  "privacy-modifications-1": "De CRUSH, nous pouvons modifier le contenu de cette politique de confidentialité à tout moment, en particulier en cas de modifications législatives, jurisprudentielles ou d'interprétation de l'Agence espagnole de protection des données qui affectent le traitement des données effectué par CRUSH via le Site Web ou l'App. Toute nouvelle version de cette politique de confidentialité entrera en vigueur à la date d'entrée en vigueur publiée.",
  "privacy-modifications-2": "Si la version révisée inclut un changement substantiel qui affecte le traitement de vos données, nous vous en informerons au moins 30 jours à l'avance en publiant un avis sur notre Site Web ou notre App ou en vous informant par e-mail. Dans tous les cas, nous vous recommandons de consulter périodiquement cette politique de confidentialité pour être informé de la manière dont vos données personnelles sont traitées et protégées, ainsi que de vos droits.",
  "cookies-title": "Crush - Cookies",
  "cookies-date": "Dernière modification: 8 décembre, 2023",
  "cookies-header": "Politique de cookies",
  "cookies-introduction-1": "Les deux sur le site web www.crushsocialnetwork.com (ci-après, le",
  "cookies-introduction-2": "'Site Web'",
  "cookies-introduction-3": ") ainsi que via l'application mobile pour Smartphones portant le nom 'Crush' (ci-après, la",
  "cookies-introduction-4": "'App'",
  "cookies-introduction-5": ") nous utilisons des cookies ou d'autres outils de suivi et de traçage pour collecter des informations sur l'utilisation que les utilisateurs font du Site Web et de l'App.",
  "cookies-introduction-6": "Dans ce document, vous découvrirez quels cookies nous donnons au Cookie Monster, ainsi que tout autre type d'informations connexes concernant cette merveilleuse bouchée.",
  "cookies-definition": "1. Qu'est-ce qu'un cookie?",
  "cookies-definition-1": "Un cookie est un fichier qui s'installe dans le navigateur de votre terminal (ordinateur, mobile, etc.) afin de stocker et de récupérer des données. Les cookies peuvent avoir une multitude de finalités, en fonction de ce pour quoi ils ont été configurés: par exemple, enregistrer les préférences, collecter des statistiques d'utilisation, activer des fonctionnalités techniques, entre autres.",
  "cookies-responsible": "2. Qui est le responsable?",
  "cookies-responsible-1": "Le responsable du traitement des informations collectées par le biais des cookies est CRUSH SOCIAL NETWORK, SARL. (CRUSH), avec NIF B-72599830 et adresse Calle Pintor Velázquez, 3, 1, 30530 Cieza, Murcie (Espagne).",
  "cookies-responsible-2": "Si vous avez des questions concernant l'utilisation des cookies, veuillez écrire un e-mail à",
  "cookies-responsible-3": "et nous vous répondrons dès que possible.",
  "cookies-data": "3. Quelles données sont collectées via les cookies?",
  "cookies-data-1": "Lorsque vous accédez au Site Web ou installez et utilisez l'App, même si vous n'êtes pas inscrit, les informations suivantes sont collectées par le biais de cookies:",
  "cookies-data-2": "Informations sur l'appareil: Lors de l'utilisation du Site Web ou de l'App, nous collectons des informations sur l'appareil mobile à partir duquel vous accédez à la plate-forme. Les informations que nous recueillons sont le modèle de l'appareil, le système d'exploitation et sa version, l'identifiant unique de l'appareil et le réseau.",
  "cookies-data-3": "Informations sur votre emplacement: Nous obtenons également l'adresse IP, le fuseau horaire et le fournisseur de services mobiles, le cas échéant, ce qui nous permet d'obtenir un emplacement général.",
  "cookies-data-4": "Informations de navigation de l'utilisateur: Nous recueillons des informations sur l'utilisation que vous faites du Site Web et de l'App. Plus précisément, la fréquence d'utilisation, les sections que vous visitez, l'utilisation de fonctions spécifiques, etc.",
  "cookies-types": "4. Quels types de cookies existe-t-il?",
  "cookies-types-1": "Selon son objectif,",
  "cookies-types-2": "nous pouvons classer les cookies parmi les suivants:",
  "cookies-types-3": "Cookies techniques:",
  "cookies-types-4": "Ces cookies sont strictement nécessaires pour naviguer sur le Site Web ou l'App et permettent des fonctions de base telles que la navigation d'une section à l'autre. Ils sont en charge des aspects techniques tels que l'identification des profils ou des sessions et la gestion des préférences des outils dans l'utilisation, l'interaction et la localisation de l'accès au contenu. Appartiennent également à cette catégorie, en raison de leur nature technique, les cookies qui permettent la gestion, de la manière la plus efficace possible, des espaces publicitaires inclus dans le Site Web et l'App, sans collecter d'informations auprès des utilisateurs à des fins différentes, telles que personnaliser les publicités ou d'autres types de contenu.",
  "cookies-types-5": "Cookies statistiques:",
  "cookies-types-6": "Ces cookies mesurent et collectent des informations statistiques concernant l'utilisation faite par l'utilisateur du Site Web ou de l'App, telles que le temps passé sur la plateforme, le nombre de visites ou les contenus visités. Ces informations nous permettent de comprendre comment les utilisateurs interagissent avec le Site Web et l'App, d'évaluer les performances, quelles sections sont les plus ou les moins visitées, y compris la quantification de l'impact des publicités introduites sur le Site Web et l'App (le cas échéant). Le fait de disposer de ces informations peut résoudre des erreurs et améliorer la conception du système et le contenu qui y est proposé.",
  "cookies-types-7": "Cookies de préférence ou de personnalisation:",
  "cookies-types-8": "Les cookies de préférence permettent au Site Web ou à l'App de mémoriser des informations spécifiques sur l'utilisateur afin qu'il puisse accéder au service avec certaines caractéristiques qui peuvent différencier son expérience de celle des autres utilisateurs. Par exemple, votre langue préférée ou la région à partir de laquelle vous accédez, le nombre de résultats à afficher lorsque l'utilisateur effectue une recherche, l'apparence ou le contenu du service en fonction du type d'appareil à partir duquel l'utilisateur y accède, etc.",
  "cookies-types-9": "Cookies publicitaires comportementaux:",
  "cookies-types-10": "Ces cookies permettent la gestion des espaces publicitaires qui, le cas échéant, ont été intégrés au Site Web ou à l'App. Toutefois, la gestion des espaces publicitaires se fait en fonction du profil de navigation de l'utilisateur. Ces cookies stockent les informations de navigation de l'utilisateur obtenues grâce à l'observation continue de ses habitudes de navigation, en développant un profil d'utilisateur spécifique, de sorte que les publicités qui apparaissent dans ces espaces seront personnalisées pour l'utilisateur en fonction desdites habitudes.",
  "cookies-types-11": "Selon l'entité qui les gère:",
  "cookies-types-12": "Cookies propres:",
  "cookies-types-13": "sont ceux qui sont envoyés à l'appareil de l'utilisateur à partir d'un ordinateur ou d'un domaine géré par la personne responsable du Site Web ou de l'App, dans ce cas, CRUSH, et à partir duquel le service demandé par l'utilisateur est fourni.",
  "cookies-types-14": "Cookies tiers:",
  "cookies-types-15": "sont ceux qui sont envoyés à l'appareil de l'utilisateur à partir d'un ordinateur ou d'un domaine qui n'est pas géré par la personne responsable du Site Web ou de l'App, mais par une autre entité qui traite les données obtenues par le biais des cookies.",
  "cookies-types-16": "En fonction du temps qu'ils restent actifs sur l'appareil:",
  "cookies-types-17": "Cookies de sessions:",
  "cookies-types-18": "Ils ont pour objet de collecter et de stocker des données pendant la navigation de l'utilisateur sur le Site ou l'App, et sont désactivés à la fin de la navigation.",
  "cookies-types-19": "Cookies persistants:",
  "cookies-types-20": "Ceux-ci stockent des données qui peuvent être consultées et traitées pendant une durée définie par le responsable du cookie. Leur durée de vie peut varier de quelques minutes à plusieurs années.",
  "cookies-used": "5. Quels cookies utilisons-nous spécifiquement sur notre plateforme?",
  "cookies-used-1": "Sur notre plateforme, nous utilisons les cookies suivants:",
  "cookies-used-2": "Prénom",
  "cookies-used-3": "Objectif",
  "cookies-used-4": "Cookies propres/tiers",
  "cookies-used-5": "Expiration",
  "cookies-used-6": "14 mois",
  "cookies-used-7": "Indéfinie",
  "cookies-used-stats": "Statistiques",
  "cookies-used-technical": "Technique",
  "cookies-used-third-party-google": "Tiers (Google LLC.)",
  "cookies-legitimation": "6. Quelle est la base de légitimation qui nous permet de traiter vos données par le biais de cookies? Si oui, sont-ils obligatoires?",
  "cookies-legitimation-1": "Les informations que nous collectons via les cookies et autres dispositifs de suivi sont basées sur votre consentement, que nous demandons lorsque vous accédez au Site Web ou lorsque vous installez l'App via une fenêtre contextuelle informative ou de la manière établie par Google (en ce qui concerne Android) ou Apple (en ce qui concerne iOS).",
  "cookies-legitimation-2": "En ce sens, vous pouvez à tout moment retirer votre consentement comme expliqué dans la section relative à la révocation du consentement.",
  "cookies-legitimation-3": "Les cookies techniques ne peuvent pas être désactivés, sinon le bon fonctionnement du Site Web ou de l'App serait compromis. En général, ils ne sont définis qu'en réponse à des actions entreprises lors de la demande de services de notre part, telles que la définition de vos préférences en ce qui concerne les cookies eux-mêmes, la connexion ou le remplissage de formulaires. Dans le cas où la désactivation se produirait, cela pourrait entraîner une limitation de l'utilisation de certains des services du Site Web ou de l'App en raison de l'impossibilité d'accéder à certaines fonctionnalités et, par conséquent, votre expérience serait moins satisfaisante.",
  "cookies-transfer": "7. A qui donnons-nous vos informations personnelles? Y a-t-il des transferts de données internationaux?",
  "cookies-transfer-1": "Nous utilisons des cookies tiers, qui sont les cookies collectés et gérés par un tiers, de sorte que lesdites entités recevront vos données personnelles collectées via les cookies aux fins indiquées pour chaque cookie. Ces types de cookies sont indiqués dans le tableau de la section 5, en tant que 'Cookies tiers'. Ce tableau identifie le tiers qui gère chaque cookie.",
  "cookies-transfer-2": "Vous pouvez vous renseigner sur les transferts vers des pays tiers qui, le cas échéant, sont effectués par les tiers identifiés dans la présente politique de cookies dans leurs politiques correspondantes.",
  "cookies-consent": "8. Quelle est la manière d'accepter, de refuser ou de révoquer le consentement à l'utilisation de cookies sur ce site web?",
  "cookies-consent-1": "Lorsque vous accédez pour la première fois à notre Site Web ou à notre App, une fenêtre s'affiche dans laquelle vous pouvez indiquer si vous acceptez les cookies ou si vous souhaitez les configurer ou les refuser, sauf ceux qui sont strictement nécessaires. De même, vous pouvez retirer à tout moment le consentement précédemment accordé.",
  "cookies-consent-2": "Si vous souhaitez retirer votre consentement relatif à la Politique de Cookies à tout moment, vous disposez de deux possibilités: (i) retirer votre consentement via les boutons activés à cet effet dans la présente Politique de Cookies; ou (ii) supprimer les cookies stockés sur l'appareil via les paramètres et les configurations de votre navigateur Internet.",
  "cookies-consent-3": "Gardez à l'esprit que si vous acceptez les cookies de tiers, vous devez les éliminer des options du navigateur ou du système proposé par le tiers lui-même.",
  "cookies-consent-4": "Vous pouvez développer les informations sur la façon de désactiver les cookies, en fonction de votre navigateur, dans les liens suivants:",
  "cookies-consent-5": "; pour iOS:",
  "cookies-rights": "9. Quels sont les droits que vous pouvez exercer en tant qu'intéressé?",
  "cookies-rights-1": "Les droits dont vous disposez en tant qu'intéressé en ce qui concerne la protection de vos données personnelles sont indiqués dans la section 'Quels sont les droits que vous pouvez exercer en tant qu'intéressé?' au sein de la",
  "cookies-rights-2": "Politique de Confidentialité",
  "cookies-modifications": "10. Modifications de cette politique",
  "cookies-modifications-1": "Nous pouvons mettre à jour la politique en matière de cookies de notre plate-forme de temps à autre. Pour cette raison, nous vous recommandons de consulter cette politique chaque fois que vous accédez à notre Site Web ou à notre App afin d'être correctement informé sur comment et pour quoi nous utilisons les cookies."
}
