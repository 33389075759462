/**
 * @file Translation script.
 * @author Victorio Molina <https://github.com/VictorioMolina>
 * @copyright Crush Social Network 2022.
 * @license MIT
 * @module Scripts/Translation
 * @requires module:i18n.DOMTranslator
 */

import { DOMTranslator } from "../lib/languages";

//
// Translate the DOM nodes
//

const translator = new DOMTranslator("data-key");

translator.process();
