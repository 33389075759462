/* eslint-disable max-len */
/* eslint-disable global-require */
import _ from "lodash";

import { DEFAULT_LANGUAGE, getNavigatorLanguage } from "../../utils/languages";

/**
 * Translation getters.
 *
 * @memberof module:i18n
 * @namespace TranslationGetters
 * @readonly
 * @example
 * ```js
 * const englishTranslation = translationGetters["en"];
 *
 * console.log(englishTranslation("home.title"));
 * ```
 */
const translationGetters = Object.freeze({
  /**
   * English translation getter.
   *
   * @inner
   * @memberof module:i18n.TranslationGetters
   * @function en
   * @returns {object} English translation.
   */
  en: () => require("../../../../assets/languages/en/translations.json"),

  /**
   * Spanish translation getter.
   *
   * @inner
   * @memberof module:i18n.TranslationGetters
   * @function es
   * @returns {object} Spanish translation.
   */
  es: () => require("../../../../assets/languages/es/translations.json"),

  /**
   * French translation getter.
   *
   * @inner
   * @memberof module:i18n.TranslationGetters
   * @function fr
   * @returns {object} French translation.
   */
  fr: () => require("../../../../assets/languages/fr/translations.json"),

  /**
   * Italian translation getter.
   *
   * @inner
   * @memberof module:i18n.TranslationGetters
   * @function it
   * @returns {object} Italian translation.
   */
  it: () => require("../../../../assets/languages/it/translations.json"),

  /**
   * Portuguese translation getter.
   *
   * @inner
   * @memberof module:i18n.TranslationGetters
   * @function pt
   * @returns {object} Portuguese translation.
   */
  pt: () => require("../../../../assets/languages/pt/translations.json"),
});

/**
 * Translate method.
 *
 * @memberof module:i18n
 * @function translate
 * @param {string} scope - The translation scope.
 * @param {string} [language] - The language's locale.
 * @param {string} [fallbackLanguage] - Fallback language.
 * @param {string} [fallbackText] - Fallback text.
 * @returns {string} The translated text.
 * @requires module:i18n/Constants.DEFAULT_LANGUAGE
 * @requires module:i18n/Functions.getNavigatorLanguage
 * @requires module:i18n.TranslationGetters
 * @example
 * ```js
 * console.log(translate("home.title"));
 * ```
 */
export const translate =
  (
    scope,
    language = getNavigatorLanguage() ?? DEFAULT_LANGUAGE,
    fallbackLanguage = DEFAULT_LANGUAGE,
    fallbackText = ""
  ) => {
    const formattedLocale = language.split(/[-_]/)[0]; // Short version: "en", "es", "it"...

    const translation = // fallback
      (translationGetters[formattedLocale] ?? translationGetters[fallbackLanguage])();

    return (
      _.get(translation, scope) ??
      _.get(translationGetters[DEFAULT_LANGUAGE](), scope, fallbackText)
    );
  };

/**
 * Shorthand version of the {@link module:i18n.translate|translate} method.
 *
 * @memberof module:i18n
 * @function t
 * @param {string} scope - The translation scope.
 * @param {string} [language=getNavigatorLanguage() ?? DEFAULT_LANGUAGE]
 *   The language's locale.
 * @param {string} [fallbackLanguage=DEFAULT_LANGUAGE] - Fallback language.
 * @param {string} [fallbackText=""] - Fallback text.
 * @returns {string} The translated text.
 * @example
 * ```js
 * console.log(t("home.title"));
 * ```
 */
export const t = translate;
