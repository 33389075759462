/**
 * @file i18n constants.
 * @author Victorio Molina <https://github.com/VictorioMolina>
 * @copyright Crush Social Network 2022.
 * @license MIT
 * @module i18n/Constants
 */

/**
 * The website supported languages.
 *
 * @constant
 * @type {string[]}
 * @default ["en", "fr", "it", "pt", "es"]
 */
export const SUPPORTED_LANGUAGES = ["en", "fr", "it", "pt", "es"];

/**
 * The website default language.
 *
 * @constant
 * @type {string}
 * @default SUPPORTED_LANGUAGES[0]
 */
export const DEFAULT_LANGUAGE = SUPPORTED_LANGUAGES[0];
